.footer-wrapper-2019 {
  z-index: 99;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #fff;
  position: relative;
  margin-bottom: 114px;
}

.footer-container {
  padding-right: 20px;
  padding-left: 20px;
  display: block;
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}

.footer-2019-col-div {
  @media only screen and (max-width: 640px) {
    display: block;
  }

  display: flex;
}
.footer-2020-col-logo {
  border-top: 1px solid #121519;
  margin-top: 50px;
  padding-top: 10px;
  @media only screen and (max-width: 640px) {
    margin-top: 30px;
    padding-top: 30px;
  }
}

.footer-2020-svg-logo {
  padding-left: 9px;
  @media only screen and (max-width: 640px) {
    padding-left: 5px;
  }
}

.footer-2019-col {
  @media #{$large-up} {
    min-width: 240px;
    padding-right: 0;

    &:last-child {
      li {
        float: right;
      }
    }
  }
  &:last-child {
    float: none;
  }
}

.footer-2019-col.footer-2019-col--small {
  @media #{$large-up} {
    min-width: 120px;
  }
}

.footer-list-item--header {
  min-height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #121519;
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;

  img {
    width: 70%;
  }
}

.footer-list-item,
.white.footer-link {
  padding-top: 5px;
  padding-bottom: 5px;
}

.footer-2019-link-item {
  color: #121519;
  font-size: 16px;
  line-height: 25px;
}
.footer-2019-header {
  color: #121519;
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
}

.footer-2019-badges {
  width: 48px;
}

.footer-social-link {
  padding-left: 9px;

  img {
    width: 20px;
  }
}

.footer-2019-badge-link.footer-2019-badge-middle {
  margin-right: 15px;
  margin-left: 15px;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
