.m-fine-print {
  .m-fine-print-header {
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    color: $ashen;
  }

  @media #{$medium-up} {
    .m-left-menu {
      top: -4px;
      position: relative;
    }
  }
}
