.m-api-quote {
  position: relative;

  .m-api-quote--quote {
    background-color: white;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.3em;
    color: $ashen;
    padding: 20px 40px;
    border: 1px solid $tepid-sloth;
    border-radius: 5px;

    .m-api-quote--quote--link > a {
      font-weight: 500;
      font-size: 14px;
      padding-left: 5px;
    }
  }

  .m-api-quote--identity {
    margin-top: 15px;
    color: $ashen;
    font-size: 14px;
  }

  .m-api-quote--arrow {
    &:after,
    &:before {
      left: 90%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-top-color: $white;
      border-width: 7px;
      margin-left: -7px;
      bottom: -13px;
    }
    &:before {
      border-top-color: $warm-chinchilla;
      border-width: 9px;
      margin-left: -9px;
      bottom: -17px;
    }
  }
}
