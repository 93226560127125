.m-api-ref-section {
  float: left;
  width: 100%;
  margin-bottom: 50px;

  .m-api-ref-section--header {
    float: left;
    width: 100%;
    margin-bottom: 20px;
  }

  .m-api-ref-section--name {
    float: left;
    font-weight: 500;
    color: $ashen;
    text-transform: uppercase;
    margin-right: 10px;
    padding: 3px 0px;
  }

  .m-api-ref-section--description {
    float: left;
    font-weight: 400;
    color: $ashen;
    margin-right: 10px;
    padding: 3px 0px;
    @media #{$small-only} {
      padding-top: 7px;
      width: 100%;
    }
  }

  .m-api-ref-section--expand-link-wrapper {
    float: right;
    max-width: 100px;
    .m-api-ref-section--expand-link {
      float: right;
      line-height: 1.8em;
    }
  }

  .m-api-ref-section--expand-link {
    float: right;
    width: 156px;
    height: 23px;
    cursor: pointer;
    padding: 3px 0px;
    font-size: 10px;
    font-weight: 500;
    background-color: $castle-rock;
    text-align: center;
    border-radius: 3px;
    color: $white;

    &::after {
      content: 'SHOW RESPONSE OBJECT';
    }

    @media #{$small-only} {
      width: 110px;

      &::after {
        content: 'SHOW RESPONSE';
      }
    }
  }

  &.is-expanded {
    .m-api-ref-section--expand-link {
      &::after {
        content: 'HIDE RESPONSE OBJECT';
      }
    }
    .m-api-ref-object {
      display: block;
    }
  }

  .m-api-ref-method-row.is-expanded .m-api-ref-method-info,
  &.is-stand-alone .m-api-ref-method-row .m-api-ref-method-info {
    display: block;
    padding: 20px 0px 10px;
  }

  &.is-stand-alone .m-api-ref-section--expand-link {
    display: none;
  }
}

.m-api-ref-object-intro {
  float: left;
  margin-bottom: 20px;
  color: $ashen;
  font-weight: 400;
}

.m-api-ref-object {
  float: left;
  width: 100%;
  display: none;
  margin-bottom: 30px;

  .m-api-ref-object--name,
  .m-api-ref-object--description {
    float: left;
    font-weight: 500;
    color: $ashen;
    padding: 3px 0px;
  }

  .m-api-ref-object--row,
  .m-api-ref-object--field-row {
    float: left;
    width: 100%;
    border-bottom: solid 1px $warm-chinchilla;
    padding: 10px 15px;
    overflow-x: auto;
  }

  .m-api-ref-object--field-name,
  .m-api-ref-object--field-description {
    float: left;
    font-weight: 400;
    color: $grey;
    padding: 3px 0px;
    word-break: break-word;
  }

  .m-api-ref-object--field-row {
    &.sub-property {
      margin-left: 3%;
      border-left: solid 1px $warm-chinchilla;
      width: 97%;
      &.last {
        border-bottom: none;
      }
      &.last + .m-api-ref-object--field-row {
        border-top: solid 1px $warm-chinchilla;
      }
      &.sub-sub-property {
        margin-left: 6%;
        width: 94%;
      }
    }

    &:last-child {
      border-bottom: solid 1px $warm-chinchilla !important;
    }
  }
}

.m-api-ref-method-table {
  float: left;
  width: 100%;

  .m-api-ref-method-table--header {
    float: left;
    background: white;
    border: solid 1px $warm-chinchilla;
    padding: 8px 15px;

    & > span {
      float: left;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      color: $grey;
      padding: 3px 0px;
    }
  }
}

.m-api-ref-method-row {
  float: left;
  border: solid 1px $warm-chinchilla;
  border-top: none;
  padding: 10px 15px;
  background: $plume;

  &:hover {
    background: $off-white;
  }

  .m-api-ref-method-row--header {
    float: left;
    cursor: pointer;
  }

  .m-api-ref-method-row--uri {
    font-weight: 400;
    color: $ashen;
    padding: 3px 0px;
    margin-bottom: 8px;

    & > strong {
      color: var(--dig-color__primary__base);
      word-break: break-all;
    }
  }

  .m-api-ref-method-row--action {
    float: left;
    font-weight: 800;
    color: $ashen;
    padding: 3px 0px;

    & > .m-api-docs-deprecated {
      font-weight: 400;
      margin-top: 5px;
    }
  }

  .m-api-ref-method-row--description {
    float: left;
    font-weight: 400;
    color: $ashen;
    padding: 3px 0px;
  }
}

.m-api-ref-method-info {
  display: none;
  float: left;
  width: 100%;

  .m-api-ref-method-info--description {
    float: left;
    width: 100%;
  }

  .m-api-ref-method-info--description--title {
    font-weight: 500;
  }

  .m-api-ref-method-info--description--text {
    word-break: break-word;
  }
}

.m-api-ref-method-example {
  float: left;
  padding: 10px;
  background: $plume;
  width: 100%;

  .m-api-ref-method-example--title {
    text-transform: uppercase;
    font-weight: 500;
  }

  .m-api-ref-method-example--extra {
    float: left;
    width: 100%;
  }

  .m-api-ref-method-example--call-example {
    font-family: Menlo, 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', Monaco,
      Consolas, monospace;
    font-size: 12px;

    div {
      font-family: Menlo, 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', Monaco,
        Consolas, monospace;
      font-size: 12px;
    }
  }

  .m-api-ref-method-example--call-example--http-call {
    font-family: Menlo, 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', Monaco,
      Consolas, monospace;
    font-size: 12px;
    color: $ashen;
    word-break: break-word;
  }

  .m-api-ref-method-example--call-example--http-params {
    padding-left: 10px;
    font-weight: 400;
    line-height: 16px;
    font-family: Menlo, 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', Monaco,
      Consolas, monospace;
    font-size: 12px;
    overflow-x: auto;
  }

  .m-tabs--pane {
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  .m-tabbed-code-example {
    margin-top: 0px;
  }
}

.m-api-ref-method-parameters {
  .m-api-ref-method-parameters--title {
    font-weight: 500;
  }

  .m-api-ref-method-parameters--text {
  }

  .m-api-ref-method-parameters--param {
    border-bottom: solid 1px $warm-chinchilla;
    padding: 10px;
    overflow-x: auto;

    &:first-child {
      border-top: solid 1px $warm-chinchilla;
    }
    &.sub-property {
      .name {
        color: $cinder-block;
        font-weight: 400;
      }
      margin-left: 20px;
      border-left: solid 2px $warm-chinchilla;
    }
    &.sub-property.last {
      border-bottom: none;
    }
    &.after-sub-properties {
      border-top: solid 1px $warm-chinchilla;
    }
  }

  .m-api-ref-method-parameters--param--opt {
    color: $light-grey;
  }

  .m-api-ref-method-parameters--param--name {
    float: left;
    font-weight: 500;
    word-wrap: break-word;

    span {
      font-weight: 300;
    }

    .m-api-docs-deprecated {
      font-weight: 400;
      margin-top: 5px;
    }
  }

  .m-api-ref-method-parameters--param--description {
    float: right;

    ul {
      list-style-type: disc;
      font-size: inherit;
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
}
