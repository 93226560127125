.m-editor {
  .m-editor--outer {
    &.gmail {
      .m-editor--logo {
        float: left;
        width: 120px;
        margin-top: 10px;
        margin-right: 20px;
      }
    }
  }

  .m-editor--controls {
    background: $warm-chinchilla;
    border-bottom: solid 1px $cinder-block;
    padding: 10px 40px;
    display: inline-block;
    width: 100%;
    height: 55px;

    &.small-h-padding {
      padding: 10px 20px;
    }
  }

  .m-editor--controls--components {
    float: left;

    & > ul {
      margin: 0;
    }

    &.align-left {
      margin-left: 0;
    }

    & > li,
    & > ul > li {
      float: left;
      border: solid 1px $cinder-block;
      box-shadow: 1px 1px 1px $cinder-block;
      background: $white;
      padding: 3px 7px;
      border-radius: 2px;
      margin-right: 15px;
      opacity: 0.8;
      filter: alpha(opacity=80);

      &:hover {
        opacity: 1;
        filter: alpha(opacity=100);
      }

      &.active {
        opacity: 1;
        filter: alpha(opacity=100);
        border: solid 1px $grey;
        border-bottom: solid 4px $ashen;
      }

      &.active > a,
      &.active .tasks_control {
        font-weight: 400;
        color: $black;
      }

      &.select {
        border: none;
        padding: 0;
        background: transparent;
      }

      & > select {
        margin: 0;
        height: 33px;
        font-size: 14px;
        color: #1a1a1a;
        padding: 0 15px;
      }
    }

    & > li .tasks_control {
      line-height: 26px;
      font-size: 14px;
      color: $ashen;
      font-weight: 400;
      font-size: 12px;

      &:hover {
        text-decoration: none;
      }
    }

    & > li .tasks_control .ico {
      float: left;
      width: 30px;
      height: 19px;
      margin: 4px 10px 0 5px;
      background-size: 100%;
      background-repeat: no-repeat;

      &.ico_signature {
        background-image: url('../signature@2x.png');
        image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
      }

      &.ico_initials {
        background-image: url('../initials@2x.png');
        image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
        margin-top: 6px;
        width: 24px;
      }

      &.ico_checkbox {
        background-image: url('../checkbox@2x.png');
        image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
        width: 16px;
      }

      &.ico_sign_date {
        background-image: url('../sign_date@2x.png');
        image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
        margin-top: 6px;
        width: 16px;
      }

      &.ico_textbox {
        background-image: url('../textbox@2x.png');
        image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
        margin-top: 6px;
        width: 16px;
      }
    }

    & > li > a {
      text-decoration: none;

      &:hover .tasks_control {
        color: $black;
      }
    }
  }

  .m-editor--controls--align {
    float: left;

    .m-editor--controls--align--options {
      float: left;
      margin-top: 7px;

      &.is-disabled {
        opacity: 0.5;
        filter: alpha(opacity=50);
      }
    }

    .m-editor--controls--align--option {
      float: left;
      width: 18px;
      height: 18px;
      background-size: 100%;
      background-repeat: no-repeat;
      margin-left: 30px;
      opacity: 0.7;
      filter: alpha(opacity=70);
      cursor: pointer;

      &.align-left {
        background-image: url('../left_align.png');
        image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
      }

      &.align-right {
        background-image: url('../right_align.png');
        image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
      }

      &.align-bottom {
        background-image: url('../bottom_align.png');
        image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
      }

      &.align-top {
        background-image: url('../top_align.png');
        image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
      }

      &:hover {
        opacity: 1;
        filter: alpha(opacity=100);
      }
    }

    .m-editor--controls--align--separator {
      float: left;
      border-right: solid 1px $cinder-block;
      width: 1px;
      height: 25px;
      margin: 0 30px;
      position: relative;
      top: 4px;
    }

    .m-editor--controls--align--required {
      float: left;
      width: 150px;

      & > input {
        float: left;
        margin: 10px 0 0 0;
      }

      & > div {
        float: left;
        margin-top: 10px;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.05em;
      }

      &.is-disabled {
        opacity: 0.5;
        filter: alpha(opacity=50);
      }
    }
  }

  .m-editor--controls--bar-container {
    text-align: right;

    .m-editor--controls--save-btn {
      float: right;
      margin: 0 0 0 20px;

      .m-button {
        margin: 0;
        padding: 11px 20px 9px;
        min-width: 100px;
      }
    }

    .m-editor--controls--spinner {
      float: right;
      display: none;
      background-image: url(../dots.gif);
      image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
      background-repeat: no-repeat;
      background-size: 100%;
      width: 60px;
      height: 20px;
      margin-top: 8px;
      margin-right: 30px;
    }

    .m-editor--controls--cancel-link {
      float: right;
      font-size: 12px;
      font-weight: 300;
      color: $light-grey;
      line-height: 38px;
      margin: 0 10px;
      text-decoration: none;
      letter-spacing: 0.05em;

      &:hover {
        cursor: pointer;
        color: $grey;
      }
    }

    .m-editor--controls--save-status {
      float: right;
      display: none;
      margin-top: 0;
      line-height: 38px;
      color: var(--dig-color__primary__base);
      font-size: 12px;
      font-weight: 400;
      margin-right: 30px;
      letter-spacing: 0.05em;
    }
  }

  .m-editor--controls--switch {
    float: left;
    margin-top: 5px;
    margin-right: 30px;

    & > span {
      float: left;
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .m-editor--controls--switch--separator {
      border-right: solid 1px $cinder-block;
      width: 1px;
      height: 25px;
      margin: 0 20px;
      position: relative;
      top: -1px;
    }

    .m-editor--controls--switch--groups {
      background-image: url('../group_actions_inactive.png');
      image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
      width: 16px;
      height: 16px;
      margin-top: 3px;

      &:hover,
      &.is-active {
        cursor: pointer;
        background-image: url('../group_actions.png');
        image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
      }
    }

    .m-editor--controls--switch--components {
      background-image: url('../plus_inactive.png');
      image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
      width: 16px;
      height: 16px;
      margin-top: 3px;

      &:hover,
      &.is-active {
        cursor: pointer;
        background-image: url('../plus.png');
        image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
      }
    }

    .m-editor--controls--switch--rotate {
      padding: 10px 8px 6px 10px;
      background: $white;
      border: 1px solid #ccc;
      margin-top: -4px;
      cursor: pointer;

      &.is-disabled {
        color: $light-grey;
      }
    }
  }

  .component-menu {
    box-shadow: none;
    border-radius: 0;
    background: $plume;
    border: solid 1px $cinder-block;
    padding: 10px;

    .hide-component-menu {
      text-align: right;
      border: none;
      padding: 0 0 10px 0;
      text-decoration: none;

      & > a {
        float: right;
        width: 10px;
        height: 5px;
        background-image: url('../minimize.png');
        image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
        background-repeat: no-repeat;
        background-position: 0 2px;
        opacity: 0.7;
        filter: alpha(opacity=70);

        &:hover {
          opacity: 1;
          filter: alpha(opacity=100);
        }
      }
    }

    .assignment-container {
      & > label {
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.05em;
        color: $ashen;
      }

      & > select {
        font-size: 12px;
        height: 30px;
        padding: 5px 10px;
        color: $ashen;
        font-weight: 300;
        border: solid 1px $warm-chinchilla;
      }
    }

    .show-advanced {
      color: $ashen;
    }

    .name-box,
    .validation-box {
      & > label {
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.05em;
        color: $ashen;
      }

      .validation-rule {
        font-size: 10px;
        font-weight: normal;
        letter-spacing: 0.05em;
        color: $goldenrod;
        margin-top: 5px;
      }

      .validation-additional-input {
        font-size: 10px;
        font-weight: normal;
        letter-spacing: 0.05em;
        margin-top: 5px;
      }

      .validation-custom-regex {
        input {
          padding: 0px 2px;
          margin: 0px 2px;
          font-size: 12px;
        }
        input.validation-custom-regex-input {
          max-width: 125px;
          display: inline;
        }
      }

      & > input {
        font-size: 12px;
        font-weight: 300;
        border: solid 1px $warm-chinchilla;
        border-radius: 0;
        padding: 5px 10px;
        box-shadow: none;
      }

      & > select {
        font-size: 12px;
        height: 30px;
        padding: 5px 10px;
        color: $ashen;
        font-weight: 300;
        border: solid 1px $warm-chinchilla;
      }
    }

    .top-arrow {
      &:after,
      &:before {
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
    }

    .menu-plus-button {
      color: $castle-rock;
      text-decoration: none !important;
    }

    &.add-my-own-menu-bottom {
      .top-arrow {
        &:after,
        &:before {
          bottom: 100%;
        }
        &:after {
          border-bottom-color: $plume;
          border-width: 7px;
          margin-left: -7px;
          margin-bottom: 0;
        }
        &:before {
          border-bottom-color: $cinder-block;
          border-width: 9px;
          margin-left: -9px;
          margin-bottom: 0;
        }
      }
    }

    &.add-my-own-menu-top {
      .top-arrow {
        &:after {
          border-top-color: $plume;
          border-width: 7px;
          margin-left: -7px;
          bottom: -14px;
        }
        &:before {
          border-top-color: $cinder-block;
          border-width: 9px;
          margin-left: -9px;
          bottom: -18px;
        }
      }

      .menu-plus-button {
        position: relative;
        top: 1px;
      }
    }
  }

  .component {
    height: 18px;
    min-height: 14px;

    .handleBar {
      display: none;

      .ie_rgba_fix {
        position: absolute;
        z-index: -1;
        top: initial;
        bottom: 0;
        left: 3px;
        zoom: 1; /* required for the filters */
        width: 12px;
        height: 12px;
        background: url('../move_icon@2x.png') left center no-repeat;
        background-size: 100%;
      }
    }

    .wrapper {
      &.interactive {
        min-height: 14px;
        border: solid 1px transparent;
        border-top: none !important;
      }

      .ie_rgba_fix {
        background: transparent !important;
      }

      .description {
        color: $ashen;
        font-weight: 500;
        letter-spacing: 0.05em;
        min-height: 14px;
      }

      .description.show_on_hover {
        background: transparent;
        color: $ashen;
      }
    }

    /* Component coloring */
    &.light-green .wrapper.interactive {
      border-color: #a2bf00 !important;
    }
    &.pink .wrapper.interactive {
      border-color: #dd078b !important;
    }
    &.yellow .wrapper.interactive {
      border-color: #f1de0b !important;
    }
    &.cyan .wrapper.interactive {
      border-color: #40c0c2 !important;
    }
    &.orange .wrapper.interactive {
      border-color: #f3961e !important;
    }
    &.grey .wrapper.interactive {
      border-color: #808080 !important;
    }
    &.dark-cyan .wrapper.interactive {
      border-color: #be1e2d !important;
    }
    &.purple .wrapper.interactive {
      border-color: #652c90 !important;
    }
    &.blue-green .wrapper.interactive {
      border-color: #00a98f !important;
    }
    &.light-blue .wrapper.interactive {
      border-color: #7da9bd !important;
    }
    &.dark-brown .wrapper.interactive {
      border-color: #645b57 !important;
    }
    &.light-brown .wrapper.interactive {
      border-color: #b86645 !important;
    }
    &.light-purple .wrapper.interactive {
      border-color: #84578f !important;
    }
    &.ocean .wrapper.interactive {
      border-color: #0099ce !important;
    }
    &.weird-purple .wrapper.interactive {
      border-color: #ec6956 !important;
    }
    &.green .wrapper.interactive {
      border-color: #006f42 !important;
    }
    &.blue .wrapper.interactive {
      border-color: #00b3e6 !important;
    }
    &.mud .wrapper.interactive {
      border-color: #9a9679 !important;
    }
    &.bright-orange .wrapper.interactive {
      border-color: #f05a22 !important;
    }
    &.brown-green .wrapper.interactive {
      border-color: #858332 !important;
    }

    .remove-wrapper {
      position: absolute;
      right: -20px;
      bottom: 0;
      width: 20px;
      top: 0;
      height: 100%;
      cursor: pointer;

      .remove {
        position: absolute;
        left: 4px;
        bottom: 0;
        margin-top: -12px;
        cursor: pointer;
        width: 16px;
        height: 16px;
        z-index: 1003;
        background-image: url('../x_icon.png');
        image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
        background-size: 100%;
        display: none;
      }
    }

    .ui-resizable-handle {
      z-index: 1002;
      bottom: 0;
      right: 0;
      left: initial;
      top: initial;
      width: 13px;
      height: 13px;
      padding: 10px 3px 3px 10px;
      background-image: url('../resize_icon.png');
      image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
      background-position: 0 0 !important;
      display: none;
    }

    &.component-selected-in-group {
      .wrapper.interactive {
        border-width: 2px !important;
      }

      .handleBar,
      .remove,
      .ui-resizable-handle {
        display: none !important;
      }
    }

    &.component-hover,
    &.component-interact {
      .handleBar,
      .remove,
      .ui-resizable-handle {
        display: inline;
      }
    }

    &.error {
      .component-menu {
        .name-box {
          & > label {
            color: red;
          }
        }
      }
    }
  }

  #left_panel,
  #left_panel > .panel-items {
    overflow-x: visible;
  }

  .m-editor--sidebar-tip {
    float: left;
    width: 250px;
    padding: 20px 10px;
    border-bottom: solid 1px $warm-chinchilla;
    border-right: solid 1px $warm-chinchilla;
    position: absolute;
    top: 55px;

    .m-editor--sidebar-tip--icon {
      float: left;
      width: 32px;
      height: 32px;
      background-size: 100%;
      background-image: url('../flag_icon@2x.png');
      image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
      background-repeat: no-repeat;
      margin-right: 10px;
      margin-left: 5px;
    }

    & > p {
      float: right;
      font-size: 12px;
      text-align: left;
      max-width: 180px;
    }

    &.is-compact {
      width: 150px;

      .m-editor--sidebar-tip--icon {
        width: 28px;
        height: 28px;
        margin-right: 8px;
        margin-left: 5px;
      }

      & > p {
        max-width: 88px;
      }
    }
  }

  .m-sig-modal--outer-wrapper {
    position: absolute;
    top: 15%;
    width: 100%;
    z-index: 9999;
  }

  .m-sig-modal--overlay {
    z-index: 9998;
    background: $castle-rock;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.3;
    filter: alpha(opacity=30);
  }

  .m-sig-modal {
    margin: 0 auto;
    height: 387px !important;
    background: $white;
    border: solid 1px $warm-chinchilla;

    .m-sig-modal--right-wrapper {
      height: 385px;
    }

    .m-sig-modal--action {
      margin-top: 0;
    }
  }
}

.prepare-page.embedded-requesting .m-editor {
  .m-editor--sidebar-tip {
    display: none !important;
  }

  .m-editor--controls--save-btn .m-button {
    margin-top: 0;
  }

  #backLink {
    float: left;
    margin-top: 10px;

    & > b {
      float: left;
      background-size: 100%;
      background-image: url('../../scss/back_arrow.png');
      image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
      background-repeat: no-repeat;
      margin: 3px 5px 0 10px;
      height: 10px;
      width: 6px;
    }

    & > a {
      letter-spacing: 0.05em;
      font-size: 14px;
      font-weight: 300;
    }

    & > a:hover {
      text-decoration: none;
    }
  }

  .m-editor--controls {
    padding: 10px 25px;
  }

  .m-editor--controls--components > li {
    margin-right: 5px;
  }
}

.l-editor.sign-page {
  #signBubble {
    display: none;
  }

  .component {
    .wrapper {
      .description.show_on_hover {
        background: transparent;
        color: $ashen;
      }

      .reset.show_on_hover {
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url('../x_icon.png');
        image-rendering: -webkit-optimize-contrast; // fix for image rendering in chrome
        width: 12px;
        height: 12px;
        left: -7px;
      }
    }

    &.valid.formfield,
    &.valid.inputsignature,
    &.valid.inputinitials,
    &.valid.inputcheckmark,
    &.valid.inputtextbox {
      .description {
        display: none;
      }

      &:hover .wrapper > img {
        opacity: 0.15;
        filter: alpha(opacity=15);
      }
    }
  }
}
