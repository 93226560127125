.m-feature-grid {
  .m-feature-grid--text {
    font-weight: 300;
    color: $ashen;
    max-width: 500px;
    display: inline-block;
    padding-bottom: 75px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .m-feature-grid--header {
    &.simple-grey {
      font-weight: 400;
    }
  }
  .m-feature-grid--list-item-group {
    display: inline-block;
  }

  .m-feature-grid--list-item {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .m-feature-grid--item-image-wrapper {
    @media #{$medium-up} {
      height: 120px;
      margin-bottom: 20px;
      &.short-spacing {
        margin-bottom: 0px;
        height: 80px;
      }
    }
    @media #{$small-only} {
      margin-bottom: 30px;
    }
    .m-feature-grid--header-2 {
      padding-left: 13px;
      font-size: 14px;
    }
  }
  .m-feature-grid--clear-float {
    clear: both;
  }
  // Module modifer, for an inverted color use-case //
  &.inverted {
    background: $black; // change to background colors pre-defined version
    .m-feature-grid--header {
      color: $white;
    }
    .m-feature-grid--text {
      color: $white;
    }
  }
  &.simple-grey {
    background: white; // change to background colors pre-defined version
  }
  .m-feature-grid--lower-button {
    position: relative;
    top: -40px;
  }
}
