.m-api-example {
  &.sticky-header {
    /* The fixed header */
    text-align: center;
    padding: 0 0 20px;
    background-color: #1a1a1a;
    position: fixed;
    top: -130px;
    width: 100%;
    z-index: 99;
    -webkit-transition: top 0.8s ease-in-out;
    -moz-transition: top 0.8s ease-in-out;
    -ms-transition: top 0.8s ease-in-out;
    -o-transition: top 0.8s ease-in-out;
    transition: top 0.8s ease-in-out;

    &.slide-down {
      top: 0;
    }

    .logo {
      /* The HS dev logo */
      display: inline-block;
      width: 140px;
      margin-right: 50px;
    }

    #hero-buttons {
      display: inline-block;
    }

    .demo-button {
      margin-left: 50px;
    }

    #docs-button,
    .or-img {
      display: none;
    }

    input.mktoField {
      width: 230px !important;
    }

    @media (max-width: 1032px) {
      padding: 15px 0;

      .logo {
        margin-right: 15px;
      }

      #demo-button {
        display: none;
      }
    }

    @media (max-width: 800px) {
      padding: 0;
    }
  }

  * {
    font-size: 14px;
  }

  p {
    text-transform: uppercase;
    vertical-align: middle;
    margin: 0 6px 0 5px;
    display: inline-block;
    color: $goldenrod;
    font-weight: 400;

    @media (max-width: 700px) {
      margin: 0 0 10px;
    }

    @media (max-width: 512px) {
      margin: 0;
    }
  }

  .logo {
    display: none;
  }

  .arrow {
    width: 7px;

    @media (max-width: 700px) {
      display: none;
    }
  }

  #demo-button,
  #docs-button {
    display: inline-block;
  }

  .demo-button,
  .docs-button {
    width: 160px;
    height: 32px;
    font-weight: 300;
    text-transform: none;
    background-color: transparent;
    margin-top: 25px;
    padding: 0;
  }

  .or-img {
    width: 29px;
    height: 29px;
    margin: -3px 19px 0px 21px;
  }

  .marketo-container {
    /* Marketo overrides */
    margin-left: 15px;
    display: inline-block;

    .rx-marketo-form {
      .mktoButtonWrap .mktoButton {
        background: $goldenrod !important;
        transition: 0.8s !important;
        border: 1px solid $goldenrod !important;
        margin-top: 4px !important;
        width: 144px !important;

        &:hover {
          background-color: transparent !important;
          color: $goldenrod !important;
        }
      }

      .mktoError {
        right: 70px !important;
        bottom: 45px !important;
      }

      .mktoFieldDescriptor.mktoFormCol {
        margin-bottom: 0px !important;
      }

      @media (max-width: 640px) {
        text-align: center;
      }
    }

    @media (max-width: 512px) {
      margin-left: 0;

      .rx-marketo-form {
        .mktoField {
          height: 37px;
          width: 240px;
          margin: 0;
        }

        .mktoButtonWrap .mktoButton {
          margin: 10px 0 0 0 !important;
        }

        .mktoButtonWrap.mktoSimple {
          margin-left: 0 !important;
        }
      }
    }
  }
}
