@import '../colors';

.l-header {
  .m-link-list--menu-link-mobile,
  .m-link-list--link--container > a {
    color: var(--dig-color__primary__base);
  }

  .m-link-list--link--container > a.c-white {
    color: $white;
  }
  .m-link-list--link--container > a.hc-cerulean:hover {
    color: var(--dig-color__primary__base);
  }

  .m-link-list--link--container > a.c-ashen,
  .m-link-list--link.m-link-list--currentPage {
    color: $ashen;
  }

  .logo {
    top: 0;

    & > a > img {
      width: initial;
    }
  }
}

.l-api-docs {
  div,
  p,
  blockquote,
  pre,
  a,
  code,
  em,
  small,
  strike,
  strong,
  b,
  u,
  i,
  center,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  section {
    letter-spacing: 0.05em;
    font-size: 15px;
    line-height: 1.7em;
  }

  p,
  .flex-video {
    float: left;
    margin-bottom: 10px;
    width: 100%;
    $levels: 15;
    @for $i from 0 through $levels {
      &.l-padding-v-#{$i*10} {
        padding-top: #{$i * 10}px;
        padding-bottom: #{$i * 10}px;
      }
      &.l-padding-t-#{$i*10} {
        padding-top: #{$i * 10}px;
      }
      &.l-padding-b-#{$i*10} {
        padding-bottom: #{$i * 10}px;
      }
      &.l-margin-v-#{$i*10} {
        margin-top: #{$i * 10}px;
        margin-bottom: #{$i * 10}px;
      }
      &.l-margin-t-#{$i*10} {
        margin-top: #{$i * 10}px;
      }
      &.l-margin-b-#{$i*10} {
        margin-bottom: #{$i * 10}px;
      }
    }
  }
}
