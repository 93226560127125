.m-grid-fade-in {
  h2 {
    font-size: 18px;
    padding-bottom: 40px;
  }
  .additional-image {
    opacity: 0;
    transition: opacity 0.4s ease-in;
    &.additional-image-active {
      opacity: 1;
    }
  }
  .grid-image {
    min-height: 90px;
  }
  &.white {
    background: $white;
  }
  li {
    padding-bottom: 70px;
    p {
      padding-bottom: 20px;
    }
    &.final-elements-centered {
      position: relative;
      @media #{$small-only} {
        left: 15%;
      }
    }
  }
  .m-grid-fade-in--final-text {
    text-align: left;
    width: 200px;
  }
}
