.m-landing-page {
  .m-landing-page--container {
    padding-top: 40px;
    padding-bottom: 80px;

    @media #{$small-only} {
      padding-bottom: 20px;
    }
  }

  .m-landing-page--feature-grid {
    padding-top: 30px;
    padding-bottom: 40px;

    @media #{$small-only} {
      padding-bottom: 0;
    }
  }
}
