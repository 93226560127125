*[class^='dig-'] {
  font-family: unset;
}

.dig-Button,
.dig-IconButton,
.dig-ChoiceChip,
.dig-InputChip,
.dig-FilterChip {
  &,
  &:hover,
  &:active,
  &:focus {
    color: unset;
    outline: unset;
    box-sizing: border-box;
    transition: unset;
    opacity: unset;
    margin-bottom: unset;
  }

  &[disabled] {
    border: unset;
  }
}

.dig-ChoiceChip,
.dig-InputChip,
.dig-FilterChip {
  &:focus {
    background-color: unset;
  }
}

button[class^='dig-Button']:not(.dig-Button--primary):not(
    .dig-Button--opacity
  ) {
  &:hover,
  &:focus {
    background: unset;
  }
}

.dig-Title,
.dig-Text,
.dig-Link {
  letter-spacing: 0;
}

.dig-Menu {
  a {
    color: unset;
  }
}

.dig-Label {
  font-family: var(--type__body__standard--fontfamily);
}

input.dig-TextInput-input[type='email'],
input.dig-TextInput-input[type='text'],
input.dig-TextInput-input[type='password'],
input.dig-TextInput-input[type='date'],
input.dig-TextInput-input[type='time'],
input.dig-TextInput-input[type='datetime'],
input.dig-TextInput-input[type='datetime-local'],
input.dig-TextInput-input[type='week'],
input.dig-TextInput-input[type='tel'],
input.dig-TextInput-input[type='number'],
input.dig-TextInput-input[type='url'],
textarea.dig-TextArea {
  &,
  &:hover,
  &:active,
  &:focus,
  &[disabled] {
    box-shadow: unset;
  }
}

.dig-Select,
.dig-Select button,
input.dig-TextInput-input[type='email'],
input.dig-TextInput-input[type='text'],
input.dig-TextInput-input[type='password'],
input.dig-TextInput-input[type='date'],
input.dig-TextInput-input[type='time'],
input.dig-TextInput-input[type='datetime'],
input.dig-TextInput-input[type='datetime-local'],
input.dig-TextInput-input[type='week'],
input.dig-TextInput-input[type='tel'],
input.dig-TextInput-input[type='number'],
input.dig-TextInput-input[type='url'],
textarea.dig-TextArea {
  &,
  &:hover,
  &:active,
  &:focus,
  &[disabled] {
    margin-bottom: unset;
    letter-spacing: unset;
    transition: unset;
    height: unset;
    color: unset;
    font-family: unset;
    font-size: unset;
    font-style: unset;
    font-weight: unset;
    line-height: unset;
    background: unset;
  }
}

input.dig-TextInput-input[type='email'],
input.dig-TextInput-input[type='text'],
input.dig-TextInput-input[type='password'] {
  &,
  &:hover,
  &:active,
  &:focus,
  &[disabled] {
    border: unset;
  }
}

// Reset SVG heights.
// Our global css for some reason sets all SVGs to 32px.
svg[class^='dig-']:not([class^='dig-Illustration']) {
  height: unset;
}
