.m-arrow-box {
  z-index: 100;
  position: absolute;
  top: 38px;
  list-style-type: none;
  font-size: 13px;
  letter-spacing: 0.05em;
  text-shadow: none;
  text-align: left;
  background: $plume;
  min-width: 120px;
  padding: 5px;
  a {
    display: block;
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 24px;
  }
}

.m-arrow-box:after,
.m-arrow-box:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.m-arrow-box--link {
  font-weight: 500;
}

.floatingmenu {
  display: none;
  z-index: 100;
  position: absolute;
  top: 60px;
  left: 240px;
  list-style-type: none;
  padding: 10px 15px;
  border-bottom: solid 1px #ccc;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 1px 0px 1px #686868;
  text-shadow: none;
  text-align: left;
  background: rgba(250, 250, 250, 0.95);
  width: 170px;
  height: 150px;
  li {
    margin-right: 20px;
    &.last-child {
      margin-right: 0px;
    }
  }
  .arrow {
    position: absolute;
    top: -7px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(177, 177, 177, 0.95);
    font-size: 0;
    line-height: 0;
  }
  a {
    color: #191919;
    font-weight: normal;
    line-height: 25px;
    &:hover {
      text-decoration: underline;
    }
  }
}
