@import 'bourbon/bourbon';
@import 'foundation/settings';
@import 'foundation';
@import 'colors';
@import '_text';

body {
  margin: 0px;
  color: $castle-rock;
}

body,
button,
input,
optgroup,
select,
textarea {
  @extend %text-global;
  font-style: normal;
}

a {
  font-family: $global-hs-font-family;
  font-style: normal;
  text-decoration: none;
  color: var(--dig-color__primary__base);
  font-weight: 300;
  outline: 0;
  &:hover,
  &:focus {
    color: inherit;
  }
}

form {
  margin: 0;
}

h1 {
  font-family: $global-hs-font-family;
  font-style: normal;
  font-size: 50px;
  font-weight: 200;
  padding: 6px 0px;
  letter-spacing: 0.05em;
  margin: 0px;
  line-height: 1.2em;
  color: $castle-rock;
  &.downsize {
    font-size: 26px;
  }
  &.white {
    color: $white;
  }
  span.i {
    font-style: italic;
  }
  @media #{$small-only} {
    font-size: 36px;
  }
}

h2 {
  font-family: $global-hs-font-family;
  font-style: normal;
  font-size: 36px;
  font-weight: 200;
  letter-spacing: 0.05em;
  line-height: 1.2em;
  color: $castle-rock;
  span.i {
    font-style: italic;
  }
  @media #{$small-only} {
    font-size: 28px;
  }
}

h3 {
  font-family: $global-hs-font-family;
  font-style: normal;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: 1.2em;
  color: $castle-rock;
  span.i {
    font-style: italic;
  }
  @media #{$small-only} {
    font-size: 24px;
  }
}

h4 {
  font-family: $global-hs-font-family;
  font-style: normal;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
  line-height: 1.3em;
  color: $castle-rock;
  &.b {
    font-weight: 500;
  }
  &.white {
    color: $plume;
  }
  span.i {
    font-style: italic;
  }
  @media #{$small-only} {
    font-size: 20px;
  }
  &.downsize {
    font-size: 18px;
  }
}

h5 {
  font-family: $global-hs-font-family;
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.5em;
  color: $castle-rock;
  &.downsize {
    font-size: 14px;
  }
  span.i {
    font-style: italic;
  }
}

h6 {
  font-family: $global-hs-font-family;
  font-style: normal;
  font-size: 16px;
  padding: 6px 0px;
  letter-spacing: 0.05em;
  font-weight: 500;
  color: $castle-rock;
  &.downsize {
    font-size: 14px;
  }
  span.i {
    font-style: italic;
  }
}

ul {
  list-style-type: none;
}

p {
  font-family: $global-hs-font-family;
  font-style: normal;
  font-size: 15px;
  font-weight: 300;
  padding: 0px;
  margin-bottom: 0px;
  letter-spacing: 0.05em;
  line-height: 1.5em;
  color: $castle-rock;
  &.i {
    color: $castle-rock;
    font-style: italic;
    a {
      font-style: italic;
      font-weight: 500;
    }
  }
  &.b {
    font-weight: 500;
  }
  span.i {
    font-style: italic;
  }
}

/* This overrides Foundation's code style */
code {
  font-family: inherit;
  color: $ashen;
  font-weight: 500;
  padding: 1px 5px;
  background-color: $plume;
  border: solid 1px $warm-chinchilla;
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea {
  font-family: $global-hs-font-family;
  width: 100%;
  padding: 5px 15px;
  font-size: 14px;
  letter-spacing: 0.05em;
  font-weight: 300;
  padding-left: 10px;
  color: $grey;
  min-height: 0;
}

/* Remove those annoying built-in field icons on IE 10+ */
input[type='password']::-ms-reveal,
input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
textarea:focus {
  background-color: $plume;
  box-shadow: none;
  border-radius: 0;
}

// Placeholder text
// The .placeholder selector is specifically for the IE polyfill
.placeholder {
  color: $cinder-block !important;
}
// These vendor-specific selectors can't be stacked into a single rule due to browser bugs
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: $cinder-block;
}
input::-moz-placeholder,      /* Firefox 19+ */
textarea::-moz-placeholder {
  color: $light-grey;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  /* Firefox 18- */
  color: $light-grey;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: $cinder-block;
}

// Placeholder text on focused fields
// These vendor-specific selectors can't be stacked into a single rule due to browser bugs
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.15s;
}
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.15s;
}
input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  /* Older versions of Firefox */
  opacity: 0;
  transition: opacity 0.15s;
}
input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.15s;
}

select {
  font-size: 20px;
  color: $grey;
}

option:disabled {
  color: $cinder-block;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  margin-right: 0;
}

// Hide the ugly placeholder frame while images are loading in Firefox
img:-moz-loading {
  visibility: hidden;
}

.clear-floats,
.clear-both {
  clear: both;
}
iframe[name='google_conversion_frame'] {
  height: 0 !important;
  width: 0 !important;
  line-height: 0 !important;
  margin-top: -13px;
  float: left;
}

.l-new-modal {
  float: left;
  width: 100%;
  background: $white;

  .l-new-modal--content-wrap {
    padding-top: 20px;
  }
  .l-new-modal--close {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 10px;
    width: 10px;
    cursor: pointer;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }

  .l-new-modal--title {
    float: left;
    width: 100%;
    margin: 0 0 20px 0;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.05em;
    line-height: 1.4em;
    text-transform: uppercase;
  }

  .l-new-modal--sub-title {
    font-size: 14px;
    letter-spacing: 0.05em;
    line-height: 1.4em;
    font-weight: 300;
    margin-bottom: 20px;
    padding: 0 75px;
  }

  .l-new-modal--sub-sub-title {
    font-size: 14px;
    letter-spacing: 0.05em;
    line-height: 1.4em;
    font-weight: 300;
    margin-bottom: 20px;
    padding: 0 75px;
    color: $light-grey;
  }

  .l-new-modal--sub-content-separator {
    display: block;
    background-color: $warm-chinchilla;
    height: 1px;
    margin: 25px 0;
  }

  .l-new-modal--actions {
    float: left;
    width: 100%;
    height: 60px;
    margin-top: 25px;
    background: $plume;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: right;

    .m-button {
      padding: 8px 10px 6px;
      min-width: 100px;
      margin-top: 16px;
      margin-right: 20px;
    }
  }

  .l-new-modal--error {
    float: left;
    width: 100%;
    padding: 0 75px;
    margin-top: 10px;
    color: red;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.05em;
  }

  .l-new-modal--text {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.05em;
    float: left;
    width: 100%;
    text-align: center;
  }
}
.stub {
  background: yellow !important;
  color: black !important;
}
