.m-doc-signed-success {
  .m-doc-signed-success--header {
    @media #{$small-only} {
      padding: 40px 0px;
    }
    @media #{$medium-up} {
      padding: 70px 0px;
    }
  }
  .m-doc-signed-success--header-text {
    @media #{$small-up} {
      white-space: normal;
    }
    @media #{$large-up} {
      white-space: nowrap;
    }
  }
}
.m-doc-signed-failure {
  .m-doc-signed-failure--header {
    @media #{$small-only} {
      padding: 40px 0px;
    }
    @media #{$medium-up} {
      padding: 70px 0px;
    }

    .m-doc-signed-failure--hr-container {
      @media #{$small-only} {
        width: 70%;
      }
      @media #{$medium-up} {
        width: 40%;
      }
    }

    .m-doc-signed-failure--list {
      .m-doc-signed-failure--list-reason {
        padding-left: 0;
      }
      .m-doc-signed-failure--list-bullets {
        padding-right: 0;
        text-align: left;

        ul {
          list-style: disc;
        }
      }
    }
  }
}
