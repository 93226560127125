.m-hs-companies--header {
  text-transform: uppercase;
  background: white;
  display: inline-block;
  padding: 0 1rem;
  margin-left: -1rem;
}

.m-hs-companies--hr {
  border-bottom: 1px solid $warm-chinchilla;
  top: -8px;
  position: absolute;
  z-index: -1;
  left: 0;
  width: 100%;
}

.m-hs-companies--grid {
  float: none;
  .m-feature-grid--item-image-wrapper {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 200px;
  }
}

.m-hs-companies--api--grid {
  .m-feature-grid--item-image-wrapper {
    padding-bottom: 20px;
  }
}
