.m-api-use-case {
  .m-api-use-case--inline {
    display: inline;
  }

  .m-api-use-case--descr--image {
    text-align: center;
  }

  .m-api-use-case--descr--image-top {
    position: relative;
    top: 12px;
  }

  .m-api-use-case--descr--header {
    float: left;
    width: 100%;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
  }

  .m-api-use-case--descr--text-fixed-height,
  .m-api-use-case--descr--text {
    float: left;
    text-align: center;
    font-size: 13px;
    &.v-padded {
      padding: 0px 10%;
    }
  }

  .m-api-use-case--descr--text-fixed-height {
    margin-bottom: 10px;
    @media #{$large-up} {
      height: 80px;
      margin-bottom: 0px;
    }
  }

  .m-api-use-case--descr--cta {
    text-align: center;
    margin-top: 20px;
    span {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .m-api-use-case--content {
    display: none;
  }

  .m-api-use-case--descr--link {
    @media #{$small-only} {
      padding: 10px;
    }
  }

  &.active {
    width: 100%;
  }

  &.inactive {
    display: none;
  }
}

.m-api-use-case-menu {
  float: left;
  display: none;

  .m-api-use-case-menu-item {
    text-align: center;
    padding: 10px;
    background: $warm-chinchilla;
    color: $ashen;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    &.left {
      float: left;
    }
    &.right {
      float: right;
    }
    &.center {
      float: left;
      @media #{$large-up} {
        margin: 0px 12.5%;
      }
    }

    &:hover,
    &.active {
      color: #303030;
      background: $cinder-block;
    }
  }
}

.m-api-use-case-separator {
  float: left;

  &.with-icon {
    padding-top: 155px;
    text-align: center;
    .image {
      position: relative;
      top: -10px;
    }
  }

  .m-api-use-case-separator--bar {
    float: left;
    width: 1px;
    height: 120px;
    position: absolute;
    margin: 0px 8%;
    border-left: solid 1px $warm-chinchilla;
  }
  .m-api-use-case-separator--top-bar {
    top: 85px;
  }
}
