.m-message-page {
  .m-message-page--title {
    font-size: 30px;
    font-weight: 200;
    color: $ashen;
    margin-bottom: 15px;
  }

  .m-message-page--section {
    margin-bottom: 15px;

    .m-message-page--section--title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    & > p {
      color: $grey;
      font-size: 14px;
    }
  }

  .m-message-page--cta-button {
    & > button {
      margin-left: 0;
    }
  }
}
