.m-login-in-person {
  padding: 10px 80px;
  background-color: $plume;
  border: 1px solid $tepid-sloth;
  color: $grey;

  h4 {
    margin: 20px 0 30px 0;
    letter-spacing: 0.3px;
    font-weight: 400;
    text-align: center;
  }
  div {
    letter-spacing: 0;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  .m-login-in-person--errors > p {
    color: $red;
  }
}
