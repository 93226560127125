.m-api-features {
  text-align: center;
  margin: 0;

  .m-api-feature {
    padding: 0 50px 50px 60px !important;
    text-align: left;

    .m-api-feature--icon {
      float: right;
      width: 44px;
    }
  }

  @media #{$small-only, $medium-only} {
    .m-api-feature {
      float: none;
      padding: 0 50px 30px 60px !important;
      margin-left: -15px;
    }
  }
}
