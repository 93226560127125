// https://www.figma.com/file/9JqUNxRLutB9yt50D0ezrS/HS-Color-Tokens-%7C-Light?node-id=21%3A9659
.dig-theme--Sign {
  --primary-blue: #0061fe; // Dropbox primary blue
  --primary-blue-active: #0050d0;
  --primary-blue-hover: #0057e5;
  --primary-blue-active-inverse: #5d9aff;
  --primary-blue-hover-inverse: #4d90ff;

  --dig-color__primary__on-base: rgba(0, 120, 160, 0.08);
  --dig-color__primary__surface: rgba(0, 120, 160, 0.16);
  --dig-color__primary__base--faint: rgba(0, 120, 160, 0.32);
  --dig-color__primary__base: var(--primary-blue);
  --dig-color__primary__base: var(--primary-blue);
  --dig-color__primary__base--state-2: var(--primary-blue-active);
  --dig-color__primary__base--state-1: var(--primary-blue-hover);
  --dig-color__primary__base: var(--primary-blue);
  --color__glyph__accent: var(--primary-blue);
  --color__inverse__attention__background--faint: rgba(1, 155, 206, 0.16);
  --color__inverse__attention__background: rgba(1, 155, 206, 0.4);
  --color__inverse__attention__border--faint: rgba(1, 155, 206, 0.4);
  --color__inverse__attention__border: var(--primary-blue-hover);
  --color__inverse__attention__text: var(--primary-blue-hover);
  --color__inverse__button__primary__active: var(--primary-blue-active-inverse);
  --color__inverse__button__primary__hover: var(--primary-blue-hover-inverse);
  --color__inverse__core__accent: var(--primary-blue-hover);
  --color__inverse__glyph__accent: var(--primary-blue-hover);

  // --dig-Button-outline--outline-color: var(--dig-color__text__base);

  .dig-Button--standard,
  .dig-Button--primary,
  .dig-Button--opacity,
  .dig-Button--outline,
  .dig-IconButton--standard,
  .dig-IconButton--primary,
  .dig-IconButton--opacity,
  .dig-IconButton--outline {
    &,
    &:before {
      border-radius: 0px;
    }
  }

  button:focus,
  button:hover {
    &.dig-IconButton {
      background-color: transparent;
    }
    &.dig-Button--opacity {
      background-color: var(--dig-Button-opacity--background-color);
    }
    &.dig-Button--standard {
      background-color: var(--dig-Button-standard--background-color);
    }
    &.dig-Button--primary {
      background-color: var(--dig-Button-primary--background-color);
    }
    &.dig-Button--outline {
      background-color: var(--dig-Button-outline--background-color);
    }
  }

  .dig-Select,
  .dig-Select button {
    border: 1px solid var(--dig-Select-trigger--border-color);
  }

  // Labels
  .dig-FormLabel {
    color: var(--dig-color__text__base);
  }

  // Text areas
  .dig-TextArea {
    background: var(--dig-TextInput-input--background);
  }

  /* DIG typography */

  // Reset back to vanilla DIG.
  --type__body__xsmall--fontfamily: var(--__bodyFontStack);
  --type__body__small--fontfamily: var(--type__body__xsmall--fontfamily);
  --type__body__standard--fontfamily: var(--type__body__xsmall--fontfamily);
  --type__body__large--fontfamily: var(--type__body__xsmall--fontfamily);
  --type__body__xlarge--fontfamily: var(--type__body__xsmall--fontfamily);
  --type__body__xsmall--fontweight_strong: 500;
  --type__body__small--fontweight_strong: var(
    --type__body__xsmall--fontweight_strong
  );
  --type__body__standard--fontweight_strong: var(
    --type__body__xsmall--fontweight_strong
  );
  --type__body__large--fontweight_strong: var(
    --type__body__xsmall--fontweight_strong
  );
  --type__body__xlarge--fontweight_strong: var(
    --type__body__xsmall--fontweight_strong
  );

  /* DIG controls */

  // Checkbox
  // Reset back to vanilla DIG.
  --dig-Checkbox-marked--background-color: var(--dig-color__secondary__base);
  --dig-Checkbox-marked--border-color: var(--dig-color__secondary__base);
}
