.m-login {
  color: $ashen;
  font-size: 14px;

  .m-login--left-border {
    @media #{$large-up} {
      border-left: 1px solid $warm-chinchilla;
      p {
        font-size: 12px;
      }
    }
  }

  .m-login--modal-google-login {
    padding-bottom: 35px;
    margin-bottom: 35px;
  }

  .m-login--form {
    margin-bottom: 0px;
  }

  @mixin m-login--base-google-login-or-image {
    background-image: url('../or_white@2x.png');
    background-repeat: no-repeat;
    height: 29px;
    width: 29px;
    background-size: 29px 29px;
    position: absolute;
    bottom: -15px;
    right: 40%;
  }

  .m-login--modal-google-login-or-image {
    @include m-login--base-google-login-or-image;
  }

  .m-login--inline-google-login-or-image {
    @include m-login--base-google-login-or-image;
    @media #{$large-up} {
      position: absolute;
      right: -15px;
      bottom: 85px;
    }
  }

  @mixin m-login--base-google-login-border {
    @media #{$small-only} {
      border-bottom: 1px solid $warm-chinchilla;
      position: absolute;
      bottom: 0;
      right: 30%;
      left: 30%;
    }
  }

  .m-login--modal-google-login-border {
    @include m-login--base-google-login-border;
    @media #{$small-up} {
      border-bottom: 1px solid $warm-chinchilla;
      position: absolute;
      bottom: 0;
      right: 30%;
      left: 30%;
    }
  }

  @mixin m-login--base-google-login-button {
    width: auto;
  }

  .m-login--inline-google-login-button {
    width: auto;
    @media #{$small-up} {
      margin-left: 0px;
    }
    @media #{$large-up} {
      margin-left: -60px;
    }
  }

  .m-login--modal-google-login-button {
    @include m-login--base-google-login-button;
  }

  @mixin m-login--base-google-login-button-container {
    text-align: center;
  }

  .m-login--inline-google-login-button-container {
    @include m-login--base-google-login-button-container;
    @media #{$large-up} {
      text-align: left;
    }
  }

  .m-login--modal-google-login-button-container {
    @include m-login--base-google-login-button-container;
  }

  .m-login--sign-up {
    @media #{$large-up} {
      height: 200px;
    }
    &.m-login--sign-up-tall {
      @media #{$large-up} {
        height: 385px;
      }
    }
    margin-bottom: 40px;
  }

  .m-login--inline-container {
    border-left: 1px solid $warm-chinchilla;
    border-right: 1px solid $warm-chinchilla;
    border-top: 1px solid $warm-chinchilla;
  }

  .m-login--text-input {
    display: inline-block;
    max-width: 300px;
    &.m-login--login-password {
      margin-bottom: 4px;
      background-color: white;
      padding: 5px 15px;
      max-width: 300px;
      height: 2.3125rem;
      border: 1px solid #ccc;
      box-sizing: border-box;
    }
  }

  .m-login--forgot-pwd-link {
    a {
      color: $ashen;
    }
  }

  .m-login--centering-container {
    display: block;
    text-align: center;
  }

  .m-login--alignment-wrapper {
    max-width: 290px;
    width: 500px;
    display: inline-block;
    text-align: left;
  }

  label {
    color: $grey;
  }
  .m-login--captcha-if-image {
    margin-top: 10px;
  }

  .m-login--captcha-reload {
    margin-top: 10px;
  }

  .m-login--captcha-image {
    width: 300px;
  }
  .m-login--captcha {
    width: 300px;
    display: inline-block;
  }
  .m-login--captcha-input {
    margin-bottom: 0px;
  }

  &.is-verification {
    .submit-button {
      color: var(--dig-color__background__base);
      margin: var(--spacing__unit--1);
    }

    .m-login--verification--title {
      text-align: center;
      margin-bottom: 20px;
    }

    .m-login--verification--code {
      float: left;
      width: 100%;
      text-align: center;
      margin: 40px 0;

      & > input {
        font-size: 15px;
        letter-spacing: 0.03em;
        font-weight: 300;
        padding-left: 10px;
        color: $grey;
        height: 2.3125rem;
      }
    }

    .m-login--verification--error {
      color: $red;
      margin-top: 10px;
    }

    .m-login--verification--links {
      text-align: center;
      margin-top: 10px;

      & > a {
        display: block;
        margin: 5px 0;
      }
    }

    .m-login--verification--recovery {
      display: none;
    }

    .m-login--verification--links--modal {
      display: none;
      max-width: 590px;
      width: 100%;

      .m-login--verification--links--modal--title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
        @media #{$small-only} {
          text-align: center;
        }
      }

      .m-login--verification--links--modal--close-button {
        display: flex;
        margin-left: auto;

        &:hover {
          background-color: transparent;
        }
      }

      .helpLinks {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        & > * {
          margin: var(--spacing__unit--1);
        }
      }
    }
  }
}
