.m-hero {
  background: $plume;
  .m-hero--small-text {
    color: $ashen;
    font-size: 12px;
  }
  .m-hero--video {
    width: 285px;
    border: 3px solid #fff;
  }

  .m-hero--message-box {
    width: 790px;
    max-width: 90%;
    height: 90px;
    font-size: 15px;
    resize: none;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
