.m-coupon-display {
  border: 1px solid $cinder-block;
  margin-bottom: 20px;
  margin-left: 14px;
  width: 300px;

  div {
    padding: 6px;
  }
  color: $ashen;
  padding: 0px;

  text-align: center;
  .coupon-code {
    color: $goldenrod;
  }
  margin-bottom: 30px;
  @media #{$large-up} {
    margin-bottom: 12px;
  }
}
