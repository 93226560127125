.m-set-password {
  .m-set-password--password-field {
    input {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .m-set-password--form-button-wrapper {
    display: inline;
  }
  p {
    color: $grey;
  }
}
