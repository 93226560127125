@import 'scss/colors.scss';

.m-plan-agreement {
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.05em;
  width: 625px;
  margin: auto;
  color: $ashen;

  .m-plan-agreement--header {
    .m-plan-agreement--header--plan-details {
      width: 300px;
      height: 32px;
      text-align: left;
      float: left;

      .m-plan-agreement--header--title {
        height: 32px;
        left: 324px;
        top: 0px;

        /* Heading 2 */
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        color: $black;
      }

      .m-plan-agreement--header--plan-name {
        width: 200px;
        height: 20px;
        left: 324px;
        top: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $black;
      }
    }
    .m-plan-agreement--header--user-details {
      text-align: right;
      .m-plan-agreement--header--name-company {
        margin-top: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: $black;
      }
      .m-plan-agreement--header--email,
      .m-plan-agreement--header--company {
        font-size: 16px;
        margin: 5px 0;
        font-weight: 300;
      }
    }
  }

  .m-plan-agreement--summary {
    width: 600px;
    height: 64px;

    /* Gray / 025 */
    background: $grey-025;
    /* UI / Focus Outline */
    border: 2px solid rgba(1, 155, 206, 0.7);
    box-sizing: border-box;
    margin-top: 32px;
  }

  .m-plan-agreement--features {
    position: relative;
    margin: 32px 0 24px 0;

    .m-plan-agreement--features--title {
      height: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $dark-black;
      text-align: left;
    }

    .m-plan-agreement--features--table {
      display: grid;
      grid-row-gap: 8px;
      grid-column-gap: 20px;
      grid-template-columns: 296px 32px;
      width: 625px;
      margin: 13px 0;
    }

    .m-plan-agreement--features--table--item {
      width: 308px;
      height: 32px;
      background: $grey-025;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $black;
      text-align: left;
      padding: 8px 0;

      svg {
        float: left;
        margin: 0 13px;
      }
    }
  }

  .m-plan-agreement--cta {
    text-align: right;
    border-top: 1px solid $grey-200;
    padding: 12px 0;
    margin-top: var(--spacing__unit--2_5);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .m-plan-agreement--cta-quote-expiration-date {
      float: left;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $color-text-mid;
    }

    .m-button {
      padding: 8px 12px;
      background: $blue-600;
      border-radius: 2px;
    }
  }

  .m-legal-description {
    text-align: left;
  }
}
