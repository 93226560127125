// Define nav bar color
$dropdown-header-color: #222; //for nav bar dropdown header
$btn-hover-bg-color: #8ae2f4; // On hover background color for nav bar right side buttons
$m-dropdown-header-bg-color: #edf0f0;
$resource-col-color: #dce4e9;
$even-dropdown-color: #f7fafd;

#dropDownMenu {
  position: relative;
}
.nav-bar-button {
  display: inline-block;
  padding: 15px 25px 15px 25px;
  transition: background-color 0.1s ease-out;
  letter-spacing: 0.5px;
  border-radius: 4px;
  border: 0;
  margin-left: 30px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  background-color: $dropdown-header-color;
  font-size: 16px;
  text-transform: none;
  font-weight: 400;
  color: $white;

  &:hover {
    background-color: $btn-hover-bg-color;
    color: $dropdown-header-color;
  }

  &:focus {
    color: $white;
  }
}

.nav-bar-dropdown {
  float: left;
  display: flex;
}

.nav-bar-mobile {
  .nav-bar-dropdown-header {
    background-color: $m-dropdown-header-bg-color;
  }
}

.nav-bar-dropdown-header {
  letter-spacing: 1px;
  padding: 25px 0px 25px 20px;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 500;
  color: $dropdown-header-color;

  @media #{$large-up} {
    padding: 15px;
    font-size: 16px;

    .w-open,
    &:hover {
      background-color: $plume-darker;
    }
  }

  &:first-child {
    .fn-dropdown-col {
      &:first-child {
        .fn-dropdown-col-item {
          border-bottom: solid;
          border-width: thin;
          padding-bottom: 20px;
          opacity: 0.9;

          &:last-child {
            border-bottom: none;
            padding-bottom: inherit;
          }

          @media #{$large-up} {
            border-bottom: none;
            padding-bottom: 0px;
          }
        }
      }
    }
  }

  &:nth-child(2) {
    svg {
      opacity: 0.8;
    }

    @media #{$large-up} {
      .fn-dropdown-col-item {
        min-height: 70px;

        &:first-child {
          min-height: 50px;
        }
      }

      .fn-dropdown-col:first-child {
        .fn-dropdown-col-item {
          padding-top: initial;

          &:first-child {
            padding-top: inherit;
          }
        }
      }
    }
  }
}

.nav-bar-dropdown-header-inner-tab {
  outline: none;
  color: $dropdown-header-color;
  cursor: pointer;
}

.nav-bar {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 16px;
  position: relative;
  z-index: 3;
  box-shadow: none;

  @media #{$large-up} {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 4px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .logo {
    z-index: 1;
  }

  .m-link-list a.m-link-list--menu-link-mobile {
    position: initial;
  }
}

.fn-dropdown-col {
  height: auto;
  width: 100%;
  background: none;

  @media #{$large-up} {
    top: 38px;
    list-style-type: none;
    letter-spacing: 0.05em;
    text-shadow: none;
    text-align: left;
    background-color: $plume-darker;
    padding: 10px 0px 0px 15px;
    width: fit-content;
    height: 472px;
    min-width: 220px;

    &:nth-child(even) {
      background-color: $even-dropdown-color;
    }

    &:last-child {
      background-color: $resource-col-color;
      min-width: 190px;

      .fn-dropdown-col-item {
        min-height: 50px;

        svg {
          opacity: 1;
        }
      }
    }
  }
}

.fn-dropdown-col-item {
  min-height: 70px;
  padding-top: 20px;
  text-transform: capitalize;

  a {
    font-weight: 400;
    font-size: 24px;
    color: $ashen;

    &:hover {
      color: $dropdown-header-color;
    }
  }

  @media #{$large-up} {
    min-height: 55px;
    padding-top: 10px;
    letter-spacing: normal;

    a {
      font-weight: 500;
      font-size: 16px;
      letter-spacing: initial;
    }

    &:first-child {
      min-height: 50px;
      text-transform: uppercase;
      color: $castle-rock;
      font-weight: 500;
    }
  }
}

.fn-dropdown-col-detail {
  font-size: 20px;
  padding-bottom: 0px;
  font-weight: 500;
  text-transform: none;
  line-height: 24px;
  letter-spacing: 0.8px;

  @media #{$large-up} {
    margin-top: 0;
    color: $ashen;
    font-size: 11px;
    line-height: 18px;
    padding-bottom: 10px;
  }
}

.fn-dropdown-col img {
  max-width: 100px;
  max-height: 15px;
}

.fn-dropdown-grid {
  display: block;
  margin-left: 0px;
  padding-top: 15px;
  position: relative;
  right: 0;
  z-index: 100;

  @media #{$large-up} {
    display: flex;
    padding-top: 25px;
    -webkit-font-smoothing: antialiased;
    position: absolute;
  }

  .fn-dropdown-col-item.fn-dropdown-social-links {
    svg {
      opacity: 1;
    }
  }

  .fn-dropdown-col-item.svg {
    opacity: 0.8;
    max-width: 340px;

    &:hover {
      opacity: 1;
    }

    @media #{$large-up} {
      max-width: 200px;
    }
  }
}

.fn-dropdown-grid-item {
  border-bottom: solid;
  border-width: thin;
  padding: 20px 0 20px 20px;

  .nav-bar-dropdown-header-inner-tab span {
    float: right;
    margin-right: 50px;
  }
}

.fn-dropdown-grid-item {
  &:last-child {
    border-bottom: none;
    padding-bottom: inherit;
  }
}

.fn-dropdown-social-icon {
  width: 27px;

  @media #{$large-up} {
    width: 18px;
  }
}

.fn-dropdown-product-logo {
  max-width: 200px;
  max-height: 20px;
}

.fn-overlay.fn-overlay-show {
  display: block;
  opacity: 1;
}

.fn-overlay {
  position: fixed;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.76);
  opacity: 0;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  display: none;
}

.fn-login-toggle {
  padding-top: 5px;
}

.text-upper-case {
  text-transform: uppercase;
}

.nav-bar-buttons {
  display: flex;
  float: right;

  .m-link-list--link--container {
    a {
      background-color: $dropdown-header-color;

      &:hover {
        background-color: $btn-hover-bg-color;
        color: $black;
      }
    }
  }
}

.nav-bar-dropdown-header-arrow {
  border: solid rgba(0, 0, 0, 0.75);
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin: 0 0 3px 6px;
  transform: rotate(45deg);
  transition-duration: 0.2s;
}

.nav-bar-dropdown-header-arrow--mobile {
  transform: rotate(-45deg);
  margin-top: 5px;
}

// only change arrow for same level , mean no changes for hierarchy
.w-open
  > .nav-bar-dropdown-header-inner-tab
  span.nav-bar-dropdown-header-arrow--mobile {
  transform: rotate(45deg);
}

.nav-bar-mobile {
  font-size: 16px;

  .w-open {
    background-color: $white;
  }
}

.nav-bar-mobile-resource {
  text-transform: none;
  padding: 10px 0 25px 20px;
  font-weight: 500;
  font-size: 21px;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;

  .fn-dropdown-col-item {
    a {
      font-size: 21px;
      font-weight: 500;
    }
  }

  .w-open {
    background-color: inherit;
  }

  .fn-dropdown-col-item:nth-child(4) {
    min-height: 100px;
  }

  .fn-dropdown-grid {
    margin-left: 15px;
  }

  .nav-bar-dropdown-header-inner-tab {
    color: $ashen;
  }
}
