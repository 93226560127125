.m-link-list {
  height: 37px;

  &.is-compact .m-link-list--link--container:nth-of-type(2) .m-link-list--link {
    margin-right: 20px;
  }

  .m-link-list--link--container {
    padding: 5px 10px 0;
    font-size: 13px;
    letter-spacing: 0.05em;
    font-weight: 500;
    float: right;

    &.no-padding {
      padding-left: 0px;
      padding-right: 0px;
      margin-right: 0px;
    }

    & > a {
      font-weight: 500;
    }

    &.active a {
      color: var(--dig-color__primary__base);
    }

    &.menu-container {
      @media #{$small-only} {
        margin-right: 5px;
      }

      &.is-hidden-on-large {
        @media #{$large-up} {
          display: none;
        }
      }
    }
  }

  .m-link-list--menu-arrow {
    position: relative;
    top: -1px;
    left: 3px;
  }

  .m-link-list--link--separator {
    float: left;
    margin-right: 20px;
  }

  a.menu-link {
    margin-right: 20px;
    float: left;
  }

  .m-link-list--menu-arrow {
    @media #{$medium-up} {
      display: inline-block;
    }
    display: none;
    position: relative;
    top: -1px;
  }

  a.m-link-list--menu-link-mobile {
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    top: -4px;
    right: 20px;
    float: right;

    @media #{$large-up} {
      display: none;
      vertical-align: top;
    }
  }

  span.m-link-list--menu-link-mobile-icon {
    content: '';
    position: relative;
    left: 0;
    display: block;
    width: 16px;
    top: 0;
    height: 1px;

    &.c-white {
      -webkit-box-shadow:
        1px 10px 1px 1px $white,
        1px 16px 1px 1px $white,
        1px 22px 1px 1px $white;
      box-shadow:
        0 10px 0 1px $white,
        0 16px 0 1px $white,
        0 22px 0 1px $white;
    }
    &.c-ashen {
      -webkit-box-shadow:
        1px 10px 1px 1px $ashen,
        1px 16px 1px 1px $ashen,
        1px 22px 1px 1px $ashen;
      box-shadow:
        0 10px 0 1px $ashen,
        0 16px 0 1px $ashen,
        0 22px 0 1px $ashen;
    }
  }

  .m-link-list--link-break-1 {
    @media #{$small-only} {
      display: none;
    }
    @media #{$medium-only} {
      display: none;
    }
  }

  .m-link-list--link-break-2 {
    @media #{$small-only} {
      display: none;
    }
  }
  .m-link-list--link {
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.5em;
    text-transform: uppercase;
    outline: none;
    margin-bottom: 16px;
    padding: 0 2px;
    @media #{$small-up} {
      display: none;
    }
    @media #{$large-up} {
      display: block;
    }

    &.m-link-list--signup {
      display: block;
      border-radius: 4px;
      padding: 10px 18px;
      position: relative;
      top: -10px;
      float: left;
      transition: 0.8s;
      @media #{$small-only} {
        display: none;
      }
    }

    .m-link-list-signup-white {
      @extend .m-link-list--signup;
    }

    &.m-link-list--log-in {
      display: block;
      float: left;
      padding-top: 5px;
      padding-bottom: 4px;
      margin-right: 110px;
      position: relative;
      top: -4px;
      @media #{$small-only} {
        display: none;
      }
      &.external-top-bar {
        margin-right: 0;
      }
    }

    &.m-link-list--currentPage {
      color: var(--dig-color__primary__base);
    }
  }
}
