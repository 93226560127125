.m-button {
  text-align: center;
  padding: 12px 20px 10px 20px;
  letter-spacing: 0.05em;
  margin: 5px;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  transition: 0;
  outline: none;
  min-height: 32px;
  text-transform: none !important;

  // DIG overrides
  letter-spacing: 0;
  padding: 0 12px;
  color: var(--dig-color__primary__on-base);
  background-color: var(--dig-color__primary__base);
  font-family: var(--type__body__standard--fontfamily);
  font-size: var(--type__body__standard--fontsize);
  font-weight: var(--type__body__standard--fontweight_strong);

  &.m-button--hidden {
    display: none;
  }
  &:hover {
    background-color: var(--dig-color__primary__base--state-1);
  }
  &.m-button--secondary {
    background-color: var(--dig-color__secondary__base);

    &:hover {
      background-color: var(--dig-color__secondary__base--state-1);
    }
  }
  &:disabled,
  &.disabled {
    background-color: var(--dig-color__disabled__base);
  }
}

.m-button-normalcase,
.m-button-normalcase:not([class^='dig-']) {
  font-weight: 400;
  text-transform: none;
}

.m-button-submit,
.m-button-submit:not([class^='dig-']) {
  .m-button-throbber {
    position: relative;
    top: -2px;
    left: 50px;
    &.m-button-throbber-hidden {
      display: none;
    }
  }
}

.m-input:not([class^='dig-']) {
  .m-input-error {
    margin: 3px 0 7px;
    text-align: left;
  }

  .m-input-error-message {
    text-align: left;
    display: inline;
    font-size: 14px;
  }

  .m-input-error-message-img {
    margin: -2px 5px 0 0;
    width: 17px;
    height: 17px;
    display: inline;
  }
}

.m-input-error:not([class^='dig-']) > input:focus {
  border: 1px solid $red;
}
