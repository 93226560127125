.m-verify-email {
  text-align: center;
  letter-spacing: 0;

  .m-verify-email--container {
    border: 1px solid $tepid-sloth;
    color: $grey;

    .m-verify-email--text {
      padding: 20px;
      background-color: $plume;
      border-bottom: 1px solid $warm-chinchilla;
      font-size: 16px;
      line-height: 1.3em;

      h4 {
        margin: 20px 0 30px 0;
        font-weight: 400;
      }
      img {
        width: 32px;
        margin-bottom: 10px;
      }
    }

    .m-verify-email--resend {
      padding: 20px;
      font-size: 12px;
      background-color: white;
    }
  }

  .m-verify-email--legal {
    color: $light-grey;
    font-size: 10px;
    background-color: white;
    padding: 5px;
    display: block;
  }
}
