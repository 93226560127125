.m-oauth-authorize {
  width: 600px;
  margin: auto;
  font-size: 15px;
  font-weight: 300;
  color: $castle-rock;

  .m-oauth-authorize-header {
    text-align: center;
    margin-bottom: 30px;

    h1 {
      font-size: 12px;
      font-weight: 500;
      color: $grey;
      margin-bottom: 10px;
    }
  }

  .m-oauth-authorize-header-v2 {
    text-align: center;
    margin-bottom: 30px;

    h1 {
      font-size: 25px;
      font-weight: 500;
      color: $black;
      margin-bottom: 30px;
    }
  }

  .m-oauth-authorize-scopes {
    b {
      font-weight: 500;
    }
    p {
      margin-bottom: 10px;
    }
    li {
      margin-bottom: 10px;
      list-style: disc;
      margin-left: 20px;
    }
    .m-oauth-authorize-billing {
      margin-top: 30px;
    }
  }

  .m-oauth-authorize-error {
    text-align: center;
    margin-bottom: 10px;
  }

  .m-oauth-authorize-actions {
    margin-top: 20px;

    .m-oauth-authorize-accept-btn {
      margin-right: 15px;
    }
  }

  .m-oauth-authorize-actions-v2 {
    margin-top: 30px;
    text-align: center;

    .m-oauth-authorize-accept-btn {
      margin-left: 15px;
    }
  }
}
