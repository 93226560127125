.top-login-bar {
  display: none;
  @media #{$large-up} {
    height: 28px;
    background-color: $plume;
    position: relative;
    z-index: 4;
    display: block;

    .pro-pro-bundle--row {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      max-width: 82rem;
    }

    .top-login-bar-dropdown {
      float: right;
      position: relative;
      padding: 6px 0px;
      font-weight: bold;
      font-size: 16px;
      border-bottom: 4px solid #8ae2f4;
      line-height: 14px;
      width: 75px;
      text-align: center;
    }
    .pro-pro-bundle--upsell-text {
      flex: 1;
      padding: 6px 0px;
      font-size: 14px;
      text-align: center;
    }

    .pro-pro-bundle-upsell-text-bold {
      font-weight: 500;
    }

    .pro-pro-bundle-upsell-link {
      text-decoration: underline;
      color: #0061fe;
    }

    .fn-dropdown-grid {
      display: block;
      padding-top: 0px;
    }

    .fn-dropdown-col {
      width: auto;
      height: auto;
      background-color: $plume;
      min-width: 150px;
      padding: 15px 10px 0px;
      text-align: right;
    }

    .fn-dropdown-col-item {
      opacity: 1;
      min-height: auto;
      text-transform: none;
    }

    a:hover,
    a:focus {
      color: $castle-rock;
    }
  }
}
