// The leading underscore in _text.scss make this a partial. If you don't extend
// any of these, it won't emit any CSS

// Font settings are based on this:
// https://www.figma.com/file/N7i3rROqbDjTqopXdE9KrSMC/Foundation-Components?node-id=992%3A218

@import 'colors';
@import 'fonts';

$global-hs-font-family: proxima-nova, sans-serif;
$display-font-family: 'Taviraj', serif;

%text-global {
  font-family: $global-hs-font-family;
}

%text-display {
  font-family: $display-font-family;
}

%text-display-xlarge {
  @extend %text-display;
  font-size: 72px;
  line-height: 110%;
}

%text-display-large {
  @extend %text-display;
  font-size: 48px;
  line-height: 110%;
}

%text-display-standard {
  @extend %text-display;
  font-size: 40px;
  line-height: 110%;
}

%text-display-small {
  @extend %text-display;
  font-size: 32px;
  line-height: 110%;
}

%text-display-xsmall {
  @extend %text-display;
  font-size: 24px;
  line-height: 110%;
}

%text-heading1 {
  font-family: $global-hs-font-family;
  font-size: 30px;
  line-height: 1.33;
  color: $color-text-darker;
}

%text-heading2 {
  font-family: $global-hs-font-family;
  font-size: 24px;
  line-height: 1.33;
  color: $color-text-darker;
}

%text-heading3 {
  font-family: $global-hs-font-family;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  color: $color-text-darker;
}

%text-heading4 {
  font-family: $global-hs-font-family;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: $color-text-darker;
}

%text-heading5 {
  font-family: $global-hs-font-family;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: $color-text-darker;
}

%text-heading6 {
  font-family: $global-hs-font-family;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 1px;
  color: $color-text-darker;
}

// body
%text,
%body {
  font-family: $global-hs-font-family;
  font-size: 14px;
  line-height: 1.43;
  color: $color-text-darker;
}

%text-large {
  font-family: $global-hs-font-family;
  font-size: 16px;
  line-height: 1.5;
  color: $color-text-darker;
}

%text-tiny {
  font-family: $global-hs-font-family;
  font-size: 12px;
  line-height: 1.33;
  color: $color-text-mid;
}

%text-action {
  @extend %text-tiny;
  color: $color-text-action;
}

%text-error {
  @extend %text-tiny;
  color: $color-text-error;
}

%text-dim {
  @extend %text-tiny;
  color: $color-text-mid;
}
