.m-style-guide {
  &.section {
    border-top: 1px solid $warm-chinchilla;
    border-bottom: 1px solid $warm-chinchilla;
  }

  &.m-style-guide--code-pre {
    font-family: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace;
    font-size: 11px;
    line-height: 140%;
    white-space: pre;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    background: #faf8f0;
  }

  #content code {
    display: block;
    padding: 0.5em 1em;
    border: 1px solid #bebab0;
  }

  &.m-style-guide--top-link {
    margin-left: 10px;
    font-size: 15px;
    display: inline-block;
  }

  &.swatch {
    border: 1px solid $cinder-block;
    padding: 0;
    margin: 0;
    height: 150px;
    width: 150px;
  }
  &.swatch-name {
    width: 150px;
    text-align: center;
  }
}
