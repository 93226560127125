.m-api-docs-section {
  float: left;
  width: 100%;
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: solid 1px $warm-chinchilla;

  &.no-separator,
  &.last {
    border-bottom: none;
  }

  .m-api-docs-section--title {
    font-size: 30px;
    font-weight: 400;
    color: $ashen;
    margin-bottom: 30px;
    line-height: 1.3em;
  }

  .m-api-docs-section--content {
    float: left;
    width: 100%;
    font-weight: 400;
    color: $ashen;
  }
}

.m-api-docs-sub-section {
  float: left;
  margin-bottom: 25px;
  width: 100%;

  .m-api-docs-sub-section--title {
    float: left;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0 15px;
    &.l-margin-b-0 {
      margin-bottom: 0;
    }
  }

  .m-api-docs-sub-section--sub-title {
    float: left;
    width: 100%;
    margin: 5px 0;
    font-weight: 500;
    &.l-margin-b-0 {
      margin-bottom: 0;
    }
  }

  .m-api-docs-table--event-field {
    line-height: 22px;
    padding: 2px 0;

    .m-api-docs-table--event-field--sub {
      letter-spacing: 0.05em;
      font-size: 14px;
      line-height: 1.3em;
      border-left: solid 3px $cinder-block;
      margin-left: 0;
      padding-left: 10px;
    }
  }
}

.m-api-docs-instructions {
  float: left;
  width: 100%;
  margin: 10px 0 30px;

  .m-api-docs-instructions--step-text {
    float: left;
    text-align: left;
    padding-right: 20px;
    max-width: 400px;
  }

  .m-api-docs-instructions--step-image {
    float: right;
    text-align: center;
    max-width: 450px;
    border-radius: 3px;
    padding: 3px;
    background: $warm-chinchilla;
    margin: 0;

    & > img {
      width: 100%;
    }
  }
}

.m-api-docs-important {
  color: $goldenrod;
  font-weight: 500;
}

.m-api-docs-deprecated {
  text-transform: uppercase;
  color: $red;
}

.m-api-docs-note {
  float: left;
  width: 100%;
  padding: 10px;
  border: 1px solid $warm-chinchilla;
  word-break: break-word;
}

.m-api-docs-table {
  font-weight: 400;
  border: solid 1px $warm-chinchilla;
  border-spacing: 0;
  margin: 10px 0 20px;
  float: left;
  display: block;
  overflow-x: scroll;
  max-width: 100%;

  tr {
    & > th {
      font-weight: 500;
      text-transform: uppercase;
      background: $plume;
      border-bottom: solid 1px $warm-chinchilla;
      padding: 10px 20px;
      text-align: left;
      &.no-b-border {
        border-bottom: none;
      }
    }
    & > th + td {
      border-left: solid 1px $warm-chinchilla;
    }
    & > td {
      padding: 10px 20px;
      text-align: left;
      border-bottom: solid 1px $warm-chinchilla;
      border-right: solid 1px $warm-chinchilla;
      &:last-child {
        border-right: none;
      }
    }
    &:last-child > th,
    &:last-child > td {
      border-bottom: none;
    }
  }

  .m-code-example {
    padding: 0;
    .m-code-example--code > * {
      word-break: normal;
    }
    .m-code-example--code {
      overflow-x: auto;
      padding: 15px;
    }
  }

  span.m-api-docs--white-labeling-color-swatch {
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    border: 1px solid $warm-chinchilla;
    margin-right: 15px;
  }

  .m-api-docs-table--example {
    clear: both;
    margin-top: 20px;
  }
}

.m-api-docs-list {
  &.narrow {
    .m-api-docs-list--item {
      margin: 5px 0;
    }
  }

  .m-api-docs-list--item {
    float: left;
    width: 100%;
    margin: 10px 0;
    word-break: break-word;
  }

  .m-api-docs-list--item--detail {
    display: block;
    float: left;
    width: 100%;
    margin-top: 5px;
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    color: $grey;
  }

  form {
    float: left;
    width: 100%;
  }
}

.m-api-docs-section--error {
  margin: 10px 0;
  color: $blood;
  font-weight: 500;
}
