.m-feature {
  color: $ashen;

  .columns {
    border: 0px solid grey;
  }
  .m-feature--header {
    text-transform: uppercase;
    text-align: left;
  }
  .m-feature--text {
    padding-bottom: 40px;
    text-align: left;
  }

  .m-feature--sub-header {
    padding-bottom: 60px;
  }

  .m-feature--align-text-right-panel {
    @media #{$medium-up} {
      text-align: right;
    }
    @media #{$small-only} {
      text-align: center;
      padding-bottom: 40px;
    }
  }

  .m-feature--align-text-left-panel {
    @media #{$medium-up} {
      text-align: left;
    }
    @media #{$small-only} {
      text-align: center;
    }
  }

  /* The ghost, nudged to maintain perfect centering */
  .m-feature--vertical-align-wrapper:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em; /* Adjusts for spacing */
  }

  /* The element to be centered, can
     also be of any width and height */
  .m-feature--vertical-align {
    display: inline-block;
    vertical-align: middle;
  }
  .m-feature--inner-container {
    @media #{$large-up} {
      min-height: 275px;
    }
  }

  .m-feature--inner-container-left {
    @media #{$medium-up} {
      padding-right: 25px;
    }
  }

  .m-feature--inner-container-right {
    @media #{$medium-up} {
      padding-left: 25px;
    }
  }

  .tr {
    text-align: left;
  }
  .m-feature--first-sentence {
    font-weight: 600;
  }

  @media #{$medium-up} {
    .m-feature--outer-container {
    }
  }
  @media #{$small-only} {
    .m-feature--outer-container {
      max-width: 400px;
    }
  }

  .m-feature--button-outer-container {
    text-align: left;
  }
  .m-feature--button-inner-container {
    display: inline-block;
  }
}
