.m-resend-notification {
  .m-resend-notification--input--container {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }

  .m-resend-notification--input {
    & > input {
      @media #{$medium-up} {
        width: 225px;
        float: left;
        margin-bottom: 0;
      }
    }

    &:first-child > input {
      @media #{$medium-up} {
        float: right;
      }
      background-color: $white;
      opacity: 0.6;
      filter: alpha(opacity=60);
    }
  }

  .m-resend-notification--error {
    display: none;
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    color: $red;
  }

  &.has-error {
    .m-resend-notification--error {
      display: inline;
    }
  }
}
