.m-api-comparison {
  .m-api-comparison--label {
    z-index: 100;
  }
  .m-api-comparison--header {
    font-size: 26px;
    font-weight: 300;
    color: $ashen;
    height: 80px;
    text-align: center;
    padding-top: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .m-api-comparison--row {
    font-size: 18px;
    color: $castle-rock;
    height: 50px;
    margin-bottom: 5px;
    padding: 17px 0;
    padding-right: 4px;
    text-align: center;
  }

  @media #{$small-only} {
    .m-api-comparison--row:nth-child(12) {
      height: 80px;
    }

    .m-api-comparison--label {
      .m-api-comparison--header {
        font-size: 15px !important;
      }
    }
    .m-api-comparison--header {
      font-size: 14px;
    }
    .m-api-comparison--row {
      font-size: 14px;
      height: 60px;

      .m-api-comparison--row--text {
        letter-spacing: 0.03em;
      }

      .m-api-comparison--row--button {
        padding-top: 5px;
      }
    }
  }
  @media #{$medium-only} {
    .m-api-comparison--row:nth-child(12) {
      height: 70px;
    }
    .m-api-comparison--row {
      font-size: 15px;
    }
  }

  // first column
  .m-api-comparison--label {
    .m-api-comparison--header {
      font-size: 18px;
      font-weight: normal;
      color: $grey;
      border-bottom: 2px solid $cinder-block;
      text-align: left;
      padding-top: 50px;
      padding-left: 17px;
    }

    .m-api-comparison--row {
      background: $plume;
      text-align: left;
      padding-left: 17px;

      .m-api-comparison--row--text {
        padding-right: 7px;
      }

      .m-api-comparison--row--tooltip {
        margin-bottom: -2px;
        width: 17px;
        height: 17px;
        content: url('../question_mark@2x.png');

        &:hover {
          content: url('../question_mark_hover@2x.png');
        }
      }

      .m-api-comparison--row--button {
        // padding-top: 5px;
        display: inline-block;
      }
    }
  }

  // second column
  .m-api-comparison--hellosign {
    box-shadow: -1px 1px 9px 1px #e6e6e6;
    border: 2px solid #a2bf00;

    .m-api-comparison--row {
      font-weight: bold;
      border-top: 1px solid $warm-chinchilla;

      a {
        font-weight: bold;
      }

      .m-api-comparison--icon {
        height: 24px;
      }
    }
  }

  // third column
  .m-api-comparison--competitor {
    .m-api-comparison--row {
      background: $plume;
      color: $ashen;

      .m-api-comparison--row--text {
        letter-spacing: 0.03em;
      }

      .m-api-comparison--icon {
        height: 8px;
      }
    }
  }
}

.m-api-comparison--row--tooltip--bubble {
  opacity: 0.85 !important;

  .m-tooltip--bubble--content {
    font-size: 15px !important;
    font-weight: 300 !important;
  }
}

.m-api-comparison--cta--subheader {
  font-size: 18px;
  letter-spacing: 0.03em;
}

.m-api-comparison--disclaimer {
  font-size: 10px;
  font-style: italic;
  font-weight: 200;
  color: $cinder-block;
  letter-spacing: 0.03em;
}
