.m-jobs {
  letter-spacing: 0.05em;

  .m-jobs--seperator {
    float: left;
    border-top: solid 1px $warm-chinchilla;
    margin: 0 0 50px;
  }

  .m-jobs--grey-container {
    float: left;
    background: $plume;
    padding: 60px 0 20px;

    @media #{$medium-up} {
      padding-bottom: 60px;
    }
  }

  .m-jobs--life-items {
    color: $ashen;
    font-weight: 300;
    padding: 0 20px;

    & > span {
      line-height: 26px;
    }
  }

  .m-jobs--life-items--title {
    font-weight: 800;
    font-size: 14px;
    color: $ashen;
    margin-bottom: 15px;
  }

  .m-jobs--life-items--sep {
    color: $ashen;
    margin: 0 2px;
  }

  .m-jobs--perks {
    float: left;
    margin-top: 40px;
    margin-bottom: 0;
    @media #{$medium-only} {
      padding: 0 10%;
    }
  }

  .m-jobs--perks--item {
    float: left;
    text-align: center;

    @media #{$small-only} {
      margin-bottom: 40px;
    }
    @media #{$medium-only} {
      margin-bottom: 40px;
    }

    @media #{$large-up} {
      background-repeat: no-repeat;
      background-size: auto 100%;
      height: 32px;

      &.salary {
        position: relative;
        left: -15px;
        background-image: url('../icon_money@2x.png');
        background-position: 60px 0;
      }

      &.health {
        background-image: url('../icon_medical@2x.png');
        background-position: 0 0;
      }

      &.vacation {
        background-image: url('../icon_vacation@2x.png');
        background-position: 50px 0;
      }

      &.apple {
        background-image: url('../icon_apple@2x.png');
        background-position: 30px 0;
      }
    }
  }

  .m-jobs--perks--item-img {
    background-repeat: no-repeat;
    background-size: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 64px;
  }

  .m-jobs--perks--item-text {
    & > p {
      font-weight: 500;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 13px !important;
    }

    @media #{$small-up} {
      float: left;
      margin-top: 10px;
      text-align: center;
      width: 100%;

      & > p {
        max-width: 50%;
        margin: 0 auto;
      }
    }

    @media #{$large-up} {
      float: none;
      text-align: left;
      height: 42px;
      padding: 15px 0;
      & > p {
        margin: 0;
        max-width: 100%;
        padding-left: 42px;
        padding-top: 12px;
        text-align: center;
      }
    }
  }

  .m-jobs--openings {
    float: left;
    margin-bottom: 40px;
  }

  .m-jobs--openings--title {
    font-weight: 800;
    font-size: 14px;
    text-align: center;
    color: $ashen;
    text-transform: uppercase;
  }

  .m-jobs--openings--title-sub {
    font-weight: 400;
    font-size: 13px;
    font-style: italic;
    color: $ashen;
    text-align: center;
    margin-top: 5px;
  }

  .m-jobs--openings--list {
    float: left;
    margin: 50px 0;
    padding: 0 20px;
    text-align: center;
  }

  .m-jobs--openings--department {
    float: left;
    margin-bottom: 30px;
  }

  .m-jobs--openings--department--title {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .m-jobs--openings--job {
    & > a {
      line-height: 24px;
      font-size: 14px;
      color: $ashen;
    }
    & > a:hover {
      color: var(--dig-color__primary__base);
    }
  }
}

.m-mozaique {
  .m-mozaique--column {
    float: left;

    @media #{$small-only} {
      margin: 0 !important;
      width: 100%;
      max-height: 380px !important;
      overflow-y: hidden;

      & > img {
        width: 100%;
      }
    }
  }
}

.m-mozaique-grey-bg {
  float: left;
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0;
  margin-top: -100px;
  background: #f7f8f9;
  z-index: -1;
}
