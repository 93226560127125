.m-code-example {
  float: left;
  width: 100%;
  margin: 10px 0px;
  padding: 15px;
  overflow: hidden;
  border: solid 1px $warm-chinchilla;
  color: #4d4d4c;

  .m-code-example--code {
    padding: 20px;
  }

  .m-code-example--code,
  .m-code-example--code--json,
  .m-code-example--code > *,
  .m-code-example--code--json > * {
    font-family: Menlo, 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', Monaco,
      Consolas, monospace;
    font-size: 12px;
    letter-spacing: 0.025em;
    line-height: 18px;
    overflow: auto;
  }

  .m-code-example--code--json {
    .key {
      color: $json-key;
    }
    .string {
      color: $json-string;
    }
    .boolean {
      color: $json-boolean;
    }
    .number {
      color: $json-number;
    }
    .null {
      color: $json-null;
    }
  }

  &.with-response {
    .m-code-example--code--json {
      margin-top: 20px;
    }
  }

  &.with-response.is-collapsed {
    .m-code-example--code--json {
      display: none;
    }
    .m-code-example--expand-link {
      display: inline;
    }
  }

  .m-code-example--expand-link {
    float: left;
    display: none;
    width: 100%;
    margin: 15px 0px 0px;
    font-size: 12px;
    font-weight: 500;

    .m-code-example--expand-link--link {
      line-height: 17px;
    }

    .m-code-example--expand-link--dots {
      float: left;
      background: $warm-chinchilla;
      border: solid 1px $cinder-block;
      color: $ashen;
      padding: 0px 7px 3px;
      margin-right: 10px;
      line-height: 0.8em;
      &:hover {
        cursor: pointer;
        border: solid 1px $light-grey;
      }
    }
  }
}
