.m-getting-started-page {
  .m-getting-started-page--title {
    font-size: 30px;
    font-weight: 200;
    color: $ashen;
    margin-bottom: 15px;
  }
  .m-getting-started-page--number {
    float: left;
    width: 21px;
    font-size: 24px;
    margin-right: 5px;
    color: $ashen;
  }

  .m-getting-started-page--item {
    font-size: 24px;
  }

  .m-getting-started-page--description {
    margin-left: 27px;
    font-size: 15px;
  }
}
