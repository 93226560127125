.m-peek-a-boo {
  .m-peek-a-boo--image {
    margin-bottom: 0px;
    position: relative;
    bottom: -350px;
    padding-bottom: 0px;
    transition: 0.8s;
    &.is-visible {
      bottom: -4px;
    }
  }
  .m-peek-a-boo--parallax {
    overflow-y: hidden;
    padding-bottom: 0px;
    margin-bottom: 0px;
    @media #{$small-only} {
      margin-bottom: 40px;
    }
  }
}
