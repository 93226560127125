@import 'colors';

.m-notification {
  .m-notification--close {
    position: absolute;
    top: 0px;
    right: 14px;
    font-size: 18px;
  }
  a {
    text-decoration: underline;
  }
  .success {
    background-color: $grasshopper;
    p,
    a {
      color: $white;
      strong {
        font-weight: 500;
      }
    }
  }
  .error {
    background-color: $blood;
    p,
    a {
      color: $white;
      strong {
        font-weight: 500;
      }
    }
  }
  .info {
    background-color: $warm-chinchilla;
    p,
    a {
      color: $ashen;
      strong {
        font-weight: 500;
      }
    }
  }
  .warning {
    background-color: $yellow-400;
    p,
    a {
      color: $dark-black;
      strong {
        font-weight: 500;
      }
    }
  }
  .api-notification {
    background-color: $castle-rock;
    p,
    a {
      color: $white;
      strong {
        font-weight: 500;
      }
    }

    .api-notification-button {
      background-color: $goldenrod;
      text-decoration: none;
      padding: 4px 10px;
    }
  }
  .hs-notification {
    background-color: $ashen;
    p,
    a {
      color: $white;
      strong {
        font-weight: 500;
      }
    }
    .hs-notification-button {
      text-decoration: none;
      padding: 3px 10px;
      height: 26px;
    }
  }
}
