.m-mobile-menu {
  font-size: 14px;
  color: #222;
  text-align: left;
  text-transform: uppercase;
  background-color: #dce4e9;

  .m-mobile-menu--x-icon {
    float: right;
    font-size: 45px;
    margin-top: -10px;
    color: #4e4e4e8a;

    &:hover {
      cursor: pointer;
    }
  }

  .nav-bar-logo {
    float: left;
    width: 65%;
    max-width: 250px;
  }

  .m-mobile-menu--icon-container {
    text-align: left;
    margin-left: 1.1rem;
  }

  .m-mobile-menu--social-icon-container {
    display: inline-text;
  }

  .m-mobile-menu--social-icon {
    padding-left: 1px;
    padding-right: 1px;
  }

  .m-mobile-menu--image-container {
    display: inline-text;
    margin-top: 20px;

    .m-footer--badge {
      width: 100px;
    }
  }

  .m-mobile-menu--copyright-container {
    margin-left: 1.1rem;
  }

  .m-mobile-menu--link {
    color: $warm-chinchilla;

    &.m-mobile-menu--link--currentPage {
      color: var(--dig-color__primary__base);
    }
  }

  .m-mobile-menu--section {
    ul {
      list-style-type: none;

      .heading {
        font-weight: 800;
        margin-bottom: 5px;
      }

      li {
        padding-bottom: 4px;
      }
    }
  }

  .m-mobile-menu--bottom {
    text-align: center;
  }

  .m-mobile-menu--signupin-link-container {
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;
  }

  .m-mobile-menu--signup-link {
    background-color: white;
    font-weight: 500;
    font-size: 13px;
    color: black;
    border-radius: 2px;
    padding-top: 5px;
    padding-bottom: 4px;
    padding-right: 8px;
    padding-left: 8px;
    margin-left: 20px;
    margin-right: 50px;
    position: relative;
    top: -3px;
    float: left;
  }
  .m-mobile-menu--signin-link {
    color: $warm-chinchilla;
    float: left;
    font-weight: 500;
    font-size: 13px;
    padding-top: 5px;
    padding-bottom: 4px;
    position: relative;
    top: -3px;
  }

  .m-mobile-menu--bottom-border {
    border-bottom: 1px solid $castle-rock;
    position: absolute;
    bottom: 0;
    right: 6%;
    left: 6%;
  }

  .m-mobile-menu--top-link-container {
    height: 70px;
    padding: 20px;
    background-color: white;
  }
}
