.m-toggle {
  // no gutter width on small-medium
  padding-left: 0px;
  padding-right: 0px;
  width: 300px;
  float: left;
  @media #{$large-up} {
    // adjust right gutter on large because of the border
    padding-right: 4px;
  }
  text-align: center;
  font-size: 12px;
  .m-toggle--choice {
    font-weight: 500;
    letter-spacing: 0.05em;
    &.is-active-cerulean {
      background: var(--dig-color__primary__base);
      color: white;
      padding: 12px;
      border: 1px var(--dig-color__primary__base) solid;
    }
    &.is-inactive-cerulean {
      color: var(--dig-color__primary__base);
      background: white;
      padding: 12px;
      border: 1px $cinder-block solid;
      &:nth-child(2n + 1) {
        border-right: none;
      }
      &:nth-child(2n) {
        border-left: none;
      }
    }
    &.is-active-goldenrod {
      background: $goldenrod;
      color: white;
      padding: 12px;
      border: 1px $goldenrod solid;
    }
    &.is-inactive-goldenrod {
      color: $goldenrod;
      background: white;
      padding: 12px;
      border: 1px $cinder-block solid;
      &:nth-child(2n + 1) {
        border-right: none;
      }
      &:nth-child(2n) {
        border-left: none;
      }
    }
  }
  cursor: pointer;
}
