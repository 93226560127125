@import 'colors';

input[disabled],
textarea[disabled],
input[readonly],
textarea[readonly],
select[disabled] {
  background: $warm-chinchilla;
}

#root {
  height: 100%;
}

/* -----  IN-APP GENERIC LAYOUT  ---------------- */

.l-app {
  .m-button {
    /* Shrink buttons a little so they don't look so big */
    margin: 0px;
  }
  letter-spacing: 0.05em;

  .l-app--canvas {
    min-width: 1180px;
    max-width: 1180px;
    padding: 0 10px;
  }

  .l-app--header {
    float: left;
    width: 100%;
    margin: 30px 0 0 0;
  }

  .l-app--container {
    float: left;
    width: 100%;
  }

  .l-app--content {
    float: left;
    max-width: 900px;
    padding-bottom: 100px;
  }

  .l-app--template-link-background {
    background: center no-repeat url(~doc_bg.png);
    min-height: 1560px;
    padding: 100px 0;
  }

  .l-app--footer {
    float: left;
    width: 100%;
    padding: 0 10px;
    min-height: 75px;
  }

  a {
    text-decoration: none !important;
    letter-spacing: 0.05em;
  }

  .form-overlay {
    background: $white;
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  &.is-minimal {
    .l-app--content {
      max-width: 100%;
      width: 100%;
    }
  }
}

/* -----  IN-APP LAYOUT COMPONENTS  ------------- */

.m-app-topbar {
  height: 100%;
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;

  .m-app-topbar--logo {
    width: 180px;
    line-height: 42px;
  }

  .m-app-topbar--actions {
    white-space: nowrap;

    & > .m-button:first-child {
      text-transform: none;
    }
  }

  .m-app-topbar--signdocs {
    display: inline;
    margin-left: 40px;
    cursor: pointer;

    .m-app-topbar--signdocs--title {
      display: inline;
    }

    .m-app-topbar--signdocs--title--text {
      color: $ashen;
      line-height: 43px;
      display: inline-block;
      font-weight: 400;
    }

    .m-app-topbar--signdocs--icon--img {
      width: 32px;
      margin-right: 10px;
      position: relative;
      vertical-align: middle;
    }
  }

  .m-button {
    min-width: 100px;
  }

  .m-app-topbar--upgrade-btn {
    display: inline-block;
    margin-left: 36px;
    width: 100px;
  }

  .m-app-topbar--menu {
    display: inline;
    margin-left: 30px;
    cursor: pointer;

    .m-app-topbar--menu--title {
      display: inline;
    }

    .m-app-topbar--menu--title--text {
      color: $ashen;
      line-height: 43px;
      display: inline-block;
      font-weight: 400;
    }

    .m-app-topbar--menu--title--icon > img {
      width: 9px;
      margin-left: 5px;
      margin-top: -1px;
    }

    &.is-disabled {
      cursor: default;

      .m-app-topbar--menu--title--icon {
        display: none;
      }
    }
  }
}

.m-app-leftmenu {
  float: left;
  width: 260px;
  font-size: 14px;
  text-align: left;
  padding: 5px 0 20px 0;
  line-height: 1;

  .m-app-leftmenu--section--logo-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 54px;

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  .m-app-leftmenu--section--items {
    display: none;
    margin-left: 10px;

    .m-app-leftmenu--section--item {
      margin-bottom: 15px;

      &.is-active {
        font-weight: 500;
      }

      &.is-active > a {
        font-weight: 500;
        color: $ashen;
      }

      & > a {
        color: $ashen;
        &:hover {
          color: $black;
          text-decoration: none !important;
        }
      }
    }
  }

  .m-app-leftmenu--section {
    margin-bottom: 30px;

    .m-app-leftmenu--section--title {
      & > a {
        color: $ashen;
        font-weight: 400;
        width: 100%;
        &:hover {
          color: $black;
          text-decoration: none !important;
        }
      }
    }

    .m-app-leftmenu--icon--beta {
      background-color: #f59600;
      padding: 1.5px 5px;
      color: white;
      border-radius: 16px;
      font-size: 8px;
      bottom: 4px;
      position: relative;
      left: 5px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.01em;
      border: 0.5px solid #c28133;
    }

    &.is-active,
    &.is-expanded {
      .m-app-leftmenu--section--title,
      .m-app-leftmenu--section--title > a {
        font-weight: 500;
        margin-bottom: 15px;
      }

      .m-app-leftmenu--section--items {
        display: block;
      }
    }

    &.is-expanded {
      .m-app-leftmenu--section--title,
      .m-app-leftmenu--section--title > a {
        font-weight: 400;
      }
    }
  }

  .m-app-leftmenu--section--item--dot {
    & > img {
      width: 6px;
      margin-right: 7px;
      position: relative;
      top: -1px;
    }
    &.is-blank {
      margin-right: 15px;
    }
  }

  .m-app-leftmenu--fax-numbers {
    background: $plume;
    padding: 15px;
    margin-right: 75px;
    border: solid 1px $warm-chinchilla;

    .m-app-leftmenu--fax-numbers--title {
      color: $goldenrod;
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 5px;
    }

    .m-app-leftmenu--fax-numbers--list {
      max-height: 280px;

      &.is-scrollable {
        overflow-y: scroll;
      }

      .m-app-leftmenu--fax-numbers--list--item {
        color: $ashen;
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.m-app-footer {
  padding-top: 15px;
  border-top: solid 1px $warm-chinchilla;

  .m-app-footer--logo {
    float: left;

    .m-app-footer--logo--img {
      width: 32px;
    }
  }

  .m-app-footer--links {
    float: left;
    line-height: 32px;
    margin-left: 30px;
  }

  .m-app-footer--links--link {
    float: left;
    margin-right: 25px;
    font-size: 14px;

    & > a {
      color: $grey;
      &:hover {
        color: $ashen;
      }
    }
  }

  .m-app-footer--social {
    float: right;
  }

  .m-app-footer--social--icon {
    float: right;
    margin-left: 5px;

    &:hover {
      cursor: pointer;
    }

    .m-app-footer--social--icon--img {
      width: 24px;
      height: 24px;
    }
  }
}

.m-app-header--menu-item {
  border-bottom: 1px solid $warm-chinchilla;
  color: $ashen;

  &:last-child {
    border-bottom: none;
  }
}

.m-app-header--menu-item--hoverable {
  &:hover {
    background: $plume;
    color: $black;
  }
}

.m-app-topbar--menu--signature {
  padding: 5px 0;
  line-height: 42px;

  .m-app-topbar--menu--signature--sig {
    align-items: center;

    .m-app-topbar--menu--signature--image {
      width: 100%;
      text-align: center;
      margin-bottom: 2px;
    }

    &.no-sig {
      display: flex;
      border-radius: 2px;
      border: solid 1px $warm-chinchilla;
      background: $plume;
      padding: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;

      & > img {
        width: 24px;
        height: 24px;
        position: relative;
      }

      & > span {
        margin-left: 10px;
        line-height: 17px;
        text-align: center;
      }
    }
  }

  .m-app-topbar--menu--signature--edit {
    font-size: 12px;
    letter-spacing: 0.05em;
  }
}

.m-app-topbar--menu--credits {
  padding: 5px 0;

  & > div {
    padding-top: 10px;
    line-height: 14px;
  }

  .m-app-topbar--menu--credits--line {
    font-size: 12px;
    font-weight: 300;
    padding-bottom: 5px;
    padding-top: 0;
  }

  .m-app-topbar--menu--credits--title {
    color: $cinder-block;
    letter-spacing: 0.05em;
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 7px;
  }
}

.m-sig-modal {
  width: 750px !important;
  height: 385px !important;
  text-align: left !important;
  border-left: 0px !important;

  .initials {
    display: none;
  }

  .is-hidden {
    display: none;
  }

  .m-sig-modal--overlay {
    background: $black;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: 0.3;
    filter: alpha(opacity=30);
  }

  .m-sig-modal--container {
    float: left;
    width: 100%;
    height: 100%;
  }

  .m-sig-modal--area {
    float: left;
    position: relative;
    width: 100%;
    height: 385px;
    border: none;

    & > div {
      height: 100%;
    }

    .m-sig-modal--area--close {
      position: relative;
      z-index: 9999;
      float: right;
      margin: 10px;
      margin-left: 0px;
      width: 10px;
      cursor: pointer;
      opacity: 0.8;
      filter: alpha(opacity=80);
      &:hover {
        opacity: 1;
        filter: alpha(opacity=100);
      }
    }
  }

  .m-sig-modal--menu {
    float: left;
    width: 175px;
    height: 300px;
    padding-top: 50px;
    padding-left: 0px;
    margin-left: 0px;
    background-color: $plume;
    border-right: solid 1px $tepid-sloth;

    .m-sig-modal--menu--sig-type-warning {
      float: left;
      font-size: 10px;
      color: #a0a0a0;
      font-weight: 300;
      line-height: 1.2em;
      letter-spacing: 0.05em;
      width: 80%;
      margin-top: 10px;
      padding-left: 25px;
    }

    & > ul {
      padding: 0;
      border-radius: 0;
      border: none;
      color: $ashen;
      font-weight: 400;
      margin-right: 0;
      margin-left: 0;
    }

    & > ul > li {
      border: none;
      color: $ashen;
      font-size: 14px;
      float: left;
      width: 100%;
      border-bottom: solid 1px $tepid-sloth;

      & > a {
        float: left;
        width: 100%;
        color: $ashen;
        padding: 10px 0 10px 25px;
        text-decoration: none !important;
        letter-spacing: 0.05em;
      }

      &:hover {
        background: $warm-chinchilla;
      }

      &.is-active {
        background: $warm-chinchilla;
        border-left: solid 5px $grasshopper;
        & > a {
          padding-left: 20px;
        }
      }

      &:first-child {
        border-top: solid 1px $tepid-sloth;
        margin-bottom: 40px;
      }

      &:nth-child(2) {
        border-top: solid 1px $tepid-sloth;
      }
    }
  }

  .m-sig-modal--right-container {
    position: absolute;
    right: 0;
    width: 575px;
  }

  .m-sig-modal--right-wrapper {
    position: relative;
    height: 385px;
    width: 100%;
  }

  .m-sig-modal--tabs {
    margin-right: 30px;
    margin-left: 55px;
    margin-top: 0px;

    & > div {
      display: none;
    }

    .m-sig-modal--tab {
      padding: 0;
      margin-top: 5px;
    }
  }

  .m-sig-modal--title {
    height: 40px !important;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    padding: 25px 0 35px;
    margin-left: 30px;
    letter-spacing: 0.05em;
    & > div {
      display: none;
    }
    & > div:first-child {
      display: block;
    }
  }

  .m-sig-modal--tab--saved {
    .m-sig-modal--tab--saved--image {
      height: 110px;
      margin: 0 0 20px;
      text-align: center;

      & > img {
        width: 300px;
      }
    }

    .m-sig-modal--tab--saved--none {
      display: none;
      margin-top: 10px;

      & > h2 {
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 15px;
      }

      & > p {
        width: 90%;
        line-height: 18px;
        font-size: 14px;
      }
    }

    .m-sig-modal--carousel {
      width: 100%;
      padding: 20px 0;
      left: 50px;
      position: absolute;

      .m-sig-modal--carousel--clip {
        height: auto;
      }

      .m-sig-modal--carousel--list {
        float: left;
        width: 600px !important;
        margin: 0;
      }

      .m-sig-modal--carousel--list > li {
        float: left;
        border: none !important;
        height: 70px;
        width: 85px;
        margin-right: 20px;
        &.is-deleted {
          display: none;
        }
      }

      .m-sig-modal--carousel--list > .m-sig-modal--carousel--arrow {
        display: none;
        width: 20px;
        opacity: 0.6;
        filter: alpha(opacity=60);
        cursor: pointer;
        &:hover {
          opacity: 1;
          filter: alpha(opacity=100);
        }
      }
      .m-sig-modal--carousel--list > .m-sig-modal--carousel--prev {
        margin: 8px 20px 0 0;
        background: url('../left_arrow@2x.png') no-repeat 0 0;
      }
      .m-sig-modal--carousel--list > .m-sig-modal--carousel--next {
        margin: 8px 0 0 0;
        background: url('../right_arrow@2x.png') no-repeat 0 0;
      }
      .m-sig-modal--carousel--list.has-paging {
        & > .m-sig-modal--carousel--arrow {
          display: initial;
        }
      }
      .m-sig-modal--carousel--arrow.is-disabled,
      .m-sig-modal--carousel--arrow.is-disabled:hover {
        opacity: 0.2;
        filter: alpha(opacity=20);
      }

      .m-sig-modal--carousel--tip {
        position: absolute;
        left: 100px;
        display: none;
        margin: 3px 10px;
        & > p {
          font-weight: 400;
          font-size: 12px;
        }
      }

      .m-sig-modal--carousel--item {
        border: solid 1px $cinder-block;
        opacity: 0.5;
        filter: alpha(opacity=50);

        float: left;
        height: 42px;
        width: 85px;
        text-align: center;
        padding: 0 5px;

        &:hover {
          opacity: 1;
          filter: alpha(opacity=100);
          cursor: pointer;
        }

        &.is-active {
          border: solid 1px var(--dig-color__primary__base);
          opacity: 1;
          filter: alpha(opacity=100);

          & + .m-sig-modal--carousel--item--delete {
            display: inline;
          }
        }
      }

      .m-sig-modal--carousel--item--delete {
        display: none;
        float: right;
        font-size: 12px;
        color: var(--dig-color__primary__base);
        cursor: pointer;
        margin-right: 2px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .m-sig-modal--tab--draw {
    .m-sig-modal--tab--draw--instructions {
      font-size: 14px;
      margin-bottom: 20px;
    }

    .m-sig-modal--canvas-holder {
      height: 125px;
      clear: both;
    }

    .m-sig-modal--canvas-holder--error {
      float: right;
      margin-right: 20px;
      margin-top: 5px;
    }

    .m-sig-modal--canvas {
      z-index: 9999;
      width: 460px;
      height: 143px;
      background-image: url(~canvas-plume.png);
      background-size: 100%;
    }

    .m-sig-modal--canvas--clear {
      position: relative;
      top: 25px;
      font-size: 14px;

      & > a {
        color: $cinder-block;
        font-size: 12px;
      }
    }

    .m-sig-modal--steps--step.drawn-sig {
      & > img {
        max-width: 450px;
        max-height: 200px;
        margin-top: 20px;
      }

      .step_loading_content {
        text-align: center;
        width: 80%;
        padding-top: 80px;
      }
    }
  }

  .m-sig-modal--tab--type {
    .m-sig-modal--tab--type--instructions {
      font-size: 14px;
      margin: 0 0 20px;
    }

    .m-sig-modal--tab--type--input {
      margin-bottom: 20px;
      & > input {
        color: $cinder-block;
        border-color: $warm-chinchilla;
        border-radius: 0px;
        font-size: 14px;
        font-weight: 300;
        height: 38px;
        width: 450px;
      }
      & > input:focus {
        box-shadow: 0 0 5px $cinder-block;
      }
    }

    .m-sig-modal--tab--type--preview {
      float: left;
      width: 100%;
      margin: 20px 0 15px;
      font-size: 36px;
      text-align: left;
      word-break: break-all;
      & > div {
        font-size: 36px !important;
      }
      & > div > span {
        font-family: inherit;
        font-size: 36px !important;
      }
    }

    .m-sig-modal--tab--type--change-font {
      color: $cinder-block;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.05em;
    }

    .m-sig-modal--error {
      font-size: 12px;
      font-weight: 300;
      color: $red;
    }
  }

  .m-sig-modal--tab--mobile {
    .m-sig-modal--steps--step.upload-steps {
      & > p {
        margin-left: 0px;
        margin-bottom: 10px;
        font-size: 14px;
      }

      & > p.with-left-margin {
        margin-left: 20px;
      }

      & > p .fixed-width {
        width: 150px;
        display: inline-block;
      }

      .number {
        position: absolute;
      }

      .indent {
        margin-left: 20px;
      }

      .bold,
      .bold > a {
        font-weight: 500;
        color: black;
      }

      .m-sig-modal--tab--mobile--instructions {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 20px;
        font-weight: 300;
        width: 100%;
      }
    }
  }

  .m-sig-modal--tab--upload {
    .m-sig-modal--steps--step {
      display: none;
      text-align: center;
    }

    .m-sig-modal--upload--details {
      margin: 5px;
      & > p {
        font-size: 12px;
        font-weight: 400;
        color: $grey;
      }
    }

    .m-sig-modal--upload--progress {
      position: relative;
      top: 0;
      width: 90%;
    }

    .m-sig-modal--upload--form {
      margin: 30px 0 0 20px;
    }

    .m-button {
      font-size: 14px;
      font-weight: 400;

      .m-button--icon {
        width: 18px;
        margin-right: 8px;
        position: relative;
        top: -1px;
      }
    }
  }

  .m-sig-modal--edit-image {
    .m-sig-modal--edit-container {
      text-align: center;
    }

    .m-sig-modal--edit-image--crop {
      background: $plume;
      border: solid 1px $warm-chinchilla;
      height: 122px;
      margin: 10px 0 20px 45px;
    }

    .m-sig-modal--edit-image--slider > p,
    .m-sig-modal--edit-image--rotate > p {
      display: block;
      float: left;
      width: 100%;
      text-align: center;
      margin-top: 10px;
      color: $grey;
      text-transform: uppercase;
      font-size: 10px;
    }

    .m-sig-modal--edit-image--slider {
      float: left;
      width: 141px;
      margin-right: 30px;
      margin-left: 100px;

      & > div {
        float: left;
        height: 1px;
        border-radius: 0px;
        border-bottom: none;
        width: 140px;
        margin: 5px auto 0;
      }
      & > div > a {
        width: 16px;
        height: 16px;
        border-radius: 10px;
        margin-top: -3px;
        cursor: pointer;
      }
      & > p {
        margin-top: 20px;
      }
    }

    .m-sig-modal--edit-image--rotate {
      float: left;
      width: 140px;
      text-align: center;

      & > a > img {
        width: 20px;
        height: 15px;
        margin-bottom: 1px;
      }
    }
  }

  .m-sig-modal--edit-image-loader {
    text-align: center;
    margin-top: 30px;
    margin-left: 40px;
    width: 410px;

    img {
      width: 120px;
    }

    & > .m-sig-modal--edit-image-loader--msg {
      margin-top: 20px;
      font-size: 14px;
      letter-spacing: 0.05em;
      color: $grey;
      font-weight: 300;
    }
  }

  .m-sig-modal--steps {
    .m-sig-modal--steps--step {
    }
  }

  .m-sig-modal--actions {
    width: 100%;
    height: 60px;
    background: $plume;
    position: absolute;
    bottom: 0;

    .m-sig-modal--action {
      float: left;
      width: 100%;
      height: 60px;
      display: none;

      .m-button {
        float: right;
        padding: 8px 10px;
        min-width: 80px;
        height: 30px;
        margin: 15px 30px;
      }

      .m-sig-modal--action--btn-container {
        margin: 0 !important;
        padding: 0 !important;
      }

      .m-sig-modal--action--link {
        float: right;
        line-height: 60px;
        font-size: 12px;
        color: $cinder-block;
        margin-left: 20px;

        &:hover {
          text-decoration: none;
          color: $black;
        }
      }
    }

    .m-sig-modal--action--statement {
      float: left;
      line-height: 60px;
      margin-left: 33.5px;
      font-size: 12px;
      color: $cinder-block;
      font-weight: 300;
      letter-spacing: 0.05em;
    }
  }

  .m-sig-modal--error {
    margin-top: 25px;
  }
}

/* -----  FANCYBOX OVERRIDES  ------------------- */

.fancybox-button:hover {
  background-color: transparent;
}

.fancybox-is-open .fancybox-bg {
  opacity: 0.3 !important;
}

.fancybox-wrapper-v3 {
  float: inherit;

  .fancybox-content {
    padding: unset;
  }
}

.fancybox-content {
  float: inherit;
}

.m-fancybox-modal-overlay {
  &.is-hidden {
    display: none !important;
  }

  &.signature-modal,
  &.settings-modal {
    background: $castle-rock !important;
    opacity: 0.7 !important;
    filter: alpha(opacity=70) !important;
  }
}

.m-fancybox-modal-wrap {
  &.is-hidden {
    display: none !important;
  }

  &.signature-modal {
    width: 793px !important;
    height: 425px !important;
  }

  &.settings-modal {
    width: 593px !important;
    height: 353px !important;
    &.delete-account {
      width: 643px !important;
      height: 451px !important;
    }
    &.delete-account.must-cancel {
      height: 283px !important;
    }
  }

  &.share-modal {
    width: 593px !important;
    height: 353px !important;
  }

  &.standard-modal {
    width: 590px !important;
    height: 350px !important;
  }

  &.team-edit-name {
    width: 443px !important;
    height: 248px !important;
  }

  &.api-app-details {
    width: 628px !important;
    height: 540px !important;
  }

  &.contact-import-completed {
    width: 603px !important;
    height: 649px !important;
  }

  &.prepare-docs {
    width: 1220px !important;
  }

  &.before-send {
    width: 563px !important;
    height: 481px !important;
  }

  &.enable-oss {
    width: 620px !important;
    height: 653px !important;
  }

  &.fax-purchase-conf {
    width: 460px !important;
    height: 250px !important;
  }

  &.view-template-perms-for-template {
    width: 530px !important;
    height: 437px !important;
  }

  &.view-template-perms-for-acct {
    width: 535px !important;
    height: 343px !important;
  }

  &.ga-team-invite {
    width: 475px !important;
    height: 285px !important;
  }

  &.invite-friends {
    width: 633px !important;
    height: 453px !important;
  }

  &.template-reuse-warning {
    width: 410px !important;
    height: 270px !important;
  }

  &.new-enterprise-seat,
  &.remove-enterprise-seat,
  &.invite-existing-account {
    width: 510px !important;
    height: 255px !important;
  }

  &.remove-enterprise-member {
    width: 510px !important;
    height: 305px !important;
    padding: 0 !important;
  }

  &.learn-more-highres {
    width: 507px !important;
    height: 400px !important;
  }

  &.update-settings-modal {
    height: 490px !important;
  }

  &.update-fax-settings-modal {
    height: 400px !important;
  }
}

.m-fancybox-modal-outer {
  border: none !important;
}

.m-fancybox-modal-inner {
  &.is-hidden {
    display: none !important;
  }

  &.signature-modal {
    width: 750px !important;
    height: 385px !important;
  }

  &.update-settings-modal {
    height: 450px !important;
    width: 520px !important;
  }

  &.update-fax-settings-modal {
    height: 360px !important;
    width: 520px !important;
  }

  &.settings-modal {
    width: 550px !important;
    height: 310px !important;

    &.delete-account {
      width: 600px !important;
      height: 410px !important;
    }

    &.delete-account.must-cancel {
      height: 240px !important;
    }
  }

  &.share-modal {
    width: 550px !important;
    height: 310px !important;
  }

  &.standard-modal {
    width: 550px !important;
    height: 310px !important;
  }

  &.team-edit-name {
    width: 400px !important;
    height: 205px !important;
  }

  &.api-app-details {
    width: 585px !important;
    height: 497px !important;
  }

  &.contact-import-completed {
    width: 560px !important;
    height: 607px !important;
  }

  &.prepare-docs {
    height: 100% !important;
  }

  &.before-send {
    width: 523px !important;
    height: 438px !important;
  }

  &.enable-oss {
    width: 580px !important;
    height: 613px !important;
  }

  &.fax-purchase-conf {
    width: 415px !important;
    height: 195px !important;
  }

  &.view-template-perms-for-template {
    width: 490px !important;
    height: 398px !important;
  }

  &.view-template-perms-for-acct {
    width: 495px !important;
    height: 300px !important;
  }

  &.ga-team-invite {
    width: 415px !important;
    height: 225px !important;
  }

  &.invite-friends {
    width: 593px !important;
    height: 413px !important;
  }

  &.template-reuse-warning {
    width: 370px !important;
    height: 230px !important;
    .l-new-modal--close {
      display: none;
    }
  }

  &.new-enterprise-seat,
  &.remove-enterprise-seat,
  &.invite-existing-account {
    top: 0 !important;
    left: 0 !important;
    width: 470px !important;
    height: 215px !important;
    .l-new-modal--content > p {
      float: left;
      padding: 0 20px;
      font-size: 14px;
      letter-spacing: 0.05em;
      width: 100%;
      margin-bottom: 5px;
    }
  }

  &.remove-enterprise-member {
    top: 0 !important;
    left: 0 !important;
    width: 510px !important;
    height: 305px !important;
  }
}

/* -----  EDITOR-SPECIFIC OVERRIDES  ------------ */

.l-editor {
  .m-sig-modal--container {
    position: absolute;
    top: 15%;
    z-index: 9999;
    width: 100%;
  }

  .m-sig-modal--wrapper {
    width: 750px;
    margin: 0 auto;
  }

  .m-sig-modal--area {
    background: $white;
  }

  .m-sig-modal--right-container {
    width: 575px;
  }

  .m-sig-modal--right-wrapper {
    height: 385px;

    .m-sig-modal--action {
      margin-top: 0;
    }
  }

  .m-sig-modal--tab--draw {
    .m-sig-modal--canvas {
      left: initial;
      width: 459px;
    }
  }

  &.sign-layout {
    .m-sig-modal--canvas--img {
      left: 314px;
      width: 459px;
      height: 143px;
    }
  }

  .signing-arrow {
    height: 50px;
    display: block;
    visibility: hidden;
    & > a {
      left: -15px;
      top: -1px;
    }
  }

  .sign_instructions > p {
    font-weight: 300;
    font-size: 14px;
    color: $white;
  }

  #progress-actions > a {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.05em;
  }

  #progress-text {
    font-weight: 300;
    font-size: 12px;
    margin-top: 5px;
    letter-spacing: 0.05em;
  }

  #document_name {
    color: $white;
  }

  #sign_footer .footer_content > p {
    color: $white;
    letter-spacing: 0.05em;
    font-weight: 300;
    font-size: 13px;

    &.footer_de_DE {
      font-size: 12px;
      width: 675px;
      margin-top: 0px;
    }
  }

  #global_error {
    font-size: 14px;
    font-weight: 300;
    padding-top: 10px;
    color: $red;
    top: -55px;
  }

  #saveButton {
    float: right;
    margin: 0;
    position: relative;
    top: -3px;
  }

  &.choose-signer-page {
    .form_type_response_sig,
    .form_type_response_sig .outer_doc_container {
      border: none;
      background: $white !important;
    }

    .instruction_bar_container .instructions {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.05em;

      & > span {
        font-weight: 400;
        color: $light-grey;
      }
    }

    .control_bar_container {
      border: none;
    }

    #inner_doc_container {
      margin-left: 0 !important;
      min-height: inherit !important;

      .instructions_plate {
        float: left;
        width: 100%;
        background: none;
        margin-left: 0;
        padding-left: 0;
      }

      .signer_choice {
        float: left;
        width: 100%;
        margin: 5px 0;

        .signer_choice_radio {
          margin: 0 0 0 1px !important;
          position: relative;
          top: -2px;
        }

        & > label {
          font-size: 16px;
          letter-spacing: 0.05em;
        }
      }
    }

    #continueButton {
      float: left;

      & > button {
        margin-left: 0;
      }
    }
  }
}

/* -----  IE FIXES  ------------ */

.ie10 {
  .uploader_container .upload_files_button {
    & > input[type='file'] {
      height: 55px !important;
    }
  }
}

.ie9 {
  .uploader_container .upload_files_button {
    & > .m-button {
      margin-top: 21px !important;
    }

    & > input[type='file'] {
      height: 55px !important;
    }
  }
}

/* -----  2021 RESPONSIVE LAYOUT and REBRAND ------------ */

#share_document {
  background-color: $dropbox-product-blue;
}
