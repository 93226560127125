.m-about-us {
  .m-about-us--heading {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 800;
    padding-bottom: 5px;
    @media #{$medium-only} {
      text-align: center;
    }
    @media #{$small-only} {
      text-align: center;
    }
  }
  .m-about-us--steps {
    max-width: none;
  }
  .m-about-us--text {
    @extend h3;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 200;
  }
  #pressKitButton {
    position: relative;
    top: -8px;
    float: right;
  }
  #pressKitButton > div {
    text-transform: none;
  }
  .m-about-us--news-item-list {
    margin-left: 0px;
  }
  .m-about-us--news-item {
    font-size: 20px;
    font-weight: 300;
    border-bottom: 1px solid $warm-chinchilla;
    list-style: none;
    padding-top: 25px;
    padding-bottom: 25px;
    @media #{$large-up} {
      margin-right: 40px;
    }
    @media #{$medium-only} {
      text-align: center;
    }
    @media #{$small-only} {
      text-align: center;
    }
  }
  .m-about-us--news-item:last-child {
    border-bottom: 0px;
  }
  a.m-about-us--news-link:link,
  a.m-about-us--news-link:visited {
    color: $ashen;
  }
  a.m-about-us--news-link:active,
  a.m-about-us--news-link:hover {
    color: var(--dig-color__primary__base);
  }
  .m-about-us--news-link > img {
    display: block;
    margin-bottom: 15px;
    @media #{$medium-only} {
      margin-left: auto;
      margin-right: auto;
    }
    @media #{$small-only} {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .m-about-us--news-item-date {
    font-size: 12px;
  }
  .m-about-us--investor-img {
    width: 214px;
    height: 284px;
  }
  .m-about-us--investor-head {
    border: 8px solid $white;
    box-shadow: 0px 0px 10px 0px $ashen;
    max-width: none;
  }
  .m-about-us--investor-company-img {
    display: none;
    background: rgba($white, 0.8);
    height: 55px;
  }
  .m-about-us--divider {
    border-top: 1px solid $warm-chinchilla;
  }
  .m-about-us--investor-img {
    border: 8px solid $white;
    box-shadow: 0px 0px 10px 0px rgba($black, 0.3);
    max-width: none;
    width: 214px;
    height: 284px;
    background-size: 214px 284px;
    backgroun-repeat: none;
  }
  .m-about-us--investor-reid {
    background-image: url('../reid_hoffman@2x.png');
  }
  .m-about-us--investor-reid:hover {
    background-image: url('../reid_hoffman_color@2x.png');
  }
  .m-about-us--investor-jacques {
    background-image: url('../jacques_benkoski@2x.png');
  }
  .m-about-us--investor-jacques:hover {
    background-image: url('../jacques_benkoski_color@2x.png');
  }
  .m-about-us--investor-dave {
    background-image: url('../dave_munichiello@2x.png');
  }
  .m-about-us--investor-dave:hover {
    background-image: url('../dave_munichiello_color@2x.png');
  }
  .m-about-us--investor-sam {
    background-image: url('../sam_altman@2x.png');
  }
  .m-about-us--investor-sam:hover {
    background-image: url('../sam_altman_color@2x.png');
  }
  .m-about-us--investor-img > .m-about-us--investor-company-img {
    display: none;
    position: relative;
    bottom: -215px;
  }
  .m-about-us--investor-img:hover > .m-about-us--investor-company-img {
    display: block;
  }
  .m-about-us--investor-img > .m-about-us--investor-company-img > img {
    position: relative;
    top: 5px;
  }
  .m-about-us--investor-name {
    text-transform: uppercase;
    padding-top: 10px;
  }
  .m-about-us--investor-title {
    font-style: italic;
    padding-bottom: 10px;
  }
}
