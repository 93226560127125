.m-cta-box {
  &.grey {
    background-color: $plume;
  }

  .m-cta-box--image-text {
    font-weight: bold;
  }
  .m-cta-box--horizontal-center {
    @media #{$small-only}, #{$medium-only} {
      display: inline-block;
      width: 320px;
    }
  }

  .m-cta-box--bottom-border {
    border-bottom: 1px solid $warm-chinchilla;
    position: absolute;
    bottom: 0;
    right: 10%;
    left: 10%;
  }
  .m-cta-box--image {
    float: left;
  }
  .m-cta-box--header {
    text-transform: uppercase;
    text-align: left;
  }
  .m-cta-box--text {
    padding-bottom: 40px;
    text-align: left;
  }
  .m-cta-box--button-outer-container {
    text-align: left;
  }

  .m-cta-box--button-inner-container {
    display: inline-block;
  }

  .m-cta-box--image-text-container {
    padding-left: 20px;
    color: $ashen;
  }
}
