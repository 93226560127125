.m-update-account-modal {
  & .row {
    margin-top: 8px;
  }

  &--recipient-picker {
    &-item {
      cursor: pointer;

      width: 120px;
      height: 120px;
      display: block;
      padding: 0px;
      text-align: center;
      position: relative;
      margin: 0px;

      & input {
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 999;
        top: 0px;
        left: 0px;
        &:checked + label {
          color: $azure;
          border: 2px solid $azure;

          &.me {
            background-image: url(../me_blue_@2x.png);
          }
          &.others {
            background-image: url(../others_blue_@2x.png);
          }
        }

        position: absolute;
      }

      & label {
        z-index: 999;
        margin: 0px !important;
        line-height: 190px;
        font-size: 14px;
        font-weight: 400;
        color: $cinder-block;
        border: 2px solid $cinder-block;
        border-radius: 3px 3px 3px 3px;

        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        position: absolute;
        background-repeat: no-repeat;
        &.me {
          background-position: 30px 15px;
          background-image: url(../me_grey_@2x.png);
        }
        &.others {
          background-position: 5px 15px;
          background-image: url(../others_grey_@2x.png);
        }
      }
    }
  }

  &--fax-pdf-attachment {
    &-item {
      & label {
        font-size: 14px;
        font-weight: 400;
      }
    }

    &-note {
      margin-top: 10px !important;
      color: $grey;
    }
  }
}
