.m-marketing-page {
  padding-bottom: 100px;

  h1,
  h2,
  h3,
  h4,
  div,
  p,
  ul,
  li,
  span,
  a {
    font-weight: normal;
  }

  p,
  ul,
  ol {
    margin-top: 15px;
  }

  .m-marketing-page--title {
    @media #{$medium-only} {
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .m-marketing-page--subtitle {
    @media #{$medium-only} {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .m-marketing-page--header {
    margin: 60px auto 10px auto;
    @media #{$small-only} {
      font-size: 20px;
      padding-top: 30px;
      padding-bottom: 0;
    }
  }

  .m-marketing-page--list {
    margin-left: 58px;

    @media #{$small-only} {
      margin-bottom: 0;
      margin-left: 38px;
    }
  }

  ul.m-marketing-page--list li:before {
    content: '\2022'; // round bullet
    color: var(--dig-color__primary__base);
    font-size: 20px;
    padding-right: 10px;
  }

  .m-marketing-page--list li {
    font-size: 15px;
    text-indent: -1.2em;
    line-height: 1.5em;
    letter-spacing: 0.75px;
    margin: 10px 0;

    @media #{$small-only} {
      margin: 5px 0;
    }
  }

  .m-marketing-page--list li:last-child {
    @media #{$small-only} {
      margin-bottom: 10px;
    }
  }

  .m-marketing-page--image-signature-modal {
    max-width: 700px;

    @media #{$medium-only} {
      max-width: 600px;
    }

    @media #{$small-only} {
      max-width: 100%;
      margin-top: -40px;
    }
  }

  @media #{$small-only} {
    padding-bottom: 40px;
  }
}

.m-marketing-page--button {
  height: 39px;
  line-height: 16px;
}

.m-marketing-page--try-it-free {
  @media #{$small-only} {
    padding-top: 0;
  }
}

.m-marketing-page--subheader {
  font-size: 18px;
  margin: auto;

  @media #{$small-only} {
    font-size: 15px;
    margin-bottom: 10px;
  }
}

.m-marketing-page--button-inline {
  display: inline-block;
  margin: 0 8px;
  padding-bottom: 30px;

  a {
    white-space: nowrap;
  }

  @media #{$small-up} {
    padding-bottom: 0;
  }
}

.m-marketing-page--embedded-tweets {
  padding-bottom: 120px;

  @media #{$small-only} {
    padding-bottom: 50px;
  }
}

.m-marketing-page--embedded-tweet {
  min-width: 500px;
  display: inline-block;

  @media #{$medium-only} {
    min-width: 45%;
  }

  @media #{$small-only} {
    margin: 0 !important;
    min-width: 95% !important;
  }
}

.m-marketing-page--embedded-tweet:nth-child(1),
.m-marketing-page--embedded-tweet:nth-child(2) {
  vertical-align: top;
  margin: 0 3px;
}

.m-marketing-page--embedded-tweet:nth-child(3),
.m-marketing-page--embedded-tweet:nth-child(4) {
  vertical-align: top;
  margin: 0 3px;
}

.m-marketing-page--embedded-tweet iframe {
  @media #{$small-only} {
    margin-left: auto;
    margin-right: auto;
  }
}

.m-marketing-page--get-started {
  padding-top: 60px;

  @media #{$small-only} {
    padding-top: 0;
  }
}

.m-marketing-page--subheader-small {
  padding-top: 70px;
  padding-bottom: 10px;

  @media #{$small-only} {
    padding-top: 40px;
    padding-bottom: 0;
  }
}

// google dropdown menu
.m-marketing-page--google-dropdown-menu-logged-in {
  right: 78px !important;
}

.m-marketing-page--google-dropdown-menu-logged-out {
  right: 287px !important;
}

.m-marketing-page--google-dropdown-menu-logged-in a,
.m-marketing-page--google-dropdown-menu-logged-out a {
  display: inline;
}
