.m-sign-mobile {
  .m-sign-mobile--send {
    position: relative;
    top: -5px;
  }

  .m-sign-mobile--send.is-active {
    @media #{$medium-up} {
      width: 420px;
    }
  }

  .m-input-text {
    float: left;
    width: 260px;
    display: inline;
    margin-right: 10px;
    top: 5px;
    position: relative;
  }

  .m-sign-mobile--android-btn {
    top: -2px;
    position: relative;
  }

  .m-sign-mobile--devices-img {
    position: relative;
    left: 30px;
  }

  .m-sign-mobile--text-field {
    display: inline-block;
    float: left;
  }

  h5.m-feature-grid--header {
    font-weight: 800;
  }

  h5.m-feature-grid--header,
  h5.m-feature-grid--sub-header {
    @extend p;
  }
}
