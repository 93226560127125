.m-home {
  .m-home--top-panel {
  }
}

.m-home-api {
  float: left;
  width: 100%;
  text-align: center;
  background: $black;
  color: $white;
  height: 75px;

  .m-home-api--inner {
    height: 45px;
    margin: 15px auto;
  }

  .m-home-api--icon {
    margin-right: 20px;
    width: 45px;
    display: inline-block;

    & > img {
      position: relative;
      top: -2px;
      width: 45px;
    }
  }

  .m-home-api--text {
    line-height: 45px;
    letter-spacing: 0.05em;
    font-size: 18px;
    font-weight: 200;
  }

  .m-home-api--cta {
    display: inline-block;
    margin-left: 40px;
    height: 32px;

    & > div {
      display: inline-block;
    }

    .m-button {
      margin: 0;
      padding: 9px 20px 7px 20px;
    }
  }
}

.m-home-ribbon {
  .m-home-ribbon--image {
    position: absolute;

    img {
      position: relative;
      width: 285px;
      left: -30px;
      z-index: 1;

      @media #{$small-only} {
        top: 560px;
      }

      @media #{$medium-only} {
        top: -100px;
      }

      @media #{$large-up} {
        top: -45px;
      }
    }
  }

  .m-home-ribbon--text {
    display: none;
  }
}
