.m-sf-landing {
  color: $ashen;

  * {
    letter-spacing: 0.05em;
    font-size: 14px;
    font-weight: 300;
  }

  input[type='text'] {
    border-radius: 0;
    background: $white;
    box-shadow: none;
  }

  .m-button {
    font-size: 12px;
    font-weight: 500;
    padding: 10px 20px;
  }

  .mobile-only {
    display: none;
  }

  .m-sf-landing--header {
    float: left;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 40px;

    @include linear-gradient(#e2f0e3, 'white');

    .m-sf-landing--header--inner {
      width: 900px;
      margin: 0 auto;
    }

    .m-sf-landing--header--logo {
      float: left;
      width: 200px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .m-sf-landing--header--cta {
      float: right;

      .m-button {
        margin: 0;
      }
    }

    .m-sf-landing--header--content {
      float: left;
      width: 100%;
      text-align: center;
      margin-top: 40px;

      .m-sf-landing--header--title {
        font-size: 36px;
        font-weight: 300;
        margin-bottom: 10px;
      }

      .m-sf-landing--header--cta {
        & > a > button {
          padding: 10px 20px;
          line-height: 15px;
          font-weight: 500;
        }
      }
    }

    .m-sf-landing--header--animation {
      opacity: 0.3;
      filter: alpha(opacity=30);
      float: left;
    }

    &.is-playing {
      background: $white;

      .m-sf-landing--header--animation {
        opacity: 1;
        filter: alpha(opacity=100);
        float: left;
      }
    }
  }

  .m-sf-landing--modal {
    display: none;
    position: fixed;
    width: 550px;
    top: 50%;
    left: 50%;
    background: $white;
    border: solid 2px $leprechaun;
    border-radius: 3px;
    padding: 30px 45px;
    @include transform(translate(-50%, -50%));
    z-index: 9999;

    &.is-fixed {
      position: absolute;
      margin: 0;
      top: 234px;
      @include transform(translate(-50%, 0));
    }

    .m-sf-landing--modal--title {
      float: left;
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: 300;
      color: $ashen;
      margin-bottom: 20px;
    }

    .m-sf-landing--modal--text {
      text-align: center;
      line-height: 20px;
      padding: 20px;
    }

    .m-sf-landing--modal--form {
      float: left;
      width: 100%;

      & > input {
        float: left;
        width: 300px;

        &.is-invalid {
          border-color: $red;
        }
      }

      & > button {
        margin: 0 0 0 10px;
      }
    }

    &.is-visible {
      display: inline;
    }
  }

  .m-sf-landing--content {
    float: left;
    width: 100%;
    margin-top: 100px;

    .m-sf-landing--content--inner {
      width: 900px;
      margin: 40px auto;
    }

    .m-sf-landing--content--header {
      float: left;
      width: 100%;
      text-align: center;
      color: $leprechaun;
      font-size: 16px;
      font-weight: 800;
      letter-spacing: 0.13em;
      text-transform: uppercase;
      margin-bottom: 40px;

      &:after {
        content: '.';
        display: block;
        color: $white;
        border-bottom: solid 2px $leprechaun;
        width: 200px;
        margin: -5px calc((100% - 200px) / 2) 0;
      }

      &.is-wide {
        &:after {
          width: 300px;
          margin: -5px calc((100% - 300px) / 2) 0;
        }
      }
    }

    .m-sf-landing--content--section {
      float: left;
      width: 100%;
      margin-bottom: 100px;

      .m-sf-landing--content--section--image {
        border-bottom: solid 1px $cinder-block;

        .m-sf-landing--content--section--image--wrapper {
          width: 395px;
          margin: 0 auto;
          overflow-x: hidden;

          &.is-special {
            width: 364px;
            position: relative;
            left: -35px;
          }
        }

        text-align: center;
        width: 50%;

        & > video {
          width: 400px;
        }

        .small {
          position: relative;
          left: -35px;
        }
      }

      .m-sf-landing--content--section--text {
        text-align: left;
        margin-top: 50px;
        width: 50%;

        .m-sf-landing--content--section--text--title {
          color: $leprechaun;
          font-weight: 500;
          font-size: 24px;
          margin-bottom: 7px;
          line-height: 28px;
          letter-spacing: 0.02em;
        }

        .m-sf-landing--content--section--text--paragraph {
          font-size: 16px;
          line-height: 22px;
          font-weight: 400;
        }
      }

      &.is-right-aligned {
        .m-sf-landing--content--section--image {
          float: left;
        }
        .m-sf-landing--content--section--text {
          float: right;
          padding-right: 5%;
        }
      }

      &.is-left-aligned {
        .m-sf-landing--content--section--image {
          float: right;
        }
        .m-sf-landing--content--section--text {
          float: left;
          padding-left: 5%;
        }
      }
    }

    .m-sf-landing--content--features {
      float: left;
      margin-top: 135px;
      margin-bottom: 110px;
    }

    .m-feature-grid--list-item-group {
      float: left;
      width: 100%;
      margin: 40px 0;

      .m-feature-grid--list-item {
        padding: 0 20px;

        .m-feature-grid--item-image-wrapper {
          height: 60px;
        }

        .m-feature-grid--header {
          color: $leprechaun;
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
        }

        .m-feature-grid--text {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  .m-sf-landing--footer {
    float: left;
    width: 100%;
    text-align: center;

    @include linear-gradient('white', #e2f0e3);

    .m-sf-landing--footer--banner {
      background-color: $leprechaun;
      color: $white;
      text-transform: uppercase;
      padding: 60px 0;

      .m-sf-landing--footer--banner--small-text {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.07em;
        margin-bottom: 30px;
      }

      .m-sf-landing--footer--banner--big-text {
        font-size: 26px;
        font-weight: 500;
        letter-spacing: 0.07em;

        &:after {
          content: '.';
          display: block;
          color: $leprechaun;
          border-bottom: solid 2px $white;
          width: 220px;
          margin: -5px calc((100% - 220px) / 2) 0;
        }
      }
    }

    .m-sf-landing--footer--form {
      float: left;
      width: 100%;
      margin: 110px 0;

      .m-sf-landing--footer--form--text {
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 50px;
        padding: 0 20px;
      }

      .m-sf-landing--footer--form--content {
        width: 415px;
        margin: 0 auto;

        & > input {
          float: left;
          width: 300px;
          height: 50px;
          font-size: 16px;

          &.is-invalid {
            border-color: $red;
          }
        }

        & > button {
          float: left;
          margin: 0 0 0 10px;
          height: 50px;
          font-size: 14px;
        }
      }
    }

    .m-sf-landing--footer--links {
      float: left;
      width: 100%;
      background: $white;

      .m-sf-landing--footer--links--inner {
        width: 900px;
        height: 50px;
        margin: 0 auto;
        padding: 10px 20px;
      }

      .m-sf-landing--footer--link {
        float: left;
        margin-right: 20px;
        color: $ashen;
        font-weight: 400;
        line-height: 30px;

        & > a {
          color: $ashen;
          font-weight: 400;
        }

        &.right-aligned {
          float: right;
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .m-sf-landing {
    width: 100vw;

    .desktop-only {
      display: none;
    }

    .mobile-only {
      display: inherit;
    }

    .m-sf-landing--header {
      width: 100vw;
      padding-top: 20px;

      .m-sf-landing--header--inner {
        width: 100vw;
      }

      .m-sf-landing--header--logo {
        margin-bottom: 20px;
        margin-left: calc((100% - 200px) / 2);
        margin-top: 20px;
      }

      .m-sf-landing--header--cta {
        float: none;
        margin-top: 20px;
        margin-left: calc((100% - 146px) / 2);
      }

      .m-sf-landing--header--animation {
        @media (min-width: 400px) {
          margin: 0 calc((100% - 400px) / 2);
        }
      }

      .m-sf-landing--header--content {
        padding: 0 20px;

        .m-sf-landing--header--title {
          font-size: 32px;
        }

        .m-sf-landing--header--subtitle {
          font-size: 14px;
        }
      }
    }

    .m-sf-landing--modal {
      float: left;
      width: 100vw;
      border-left: 0;
      border-right: 0;
      @include transform(none);
      margin: 0 !important;
      border-radius: 0;
      width: 100%;
      left: 0;

      &.is-fixed {
        @include transform(none);
        top: 300px;
      }

      .m-sf-landing--modal--form {
        & > input,
        & > button {
          float: left;
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    .m-sf-landing--content {
      width: 100vw;

      .m-sf-landing--content--inner {
        width: 100vw;
      }

      .m-sf-landing--content--section {
        .m-sf-landing--content--section--image {
          width: 100%;
          padding: 0 20px;
          margin-bottom: 20px;

          .m-sf-landing--content--section--image--wrapper {
            width: calc(100vw - 40px);

            &.is-special {
              width: 100vw;
              position: relative;
              left: -65px !important;
            }

            & > img {
              position: relative;
              left: 1px;
            }
          }
        }

        .m-sf-landing--content--section--text {
          width: 100%;
          padding: 0 20px !important;
          margin: 20px 0;
          text-align: center;
        }
      }

      .m-feature-grid--list-item-group {
        .m-feature-grid--list-item {
          margin-bottom: 40px !important;
        }
      }
    }

    .m-sf-landing--footer {
      .m-sf-landing--footer--links {
        width: 100vw;

        .m-sf-landing--footer--links--inner {
          width: 100vw;
        }
      }

      .m-sf-landing--footer--form {
        margin: 90px 0;

        .m-sf-landing--footer--form--content {
          width: 100%;
          padding: 0 20px;

          & > input,
          & > button {
            width: 100%;
            margin-right: 0;
            margin-left: 0;
          }
        }
      }
    }
  }
}
