.m-left-menu {
  @media #{$medium-up} {
    .m-button {
      background-color: $white;
      color: $ashen;
    }
    .m-button.is-active {
      color: var(--dig-color__primary__base);
    }
    .m-left-menu-item {
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e6e6e6;
      padding-left: 5px;
      font-weight: bold;
      text-align: left;
    }
    .m-left-menu-last-item {
      border-bottom: 0px;
    }
  }
  @media #{$small-only} {
    padding-bottom: 20px;
    text-align: center !important;
  }
}
