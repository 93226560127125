.m-login-or-signup {
  &.use-gradient {
    background: $royal-peacock; /* fallback color if gradients are not supported */
    background-image: -moz-linear-gradient(
      top,
      $royal-peacock 0%,
      $velvet-evening 100%
    ); /* For old Fx (3.6 to 15) */
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, $royal-peacock),
      color-stop(100%, $velvet-evening)
    ); /* Chrome,Safari4+ */
    background-image: -webkit-linear-gradient(
      top,
      $royal-peacock 0%,
      $velvet-evening 100%
    ); /* For Chrome10+ and Safari5.1+ */
    background-image: -o-linear-gradient(
      top,
      $royal-peacock 0%,
      $velvet-evening 100%
    ); /* For old Opera (11.1 to 12.0) */
    background-image: -ms-linear-gradient(
      top,
      $royal-peacock 0%,
      $velvet-evening 100%
    ); /* For pre-releases of IE 10*/
    background-image: linear-gradient(
      180deg,
      $royal-peacock 0%,
      $velvet-evening 100%
    ); /* Standard syntax; must be last */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{ $royal-peacock }, endColorstr=#{ $velvet-evening }, GradientType=0); /* IE 6-9 */
  }

  .m-login-or-signup--left-adjust {
    @media #{$large-up} {
      position: relative;
      left: 40px;
    }
  }
  .m-login-or-signup--left-button-container {
    @media #{$large-up} {
      text-align: right;
    }
  }
  .m-login-or-signup--right-button-container {
    @media #{$small-only}, #{$medium-only} {
      max-width: 270px;
      clear: both;
    }
  }
  .m-login-or-signup--left-button {
    display: inline-block;
  }
  .m-login-or-signup--or-graphic-container {
    padding: 6px 0;
    min-height: 45px;
    margin-bottom: 10px;
    height: 32px;
    background-image: url('../or@2x.png');
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-position: center;
  }

  .m-login-or-signup--sign-up-button {
    @media #{$small-only}, #{$medium-only} {
      width: 100%;
    }

    @media #{$large-up} {
      position: absolute; /* unclear why this is needed to make the button line up vertically in Firefox */
      top: 0;
      left: 277px;
    }

    margin: 0;
    padding: 14px 20px;
  }
  .m-login-or-signup--text-field {
    display: inline-block;
    @media #{$large-up} {
      width: 250px;
    }
    @media #{$medium-only}, #{$small-only} {
      margin-bottom: 25px;
    }
    width: 100%;
    margin-right: 15px;
  }

  .m-login-or-signup--opt-in-label {
    color: $warm-chinchilla;
    padding-top: 5px;
  }

  .m-login-or-signup--keep-together {
    @media #{$large-up} {
      white-space: nowrap;
    }
  }
  .m-input-text {
    margin: 0px;
    height: 43px;
  }

  .m-google-signin {
    position: relative;
    left: 0;
  }
  .m-login-or-signup--takeATourButton {
    @media #{$large-up} {
      position: relative;
      left: -50px;
    }
  }
}
