.m-twitter {
  border-top: 1px solid $warm-chinchilla;
  padding-left: 0px;
  padding-right: 0px;
  .m-twitter--quote-container {
    width: 100%;
  }

  .m-twitter--icon {
    margin-right: 10px;
    display: inline-block;
  }

  .m-twitter--row {
    height: 100px;
  }

  .m-twitter--quote {
    margin-left: 10px;
  }

  .m-twitter--inner-quote-wrapper {
    display: inline-block;
    padding-right: 0px;
  }
}
