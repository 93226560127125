/**
 * This is a port over of a section of the 'main-hs-hf-v2.css' file.  The
 * original home page was loading this stylesheet as well as 'hellosign.css.'
 * The style of the page depended on certain style rules within the
 * 'main-hs-hf-v2.css' so I've ripped out those styles and placed them here to
 * keep style parity with the original page.  This will let us have a true
 * single entry point for the style of this page.  This is a temporary
 * step towards refactoring the style of this page.
 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

p.center,
h1.center,
h2.center,
h3.center,
h4.center,
h5.center,
h6.center,
div.center {
  text-align: center;
}

body {
  font-weight: 200;
  display: block;
  line-height: 1.34;
  margin: 0px;
  padding: 0px;
}

html,
body {
  height: 100%;
  /* min-width: 1000px; */
}

h1.headline {
  min-height: 39px;
  margin: 0px 0px 45px 0px;
  font-weight: 300;
  font-size: 30px;
  width: 100%;
  line-height: 80%;
}

.embedded-requesting #send h1,
h1.headline {
  margin: 0 0 25px 0;
  padding: 2px 0 5px 0;
}

.headline {
  margin-bottom: 20px;
}

/**
 * Fancybox
 */

@-moz-document url-prefix() {
  iframe#fancybox-frame {
    overflow: hidden;
  }
}

#fancybox-close {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 15px;
  right: 15px;
  background-image: url(../../../../public/images/v2/common/x@2x.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
  opacity: 0.8;
}

#fancybox-close:hover {
  opacity: 1;
}

.fancybox-popup-page {
  padding: 20px;
}

.fancybox-popup-page .close-link {
  margin-left: 70px;
  float: left;
  margin-top: 5px;
  display: inline-block;
}

html.iframe body.fancybox-popup-page {
  width: auto;
}

html.iframe body.fancybox-popup-page .activated {
  height: 500px;
}

.notification-fancybox {
  display: none;
}

.notification-inner-fancybox {
  position: relative;
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
}

.notification-inner-fancybox a.secondary {
  display: inline-block;
  top: 12px;
  position: relative;
  font-size: 12px;
  margin-left: 15px;
}

.notification-inner-fancybox img#paper {
  display: block;
  margin: 20px auto;
}

.notification-inner-fancybox.simple {
  padding: 20px;
}

.notification-inner-fancybox.no-fax-team-plan,
.notification-inner-fancybox.ga-team-setup {
  width: 400px;
  height: 188px;
}

.notification-inner-fancybox.ga-team-setup .actions-continue {
  text-align: right;
  margin-top: 20px;
  float: left;
  width: 100%;
}

.notification-inner-fancybox.ga-team-setup h1,
.notification-inner-fancybox.ga-team-setup div {
  text-align: left;
}
.notification-inner-fancybox.ga-team-setup p {
  margin-bottom: 15px;
  text-align: left !important;
  font-size: 14px !important;
  font-weight: 300 !important;
}
.notification-inner-fancybox.ga-team-setup a {
  font-size: 14px;
  margin-left: 10px;
}

.fancybox_close {
  display: none;
}

#fancybox-outer {
  border-style: none;
}

.notification-inner-fancybox h1 {
  min-height: 39px;
  width: 100%;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
}

.notification-inner-fancybox p,
.notification-inner-fancybox ul {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.4;
}

.notification-inner-fancybox .m-button {
  float: right;
  margin-top: 15px;
  margin-right: 0;
}

.notification-inner-fancybox ul {
  margin-left: 17px;
  list-style-type: disc;
}

.notification-inner-fancybox p.fb-subhead {
  font-size: 16px;
  width: 100%;
  font-weight: var(--type__body__standard--fontweight_strong);
}

.notification-inner-fancybox h3 {
  margin-top: 20px;
  font-weight: var(--type__body__standard--fontweight_strong);
}

.notification-inner-fancybox h3.fb-subhead {
  padding-top: 30px;
  margin-top: 10px;
  border-top: 1px dashed #e6e6e6;
}

.notification-inner-fancybox a.blue-sub {
  margin-top: 15px;
  display: inline-block;
}
.notification-inner-fancybox .inset {
  margin-left: 7px;
}
.notification-inner-fancybox .simple {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.05em;
}

.notification-inner-fancybox#user_upgrade a {
  display: block;
  float: left;
}

.notification-inner-fancybox#user_upgrade div#new_user_upgrade_reasons {
  float: left;
  margin-left: 20px;
  width: 315px;
  font-size: 10px;
}

.notification-inner-fancybox#user_upgrade div#new_user_upgrade_reasons h5 {
  font-size: 12px;
  /* font-style: italic; */
  margin: 10px 0px 5px 0px;
}

.notification-inner-fancybox#user_upgrade li {
  margin-left: 20px;
  line-height: 18px;
  font-size: 12px;
  font-weight: var(--type__body__standard--fontweight_strong);
}
.notification-inner-fancybox#user_upgrade
  div#new_user_upgrade_reasons
  div.contact-us {
  border-top: 2px solid #f2f2f2;
  margin-top: 23px;
  padding-top: 10px;
}
.notification-inner-fancybox#user_upgrade div.contact-reasons {
  font-size: 12px;
  float: left;
  line-height: 16px;
}
.notification-inner-fancybox#user_upgrade div.contact {
  float: right;
  padding: 0px 12px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin-top: 2px;
}
.notification-inner-fancybox#user_upgrade div.contact h4 {
  font-size: 14px;
  font-weight: var(--type__body__standard--fontweight_strong);
  line-height: 32px;
}

.notification-inner-fancybox.survey h1 {
  font-size: 20px;
}

.notification-inner-fancybox.survey h3 {
  font-size: 22px;
}

.notification-inner-fancybox.survey .error {
  /* color: red;*/
  visibility: hidden;
}

.notification-inner-fancybox.survey form {
  padding-left: 7px;
}

.notification-inner-fancybox.survey textarea {
  width: 97%;
  height: 72px;
  line-height: 1.3em;
}

.notification-inner-fancybox.survey input[type='submit'] {
  float: right;
  margin-top: 20px;
}

.notification-inner-fancybox.test-mode {
  height: 195px;
  text-align: left;
}

.notification-inner-fancybox.test-mode p {
  text-align: left;
}

/**
 * Tutorial styling
 */

#message-bar-inner .message-bar-close {
  display: inherit;
}

/* Onboarding */
#message-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999;
  background-color: #e6e6e6;
  text-align: center;
  margin: auto;
  padding: 4px 40px;
}

#message-bar p {
  font-size: 13px;
}

#message-bar span#onboarding-cta-button-container {
  margin-left: 20px;
}

#message-bar button.m-button.m-button--onboarding-cta {
  padding: 8px 20px;
  font-size: 12px;
}

.m-collect-info-modal .m-button {
  margin: 0;
}
