.l-header {
  padding-top: 20px;
  padding-bottom: 0px;
  font-size: 16px;

  .logo {
    position: relative;
    padding-left: 20px;

    img {
      width: 175px;
      min-width: 175px;
      @media #{$small-only} {
        width: 160px;
        min-width: 160px;
      }
    }

    & > a {
      outline: none;

      & > img.svg-logo {
        width: 190px;
        height: 45px;
      }
    }
  }

  &.white {
    background: #fff;
  }

  .label {
    padding: 13px 0px 0px 20px;
    font-weight: 500;
  }

  .m-link-list {
    .m-drop-down-menu--drop-down-arrow {
      position: relative;
      margin-left: 4px;
      top: -1px;
    }
  }
}

.l-footer {
  background: $castle-rock;
  color: $warm-chinchilla;
  text-align: left;
  font-size: 16px;

  &.api-docs {
    margin-top: 100px;
  }

  .l-footer--bottom {
    text-align: center;
    a {
      font-size: 16px;
      color: $warm-chinchilla;
    }
  }
}

.l-mobile-menu {
  background: black;
  text-align: left;
}

$levels: 30;
$step: 5;
@mixin padding-and-margin {
  @for $i from -10 through $levels {
    .l-padding-v-#{$i*$step} {
      padding-top: #{$i * $step}px;
      padding-bottom: #{$i * $step}px;
    }
    .l-margin-v-#{$i*$step} {
      margin-top: #{$i * $step}px;
      margin-bottom: #{$i * $step}px;
    }
    .l-padding-b-#{$i*$step} {
      padding-bottom: #{$i * $step}px;
    }
    .l-padding-t-#{$i*$step} {
      padding-top: #{$i * $step}px;
    }
    .l-margin-t-#{$i*$step} {
      margin-top: #{$i * $step}px;
    }
    .l-margin-b-#{$i*$step} {
      margin-bottom: #{$i * $step}px;
    }
  }
}
@include padding-and-margin;

/* Formatting classes */

.l-inline {
  display: inline;
}

.l-inline-block {
  display: inline-block;
}

.l-float-left {
  float: left;
}

.l-float-right {
  float: right;
}

.l-inline-block {
  display: inline-block;
}

.l-text-left {
  text-align: left;
}

.l-text-right {
  text-align: right;
}

.l-centered {
  margin-left: auto;
  margin-right: auto;
}

.l-img-centered {
  display: block;
  margin: auto;
}

.l-no-gutter {
  padding-left: 0px;
  padding-right: 0px;
}

.l-small-hidden {
  @media #{$small-only} {
    display: none;
  }
}

.l-text-centered {
  text-align: center;
}
.l-text-uppercase {
  text-transform: uppercase;
}
.l-bold {
  font-weight: 500;
}
.l-400 {
  font-weight: 400;
}

.l-word-break-all {
  word-break: break-all;
}

.l-grey-border {
  border: solid 1px $warm-chinchilla;
}

.l-api-docs {
  margin-top: 0;
  margin-bottom: 100px;

  @media #{$small-only} {
    margin-top: 10px;
  }
  @media #{$medium-only} {
    margin-top: 10px;
  }

  .l-api-docs--content {
    @media #{$small-only} {
      margin-top: 50px;
    }
    @media #{$medium-only} {
      margin-top: 50px;
    }

    .l-api-docs--page-title {
      float: left;
      width: 100%;
      margin-bottom: 45px;
      font-size: 34px;
      color: $ashen;
      padding-bottom: 35px;
      border-bottom: solid 1px $warm-chinchilla;
      font-weight: 400;
    }

    .l-api-docs--intro {
      float: left;
      width: 100%;
      margin-bottom: 50px;
      color: $ashen;
    }
  }
}

.l-api-docs-menu {
  text-align: center;

  @media #{$medium-up} {
    position: absolute;
    top: 0;
    text-align: left;
  }

  &.is-fixed {
    @media #{$medium-up} {
      position: fixed;
      top: 100px;
    }
    .l-api-docs-menu--section.is-expandable .l-api-docs-menu--section--title {
      cursor: pointer;
    }
    .l-api-docs-menu--items--item.is-expanded .l-api-docs-menu--arrow,
    .l-api-docs-menu--arrow {
      left: -13px;
    }
  }

  .l-api-docs-menu--section {
    float: left;
    margin-bottom: 25px;

    &.is-collapsed {
      margin-bottom: 10px;
      .l-api-docs-menu--items {
        display: none;
      }
    }

    &.is-expandable {
      .l-api-docs-menu--section--title {
        cursor: pointer;
      }
    }
  }

  .l-api-docs-menu--section--title {
    text-transform: uppercase;
    font-weight: 500;
    color: var(--dig-color__primary__base);
    letter-spacing: 0.1em;
    line-height: 1.4em;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;

    a {
      font-weight: 500;
      color: var(--dig-color__primary__base);

      @media #{$small-up} {
        font-size: 14px;
      }
      @media #{$large-up} {
        font-size: 16px;
      }
    }

    @media #{$small-up} {
      font-size: 14px;
    }
    @media #{$large-up} {
      font-size: 16px;
    }
  }

  .l-api-docs-menu--items--item {
    font-size: 13px;
    font-weight: 400;
    color: $ashen;
    line-height: 30px;

    @media #{$small-only} {
      font-size: 12px;
    }

    & > a:hover {
      color: var(--dig-color__primary__base);
    }
    & > a {
      color: $ashen;
      font-size: 13px;
      font-weight: 400;
      @media #{$small-only} {
        font-size: 12px;
      }
    }

    @media #{$medium-only} {
      @media only screen and (max-width: 800px) {
        max-width: 145px;
      }
    }
  }

  .l-api-docs-menu--items--item.is-active {
    font-weight: 500;

    & > a {
      color: var(--dig-color__primary__base);
    }
    &.is-expandable > a {
      color: $ashen;
    }
    & > a:hover {
      color: $ashen;
    }
  }

  .l-api-docs-menu--items--item.is-expanded {
    .l-api-docs-menu--sub-items {
      display: block;
    }
    .l-api-docs-menu--arrow {
      background-image: url('../arrow_down.png');
      width: 8px;
      height: 6px;
      margin-top: 11px;
    }
  }

  .l-api-docs-menu--arrow {
    background-repeat: no-repeat;
    background-image: url('../arrow_right.png');
    float: left;
    width: 6px;
    height: 8px;
    position: absolute;
    left: -13px;
    margin-top: 10px;
    cursor: pointer;
  }

  .l-api-docs-menu--sub-items {
    display: none;
    margin-bottom: 5px;
  }

  .l-api-docs-menu--sub-items--sub-item {
    line-height: 22px;

    & > a {
      color: $ashen;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.05em;
      line-height: 22px;
      @media #{$small-only} {
        font-size: 11px;
      }
    }

    &.is-active {
      font-weight: 500;
    }
    &.is-active > a,
    & > a:hover {
      color: var(--dig-color__primary__base) !important;
    }
  }
}

/* The ghost, nudged to maintain perfect centering */
.l-vertical-align-wrapper:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em; /* Adjusts for spacing */
}

/* The element to be centered, can
   also be of any width and height */
.l-vertical-align {
  display: inline-block;
  vertical-align: middle;
}

#site-wrapper {
  position: relative;
  /*  overflow: hidden; */
  width: 100%;
}

#site-canvas {
  width: 100%;
  height: 100%;
  position: relative;
  @media #{$small-only} {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}

#site-menu {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  display: none;
}

.l-nowrap {
  white-space: nowrap;
}

.l-nowrap-small-only {
  @media #{$small-only} {
    white-space: nowrap;
  }
}

.l-wrap {
  white-space: normal;
}
