@import './wf-footer.scss';

.m-footer {
  $social_icon_extra_spacing: 2px;
  $social_stuff_block_width: (28px * 5) +
    (($social_icon_extra_spacing + 4px) * 4);
  h5 {
    text-transform: uppercase;
  }
  h5 a {
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 1.5em;
  }
  p {
    color: white;
    font-size: 14px;
    margin-bottom: 5px;
  }
  ul {
    margin-bottom: 0;
  }
  .m-footer--logo-container {
    margin-top: 0px;
  }
  .m-footer--badge {
    height: 100px;
    margin-left: 35px;
  }
  .mktoForm {
    .mktoGutter {
      display: none;
    }
    label {
      display: none;
    }
    input[type='email'] {
      height: 28px;
      display: inline-block;
      vertical-align: top;
      width: 219px !important;
      padding: 5px 10px;
      position: relative;
      line-height: 18px;
    }
    .mktoOffset {
      display: none;
    }
    .mktoButtonWrap {
      margin-left: 0 !important;
    }
    .mktoFormCol {
      margin-bottom: 0 !important;
    }
    .mktoFormRow {
      display: inline-block;
      vertical-align: top;
    }
    .mktoButtonWrap.mktoSimple .mktoButton,
    .mktoButtonWrap.mktoSimple .mktoButton:active {
      margin: 0 0 10px 4px;
      width: 28px;
      height: 28px;
      display: inline-block;
      background-image: url('../footer_submit_button_@2x.png');
      background-size: 28px;
      background-color: transparent;
      cursor: pointer;
      border-style: none;
      color: transparent;
      font-size: 0;
      vertical-align: top;
    }
    .mktoButtonWrap.mktoSimple .mktoButton:hover {
      border-width: 0;
    }
    .mktoButtonWrap.mktoSimple .mktoButton:active {
      opacity: 0.8;
    }
    .mktoError {
      color: white;
      .mktoErrorArrow {
        background-color: white;
        border-color: white;
      }
      .mktoErrorMsg {
        color: $ashen;
        background: white;
        border-color: white;
        box-shadow: none;
        text-shadow: none;
        font-family: proxima-nova, sans-serif;
        font-weight: 300;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.7px;
        border-radius: 2px;
      }
    }
  }
  .m-footer--icon-container {
    display: none;
    @media #{$large-up} {
      display: block;
    }
  }
  ul.m-footer--social-icon-container {
    list-style-type: none;
    margin: 0;
    display: inline-block;
    vertical-align: bottom;
    padding: 20px 0 0 0;
    li {
      display: inline-block;
      margin-right: $social_icon_extra_spacing;
      a {
        display: block;
      }
      a:active {
        opacity: 0.8;
      }
    }
  }
  .m-footer--icon-container {
    padding-right: 0;
  }
  .m-footer--sf-image {
    display: block;
    float: right;
  }
  .m-footer--image-container {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    width: $social_stuff_block_width;
    text-align: center;
  }
  .m-footer--copyright-container {
    margin-left: 1.1rem;
  }
  .m-footer--werehiring-link {
    margin-right: 10px;
  }
  .m-footer--link {
    font-size: 14px;
    line-height: 1.3em;
  }
  .m-footer--no-padding-left {
    @media #{$large-up} {
      padding-left: 0;
    }
    margin-left: 0;
  }
  .m-footer--outer-container {
  }
}
