$dropbox-blue: #b1dcf5;
$box-yellow: #fed48c;
$evernote-green: #aededa;
$onedrive-orange: #fab697;
$hubspot-orange: #fed486;

.seo-cloud-integrations {
  background-color: $plume;
  font-family: Proxima Nova;
  font-weight: 100;
  text-align: center;

  .top {
    position: relative;
    padding-top: 100px;
    background-color: $dropbox-blue;
    height: 1000px;
    @media #{$large-up} {
      height: 700px;
      padding: 0 0 80px 0;
      margin-bottom: 0px;
    }
  }

  .dropbox-blue {
    background-color: $dropbox-blue;
  }
  .box-yellow {
    background-color: $box-yellow;
  }
  .evernote-green {
    background-color: $evernote-green;
  }
  .onedrive-orange {
    background-color: $onedrive-orange;
  }
  .hubspot-orange {
    background-color: $hubspot-orange;
  }

  .top-content-container {
    @media #{$large-up} {
      text-align: start;
      margin-left: 0px;
      margin-right: 0px;
      padding-top: 100px;
      & > div {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }

  .hellosign-dropbox {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    @media #{$large-up} {
      justify-content: flex-start;
      margin-bottom: 30px;
    }
    .hs-logo {
      width: 180px;
      margin-right: 30px;
    }
    .plus {
      font-size: 24px;
    }
    .dropbox-logo {
      margin-left: 22px;
      width: 160px;
    }
    .box-logo {
      margin-left: 22px;
      width: 50px;
    }
  }
  .title {
    font-size: 43px;
    letter-spacing: 1px;
    margin-bottom: 35px;
    @media #{$large-up} {
      font-size: 50px;
      margin-bottom: 30px;
    }
  }
  .subtitle {
    width: 80%;
    font-size: 24px;
    font-weight: 300;
    margin: 0 auto 35px auto;
    @media #{$large-up} {
      width: 110%;
      margin-bottom: 30px;
      h2 {
        font-size: 15px;
        font-weight: 300;
      }
    }
  }
  .signup-button {
    button {
      margin-bottom: 0;
    }
    .signup-button-text {
      text-transform: uppercase;
      font-size: 13px;
    }
  }
  .clouds-container {
    margin: 0 auto 0 auto;
    @media #{$large-up} {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    img {
      margin: 0 auto 0 auto;
      display: block;
      width: 500px;
      @media #{$large-up} {
        width: 750px;
      }
    }
  }
  .seo-cloud-integrations__main {
    .seo-cloud-integrations__main__free_accounts__top {
      padding: 35px 0 35px 0;
      font-size: 18px;
      line-height: 25px;
      font-weight: 400;
      @media #{$large-up} {
        padding: 0;
        font-size: 15px;
        font-weight: 300;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          display: inline-block;
        }
        &:first-child {
          margin-right: 5px;
        }
      }
    }
    .seo-cloud-integrations__main__free_accounts__bottom {
      padding: 40px 0 40px 0;
      font-size: 18px;
      font-weight: 400;
      @media #{$large-up} {
        font-size: 15px;
        font-weight: 300;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          display: inline-block;
        }
      }
    }
    .seo-cloud-integrations__benefits {
      width: 90%;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 100px;
      @media #{$large-up} {
        width: 60%;
        margin-top: 70px;
        margin-bottom: 60px;
      }
      .benefits-image-container {
        @media #{$large-up} {
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      h2 {
        margin: 35px auto 0 auto;
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        @media #{$large-up} {
          margin: 0 auto 0 0%;
          text-align: start;
          font-size: 15px;
        }
      }
      .row {
        margin-top: 0px;
        @media #{$large-up} {
          margin-top: 30px;
        }
      }
      section {
        margin: 65px auto 0 auto;
        @media #{$large-up} {
          margin-top: 0px;
        }
        img {
          width: 70px;
          margin-bottom: 30px;
        }
        h3 {
          font-size: 26px;
          color: var(--dig-color__primary__base);
          margin-bottom: 30px;
          @media #{$large-up} {
            font-size: 20px;
            margin-bottom: 45px;
            height: 48px;
          }
        }
        p {
          font-size: 24px;
          font-weight: 300;
          line-height: 30px;
          color: $castle-rock;
          @media #{$large-up} {
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 0px;
          }
        }
        .save-signed-copies {
          p {
            margin-bottom: 0px;
          }
        }
      }
    }
    .seo-cloud-integrations__how-it-works {
      width: 90%;
      @media #{$large-up} {
        width: 60%;
        margin-bottom: 110px;
      }
      .screenshot-explanation {
        @media #{$large-up} {
          transform: translateX(10px);
        }
      }
      .dropbox-screenshot {
        @media #{$large-up} {
          transform: translateX(-10px);
        }
      }
      .columns {
        padding: 0;
      }
      margin: 0 auto 0 auto;
      h2 {
        margin-bottom: 50px;
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        @media #{$large-up} {
          margin-bottom: 40px;
          font-size: 15px;
          text-align: start;
        }
      }
      img {
        margin: 0 auto 80px auto;
        @media #{$large-up} {
          margin: 0 auto 0 auto;
        }
      }
      p {
        font-size: 24px;
        font-weight: 300;
        line-height: 30px;
        color: $castle-rock;
        margin-bottom: 50px;
        @media #{$large-up} {
          text-align: start;
          font-size: 15px;
          line-height: 20px;
          margin-bottom: 20px;
        }
      }
    }
    .seo-cloud-integrations__quote-container {
      width: 90%;
      margin: 0 auto 0 auto;
      @media #{$large-up} {
        width: 60%;
        margin-bottom: 100px;
      }
      .quote {
        text-align: start;
        font-size: 24px;
        margin-bottom: 50px;
        @media #{$large-up} {
          margin-bottom: 20px;
        }
      }
      .quote-name {
        font-size: 18px;
        font-weight: bold;
        color: $castle-rock;
        margin-bottom: 0px;
        @media #{$large-up} {
          font-size: 14px;
          text-align: right;
        }
      }
      .quote-title {
        font-size: 18px;
        font-weight: bold;
        color: $castle-rock;
        margin-bottom: 100px;
        @media #{$large-up} {
          font-size: 14px;
          text-align: right;
        }
      }
    }
    .seo-cloud-integrations__social {
      margin-bottom: 100px;
      @media #{$large-up} {
        margin-bottom: 50px;
      }
      img {
        width: 45px;
      }
      .facebook-share {
        margin: 0 20px;
      }
    }
    .seo-cloud-integrations__faq {
      font-size: 26px;
      font-weight: 300;
      line-height: 30px;
      color: $castle-rock;
      @media #{$large-up} {
        p {
          font-size: 28px;
          margin-bottom: 30px;
        }
      }
      button {
        margin: 30px auto 60px auto;
        text-transform: uppercase;
        @media #{$large-up} {
          margin-top: 0px;
          margin-bottom: 50px;
          font-size: 13px;
        }
        .faq-link {
          color: $white;
          text-transform: uppercase;
          font-size: 13px;
        }
      }
    }
  }
  .cloud-left {
    display: inline;
    position: absolute;
    width: 150px;
    left: 0;
    bottom: 0;
    @media #{$large-up} {
      width: 300px;
    }
  }
  .cloud-right {
    display: inline;
    position: absolute;
    width: 150px;
    right: 0;
    bottom: 0;
    @media #{$large-up} {
      width: 300px;
    }
  }
  .row {
    max-width: 100%;
  }
  .access-dropbox-from-hs {
    margin-top: 0px;
  }

  #try-it-free-link {
    display: none;
  }

  .gmail-not-installed {
    display: none;
  }

  #try-it-free-link.gmail-not-installed {
    display: block;
  }
}
