@import 'colors';

.m-press-kit {
  .m-press-kit--section {
    padding-bottom: 160px;

    .m-press-kit--section--title {
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
      text-align: left;
      color: $castle-rock;
      margin-bottom: 40px;
    }
  }

  .m-press-kit--press-room {
    .m-press-kit--press-room--content {
      float: left;
      text-align: left;

      & > h1 {
        font-size: 50px;
        font-weight: 200;
        letter-spacing: 0.02em;
        padding: 0;
      }

      .m-press-kit--press-room--icons {
        float: left;
        width: 100%;
        min-width: 525px;
        margin: 60px 0;

        & > span {
          float: left;
          margin-right: 8%;
          text-align: center;

          & > img {
            margin-bottom: 5px;
          }

          & > p {
            font-size: 15px;
            font-weight: 300;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .m-press-kit--press-room--cta {
        float: left;
        width: 100%;

        .m-button {
          margin: 0;
        }

        & > p {
          margin-top: 15px;
        }
      }
    }

    .m-press-kit--press-room--image {
      float: left;
    }
  }

  .m-press-kit--featured-stories {
    padding-bottom: 80px;

    .m-press-kit--featured-stories--extra {
      display: none;

      &.is-shown {
        display: inline;
      }

      .m-press-kit--featured-stories--item {
        padding-top: 35px !important;
      }
    }

    .m-press-kit--featured-stories--item {
      float: left;
      width: 100%;
      padding: 35px 0;
      border-bottom: solid 1px $warm-chinchilla;

      .m-press-kit--featured-stories--item--icon {
        float: left;
        opacity: 0.3;
        filter: alpha(opacity=30);
        margin-right: 3%;
        width: 250px;

        & > img {
          float: left;
          height: 35px;
        }
      }

      .m-press-kit--featured-stories--item--title {
        color: $castle-rock;
        font-size: 20px;
        font-weight: 400;
        line-height: 35px;
        text-align: left;
        float: left;
        width: calc(100% - 290px);
      }

      &:hover {
        .m-press-kit--featured-stories--item--icon {
          opacity: 100;
          filter: alpha(opacity=100);
        }

        .m-press-kit--featured-stories--item--title {
          color: var(--dig-color__primary__base);
        }
      }

      &:first-child {
        padding-top: 0;
      }
    }

    .m-press-kit--featured-stories--more-link {
      & > a {
        float: left;
        margin-top: 20px;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }

  .m-press-kit--latest-news {
    padding-bottom: 120px;

    .m-press-kit--latest-news--extra {
      display: none;
    }

    .m-press-kit--latest-news--item {
      float: left;
      width: 100%;
      padding: 35px;
      padding-bottom: 0;
      margin-left: -35px;

      .m-press-kit--latest-news--item--title {
        float: left;
        width: 100%;
        text-align: left;
        color: $castle-rock;
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 10px;
      }

      .m-press-kit--latest-news--item--date {
        float: left;
        width: 100%;
        text-align: left;
        color: $grey;
        font-size: 13px;
        font-weight: 300;
      }

      &.is-featured {
        border: solid 1px var(--dig-color__primary__base);
        padding-bottom: 35px;

        .m-press-kit--latest-news--item--title {
          font-size: 36px;
        }
      }

      &:hover {
        cursor: pointer;

        .m-press-kit--latest-news--item--title,
        .m-press-kit--latest-news--item--date {
          color: var(--dig-color__primary__base);
        }
      }
    }

    .m-press-kit--latest-news--more-link {
      float: left;
      margin-top: 45px;
      width: 100%;
      text-align: left;
      margin-bottom: 70px;

      & > a {
        font-size: 15px;
        font-weight: 500;
      }
    }

    .m-press-kit--latest-news--other {
      .m-press-kit--latest-news--other--mention {
        float: left;
        margin-right: 5%;
        margin-bottom: 40px;

        & > a {
          & > img {
            float: left;
            height: 35px;
          }
        }
      }
    }
  }

  .m-press-kit--timeline {
    padding-bottom: 105px;

    & > h1 {
      text-align: left;
      margin-bottom: 25px;
      padding: 0;
      font-size: 50px;
      font-weight: 200;
      letter-spacing: 0.02em;
    }

    & > h3 {
      margin-bottom: 80px;
      text-align: left;
      font-size: 20px;
      font-weight: 300;
    }

    .m-press-kit--timeline--years {
      float: left;
      text-align: left;
      padding-right: 40px;

      .m-press-kit--timeline--year--detail {
        display: none;

        &.is-selected {
          display: none;
        }
      }

      & > span {
        float: left;
        width: 100%;

        & > b {
          float: left;
          width: 55px;
          margin-right: 20px;
          margin-bottom: 5px;
          padding-top: 20px;
          font-size: 20px;
          font-weight: 300;
          color: $castle-rock;
        }

        & > p {
          float: left;
          padding: 20px 40px;
          width: calc(100% - 75px);
          border: solid 1px transparent;
        }

        &:before {
          content: '.';
          color: transparent;
          position: absolute;
          border-left: solid 1px $grey;
          margin-top: 50px;
          margin-left: 20px;
        }

        &.is-selected {
          & > p {
            border: solid 1px var(--dig-color__primary__base);
          }
        }

        &:hover {
          cursor: pointer;
        }
      }

      & > span[data-year='2011']:before,
      & > span[data-year='2012']:before {
        height: 25px;
      }

      & > span[data-year='2013']:before,
      & > span[data-year='2014']:before {
        height: 45px;
      }

      & > span[data-year='2015']:before {
        height: 25px;
      }

      & > span[data-year='2016']:before {
        height: 0;
      }
    }

    .m-press-kit--timeline--year {
      float: left;
      text-align: left;
    }

    .m-press-kit--timeline--year--detail {
      display: none;

      & > h1 {
        font-size: 20px;
        font-weight: 300;
        color: var(--dig-color__primary__base);
        margin-bottom: 20px;
      }

      & > b {
        font-size: 11px;
        font-weight: 500;
        color: $castle-rock;
        text-transform: uppercase;
      }

      & > p {
        font-size: 15px;
        font-weight: 300;
        color: $castle-rock;
        margin-bottom: 10px;
      }

      &.is-selected {
        display: inline;
      }
    }
  }

  .m-press-kit--investors {
    padding-bottom: 80px;

    .m-press-kit--investors--list {
      .m-press-kit--investors--item {
        float: left;
        margin-right: 60px;
        margin-bottom: 20px;

        & > img {
          height: 32px;
        }
      }
    }

    .m-press-kit--investors--browse-link {
      float: left;
      width: 100%;
      margin-top: 20px;

      & > a {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }

  .m-press-kit--press-assets {
    float: left;
    width: 100%;
    background: $plume;
    padding-top: 80px;
    padding-bottom: 80px;

    .m-press-kit--press-assets--inner {
      & > h1 {
        font-size: 50px;
        font-weight: 200;
        letter-spacing: 0.02em;
        text-align: left;
        padding: 0;
        margin-bottom: 25px;
      }

      & > h3 {
        text-align: left;
        font-size: 20px;
        font-weight: 300;
        padding: 0;
        margin-bottom: 25px;
      }

      .m-press-kit--cta {
        text-align: left;

        .m-button {
          margin: 0;
        }
      }

      .m-press-kit--image-table {
        float: left;
        width: 100%;

        .m-press-kit--image {
          float: left;
          padding-right: 40px;

          .m-press-kit--image--label {
            font-size: 13px;
            text-align: left;
          }

          .m-press-kit--image--img {
            float: left;
            width: 100%;
            margin: 10px 0;
            border: solid 1px $warm-chinchilla;

            &.with-band {
              background: $white;
              padding: 5px;
            }
          }

          .m-press-kit--image--link {
            float: left;
            width: 100%;
            text-align: left;

            & > a {
              font-size: 13px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .m-press-kit--notes {
    float: left;
    width: 100%;
    background: $plume;
    padding-bottom: 160px;

    .m-press-kit--notes--inner {
      & > h1 {
        padding: 0;
        font-size: 24px;
        font-weight: 200;
        color: $castle-rock;
        margin-bottom: 40px;
      }

      & > p {
        float: left;

        & > a {
          font-weight: 500;
        }
      }

      .m-press-kit--notes--note {
        float: left;
        width: 100%;
        margin-bottom: 20px;

        .m-press-kit--notes--note--flag {
          float: left;
          margin-right: 10px;

          & > img {
            float: left;
            width: 16px;
          }
        }

        & > a {
          font-weight: 500;
        }
      }

      .m-press-kit--notes--link {
        padding-top: 60px;
      }
    }
  }
}

@media #{$small-only} {
  .m-press-kit {
    .m-press-kit--section {
      padding-bottom: 100px;

      .m-press-kit--section--title {
        font-size: 16px;
        margin-bottom: 35px;
      }
    }

    .m-press-kit--press-room {
      text-align: center;

      .m-press-kit--press-room--content {
        & > h1 {
          font-size: 45px;
        }

        .m-press-kit--press-room--icons {
          margin: 30px 0;
          min-width: 0;

          & > span {
            margin: 20px 0;
            width: 100%;
            text-align: center;
          }
        }

        .m-press-kit--press-room--cta {
          text-align: center;

          & > p {
            margin-top: 35px;

            & > b {
              display: block;
            }
          }
        }
      }
    }

    .m-press-kit--featured-stories {
      .m-press-kit--featured-stories--item {
        padding: 30px 0;

        .m-press-kit--featured-stories--item--title {
          margin-top: 30px;
          width: 100%;
        }
      }
    }

    .m-press-kit--latest-news {
      .m-press-kit--latest-news--item {
        padding: 20px;
        margin-left: 0;

        .m-press-kit--latest-news--item--title {
          margin-bottom: 20px;
          line-height: 1.4em;
        }

        .m-press-kit--latest-news--item--date {
          font-size: 16px;
        }

        &.is-featured {
          .m-press-kit--latest-news--item--title {
            margin-bottom: 30px;
          }
        }
      }

      .m-press-kit--latest-news--more-link {
        margin: 35px 0 100px;
      }
    }

    .m-press-kit--timeline {
      & > h1 {
        font-size: 45px;
        font-weight: 300;
      }

      & > h3 {
        margin-top: 0;
        margin-bottom: 100px;
        font-size: 26px;
        font-weight: 200;
      }

      .m-press-kit--timeline--years {
        padding-right: 0;

        & > span {
          & > p {
            padding: 20px 0;
          }

          &.is-selected {
            &:before {
              display: none !important;
            }

            & > p {
              padding: 20px;
            }

            .m-press-kit--timeline--year--detail {
              display: inline;
            }
          }
        }
      }

      .m-press-kit--timeline--year {
        padding-left: 75px;
        padding-top: 35px;

        .m-press-kit--timeline--year--detail {
          display: none;
        }
      }

      .m-press-kit--timeline--year--detail {
        float: left;
        width: 100%;
        margin: 20px 0;

        & > h1 {
          display: none;
        }

        & > b {
          float: left;
          width: 55px;
          margin-right: 20px;
          padding-top: 4px;
        }

        & > p {
          float: left;
          width: calc(100% - 75px);
          color: $grey;
          font-size: 13px;
          font-weight: 300;
        }
      }
    }

    .m-press-kit--investors {
      padding-bottom: 70px;

      .m-press-kit--investors--list,
      .m-press-kit--investors--browse-link {
        text-align: center;
      }

      .m-press-kit--investors--item {
        margin: 25px 0;
        width: 100%;
      }

      .m-press-kit--investors--browse-link {
        margin-top: 25px;
      }
    }

    .m-press-kit--press-assets {
      padding-bottom: 60px;

      .m-press-kit--press-assets--inner {
        & > h1 {
          font-size: 45px;
        }

        & > h3 {
          margin-bottom: 50px;
        }

        .m-press-kit--cta {
          text-align: center;
        }

        .m-press-kit--image-table {
          padding-top: 60px !important;
          padding-bottom: 0 !important;

          .m-press-kit--image {
            margin-bottom: 60px;

            .m-press-kit--image--label {
              font-size: 15px;
              font-weight: 300;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .m-press-kit--notes {
      .m-press-kit--notes--inner {
        .m-press-kit--notes--link {
          padding-top: 40px;
          text-align: center;

          & > b {
            float: left;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}
