.m-steps {
  position: relative;
  z-index: 1000;
  background: var(--dig-color__primary__base);
  color: $plume;
  img {
    width: 32px;
    min-width: 32px;
    height: 33px;
  }
}
