@mixin m-tabs--arrow {
  position: absolute;
  top: 50%;
  width: 10%;
  max-height: 62px;
  max-width: 62px;
  min-height: 31px;
  min-width: 31px;
  &:hover {
    cursor: pointer;
  }
}

@mixin m-tabs--left-arrow {
  @include m-tabs--arrow;
  left: 20px;
}

@mixin m-tabs--right-arrow {
  @include m-tabs--arrow;
  right: 20px;
}

.m-tabs {
  z-index: 50;
  margin-top: -59px;
  position: relative;
  color: $ashen;
  &.plume {
    background-color: $plume;
  }
  .m-tabs--wrapper {
    @media #{$large-up} {
      display: inherit;
    }
    display: none;
  }

  .m-tabs--tab {
    text-align: center;
    background: $cinder-block;
    border-radius: 5px 5px 0px 0px;
    padding: 15px 2px;
    border: 2px solid #000;
    border-bottom: 2px solid $cinder-block;
    cursor: pointer;
    &.is-active {
      background: $plume;
      border-bottom: 2px solid $plume;
      font-weight: 500;
    }
    &:hover:not(.is-active) {
      background: $cinder-block;
    }
  }

  .m-tabs--pane-area {
    background: $plume;
  }

  .m-tabs--pane {
    display: none;
    &.is-active {
      display: block;
    }
    padding: 10px;
    .or-image {
      margin-top: 50px;
    }
  }

  .m-tabs--switcher {
    position: absolute;
    top: 200px;
    font-size: 80px;
    color: $ashen;

    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
    }
  }
  .m-tabs--left-arrow {
    @include m-tabs--left-arrow;
  }

  .m-tabs--right-arrow {
    @include m-tabs--right-arrow;
  }
}

.m-tabs_tiles {
  @extend .m-tabs;
  margin-top: 0px;
  .m-tabs--wrapper {
    display: inline-block;
  }

  .m-tabs_tiles--tiles {
    float: left;
    color: $cinder-block;
    &.is-active {
      color: $castle-rock;
    }
  }

  .m-tabs_tiles--center-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .m-tabs_tiles--tile-text-container {
    min-height: 25px;
    width: 200px;
    text-align: center;
  }

  .m-tabs_tiles--clickable-img {
    vertical-align: middle;
    max-height: 75px;
    &:hover {
      cursor: pointer;
    }
  }

  .m-tabs_tiles--tileWrapper {
    height: 75px;
    width: 200px;
    text-align: center;
  }

  .m-tabs_tiles--tile-header {
    margin: 0px 20px 60px 20px;
  }

  .m-tabs_tiles--pane {
    margin-left: -9999px;
    float: left;
    padding: 10px 10px 0px 10px;
    &.is-active {
      float: none;
      margin-left: 0px;
    }
  }

  .m-tabs_tiles--pane-header {
    text-transform: uppercase;
  }

  .m-tabs_tiles--bottom-image {
    @media #{$large-up} {
      display: inline-block;
    }
    padding: 70px 0px 0px 0px;
    display: none;
  }

  .m-tabs_tiles--copy-container {
    @media #{$small-only} {
      max-width: 213px;
      padding-bottom: 75px;
    }
    @media #{$medium-only} {
      max-width: 375px;
      padding-bottom: 75px;
    }
    max-width: 875px;
    display: inline-block;
  }
  .m-tabs--left-arrow {
    @include m-tabs--left-arrow;
  }
  .m-tabs--right-arrow {
    @include m-tabs--right-arrow;
  }
}
