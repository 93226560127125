.m-payment {
  .m-payment--wrapper {
    margin-top: 20px;
  }

  .m-payment--dbx-header {
    font-size: 16px;
    letter-spacing: normal;
    margin-bottom: 0px;
  }

  .m-payment--dbx-subheader {
    font-size: 14px;
    color: $color-text-dark;
    font-weight: 400;
  }

  .m-payment--dbx-card-wrapper {
    margin-top: 30px;
  }

  .m-payment--dbx-card-wrapper span {
    font-size: 12px;
    color: $color-text-darker;
    line-height: 16px;
  }

  .m-payment--dbx-card-last-four-digits {
    font-weight: 500;
  }

  .m-payment--dbx-switch,
  .m-payment--manual-switch {
    font-size: 14px;
    font-weight: 400;
    margin-top: 30px;
    color: $color-text-action;
    cursor: pointer;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 20px;
    }
  }

  .m-payment--manual-switch {
    margin-top: 54px;
  }

  .m-payment--left-border {
    @media #{$large-up} {
      border-left: 1px solid $warm-chinchilla;
      p {
        font-size: 12px;
      }
    }
  }

  select {
    width: 100%;
  }

  select.m-payment--cc-exp-month {
    width: 46%;
  }
  select.m-payment--cc-exp-year {
    width: 46%;
  }
  .m-payment--error {
    p {
      color: red;
      font-weight: normal;
    }
  }
  .m-payment--api-terms {
    label {
      display: inline;
      line-height: 1;
    }
  }
  .m-payment--cvv {
    width: 33%;
    display: inline-block;
    margin-right: 5px;
  }
  .m-payment--zip {
    width: 56%;
    display: inline-block;
    margin-left: 5px;
    @media #{$large-up} {
      margin-left: 0px;
    }
  }
  .m-payment--cc {
    position: relative;
  }
  .m-payment--ccs {
    position: absolute;
    right: 13px;
    top: 7px;
    img {
      width: 30px;
      margin-right: 2px;
      margin-top: 3px;
    }
  }

  // CC Brands
  .m-payment--cc-logo {
    margin-bottom: 10px;
    width: 40px;
    height: 24px;
    background-size: 40px 24px;
    background-repeat: no-repeat;
  }

  .m-payment--cc-info {
    h4 {
      font-size: 21px;
      color: $grey-900;
      line-height: 26px;
    }
  }

  .m-payment--button {
    padding: 10px 50px;
    border-radius: 6px;
    font-size: 15px;
  }
  .m-payment--l-height {
    line-height: 46px;
  }
  .columns.m-payment--billing-string {
    float: left;
  }
  .m-payment--billing-string {
    line-height: 18px;
    p {
      color: $black;
      font-weight: 400;
      font-size: 14px;
      white-space: pre-wrap;
    }
    a {
      font-weight: 400;
      font-size: 14px;
    }
    input[type='checkbox'] {
      margin-left: -18px;
      margin-bottom: 0px;
    }
    label {
      margin-left: 18px;
    }
  }
  .m-payment--label {
    p {
      font-weight: 500;
      font-size: 17px;
    }
  }

  .l-bold {
    h3 {
      font-weight: 500;
      font-size: 32px;
      color: $grey-900;
      line-height: 35px;
    }
  }
  .m-payment-size {
    line-height: 25px;
    p {
      font-size: 16px;
      color: $black;
    }
  }

  .m-payment--cc-logo--brand-amex {
    background-image: url('../../public/images/cc-brands/Amex-color.svg');
  }
  .m-payment--cc-logo--brand-discover {
    background-image: url('../../public/images/cc-brands/Discover-color.svg');
  }
  .m-payment--cc-logo--brand-elo {
    width: 24px;
    background-size: cover;
    background-image: url('../../public/images/cc-brands/elo-color.png');
  }
  .m-payment--cc-logo--brand-jcb {
    background-image: url('../../public/images/cc-brands/JCB-dark.svg');
  }
  .m-payment--cc-logo--brand-maestro {
    background-image: url('../../public/images/cc-brands/Maestro-color.svg');
  }
  .m-payment--cc-logo--brand-mastercard {
    background-image: url('../../public/images/cc-brands/Mastercard-color.svg');
  }
  .m-payment--cc-logo--brand-unionpay {
    background-image: url('../../public/images/cc-brands/UnionPay-dark.svg');
  }
  .m-payment--cc-logo--brand-visa {
    background-image: url('../../public/images/cc-brands/Visa-color.svg');
  }
}

@media #{$small-only} {
  .m-payment--button,
  .columns.m-payment--billing-string {
    width: 100%;
  }
}
