.m-quote {
  float: left;
  padding: 60px 0;
  width: 100%;

  .m-quote--text {
    padding: 0 20px;
    text-align: left;
    font-weight: 200;

    @media #{$small-only} {
      text-align: center;
    }
    @media #{$medium-up} {
      float: left;
    }
  }

  .m-quote--button {
    padding: 0px 15px;
    margin-top: 10px;
  }

  .m-quote--identity {
    margin-left: 0;

    @media #{$medium-up} {
      float: left;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    @media #{$large-up} {
      &.with-photo {
        .m-quote--identity--name {
          width: 50%;
          margin-top: 55px;
        }
        .m-quote--identity--title {
          width: 50%;
        }
      }
    }

    @media #{$small-only} {
      float: left;
      width: 100%;
      text-align: center;
      margin-top: 10px;
      padding: 0 20px;
    }
  }

  .m-quote--identity--photo {
    width: 80px;
    margin-top: 40px;
    margin-left: 15px;
    float: left;

    @media #{$small-only} {
      float: none;
      margin-top: 20px;
      margin-left: 0;
    }

    @media #{$medium-up} {
      float: left;
      width: 60px;
    }
  }

  .m-quote--identity--name {
    float: left;
    width: 100%;
    padding: 5px 15px;
    font-size: 13px;
    text-transform: uppercase;
    color: $ashen;
    font-weight: 400;
    line-height: 1em;

    @media #{$small-only} {
      float: none;
      margin-left: 0;
    }
  }

  .m-quote--identity--title {
    float: left;
    width: 100%;
    color: $ashen;
    font-weight: 500;
    font-size: 13px;
    padding: 0 15px;
    line-height: 1em;

    @media #{$small-only} {
      float: none;
      margin-left: 0;
    }
  }
}
