// Override variables specific to DIG components here.

.dig-theme--Sign {
  /* DIG buttons */

  --dig-Button-primary--background-color: var(--dig-color__primary__base);
  --dig-Button-primary--color: var(--color__inverse__standard__text);
  --dig-Button-primary--hover-color: var(--color__inverse__standard__text);
  --dig-Button-primary--focus-color: var(--color__inverse__standard__text);
  --dig-Button-primary-hover--background-color: var(
    --dig-color__primary__base--state-1
  );
  --dig-Button-primary-focus--background-color: var(
    --dig-color__primary__base--state-1
  );
  --dig-Button-primary-active--background-color: var(
    --dig-color__primary__base--state-2
  );
  --dig-Button-opacity--background-color: var(--dig-color__opacity__surface);
  --dig-Button-opacity-hover--background-color: var(
    --dig-color__opacity__surface--state-1
  );
  --dig-Button-opacity-focus--background-color: var(
    --dig-color__opacity__surface--state-1
  );
  --dig-Button-opacity-active--background-color: var(
    --dig-color__opacity__surface--state-2
  );
  // --dig-Button-outline--outline-color: var(--dig-color__text__base);

  button:not([class^='dig-']),
  .dig-Button--standard,
  .dig-Button--primary,
  .dig-Button--opacity,
  .dig-Button--outline,
  .dig-IconButton--standard,
  .dig-IconButton--primary,
  .dig-IconButton--opacity,
  .dig-IconButton--outline {
    &,
    &:before {
      border-radius: 0px;
    }
  }

  .dig-Button--primary {
    &:hover,
    &:focus {
      color: var(--dig-Button-primary--hover-color);
    }

    &:hover {
      background-color: var(--dig-Button-primary--background-color);
    }

    &:not(:hover)::before {
      background-color: var(--dig-Button-primary-hover--background-color);
    }

    &:focus {
      background-color: var(--dig-Button-primary--background-color);
    }
  }

  .dig-IconButton--filled,
  .dig-IconButton--outline {
    background-color: var(--dig-color__background__base);

    &:hover {
      background-color: var(--dig-IconButton-primary-hover--background-color);
    }
  }

  .dig-IconButton--transparent {
    background-color: transparent;

    &:hover {
      background-color: var(--dig-IconButton-primary--background-color);
    }
  }

  .dig-Button--opacity,
  .dig-IconButton--opacity {
    &:hover {
      background-color: var(--dig-Button-opacity-hover--background-color);
    }

    &:focus {
      background-color: var(--dig-Button-opacity-focus--background-color);
    }
  }

  .dig-Button--outline,
  .dig-IconButton--outline {
    color: var(--dig-Button-outline--outline-color);

    &[disabled] {
      box-shadow: inset 0 0 0 1px
        var(--dig-Button-outline-disabled--outline-color);
    }
  }

  button:focus,
  button:hover {
    &.dig-IconButton {
      background-color: transparent;
    }
    &.dig-Button--standard {
      background-color: var(--dig-Button-standard--background-color);
    }
    &.dig-Button--opacity {
      background-color: var(--dig-Button-opacity--background-color);
    }
    &.dig-Button--primary {
      background-color: var(--dig-Button-primary--background-color);
    }
    &.dig-Button--outline {
      background-color: var(--dig-Button-outline--background-color);
    }
  }

  /* DIG Chips */

  --dig-FilterChip--background-color: var(--dig-color__opacity__surface);
  --dig-FilterChip-hover--background-color: var(
    --dig-color__opacity__surface--state-1
  );
  --dig-FilterChip-active--background-color: var(
    --dig-color__opacity__surface--state-2
  );

  --dig-FilterChip-selected--background-color: var(
    --dig-color__opacity__surface
  );
  --dig-FilterChip-selected--border-color: var(--dig-color__secondary__base);
  --dig-FilterChip-selected-hover--background-color: var(
    --dig-color__opacity__surface--state-1
  );
  --dig-FilterChip-selected-active--background-color: var(
    --dig-color__opacity__surface--state-2
  );

  .dig-ChoiceChip {
    &:focus {
      background-color: var(--dig-ChoiceChip--background-color);
    }

    &--isSelected:focus {
      color: var(--dig-ChoiceChip-selected--color);
      background-color: var(--dig-ChoiceChip-selected--background-color);
    }
  }

  .dig-FilterChip {
    &:focus {
      background-color: var(--dig-FilterChip--background-color);
    }
  }

  .dig-InputChip {
    &:focus {
      background-color: var(--dig-InputChip--background-color);
    }
  }

  .dig-ChoiceChip,
  .dig-InputChip,
  .dig-FilterChip {
    &--isSuccess {
      &,
      &:focus {
        background-color: var(--dig-ChoiceChip-success--background-color);
      }

      &:hover,
      &:focus {
        color: var(--dig-ChoiceChip-success--color);
      }
    }

    &--isAlert {
      &,
      &:focus {
        background-color: var(--dig-ChoiceChip-alert--background-color);
      }

      &:hover,
      &:focus {
        color: var(--dig-ChoiceChip-alert--color);
      }
    }

    &--isWarning {
      &,
      &:focus {
        background-color: var(--dig-ChoiceChip-warning--background-color);
      }

      &:hover,
      &:focus {
        color: var(--dig-ChoiceChip-warning--color);
      }
    }
  }

  /* DIG typography */
  * {
    font-family: var(--type__body__xsmall--fontfamily);
  }

  .dig-Link {
    &:hover,
    &:active,
    &:focus {
      color: var(--dig-color__primary__base);
    }
  }

  /* DIG controls */

  /* DIG Text Fields */
  --dig-TextInput-input--background: var(--dig-color__background__base);
  --dig-Select-size_standard: 30px; // This was 32px for some reason.

  // This needs `button.` to have a higher specificity than reset.scss
  button.dig-Select-trigger {
    background: var(--dig-TextInput-input--background);

    height: 32px;

    &:hover {
      color: var(--dig-color__text__base);
    }
  }

  /* DIG Forms */
  .dig-FormLabel {
    color: var(--dig-color__text__base);
    cursor: default;
  }

  .dig-TextArea {
    background: var(--dig-TextInput-input--background);
  }

  .dig-Select button,
  textarea.dig-TextArea,
  input.dig-TextInput-input[type='email'],
  input.dig-TextInput-input[type='text'],
  input.dig-TextInput-input[type='password'],
  input.dig-TextInput-input[type='date'],
  input.dig-TextInput-input[type='time'],
  input.dig-TextInput-input[type='datetime'],
  input.dig-TextInput-input[type='datetime-local'],
  input.dig-TextInput-input[type='week'],
  input.dig-TextInput-input[type='tel'],
  input.dig-TextInput-input[type='number'],
  input.dig-TextInput-input[type='url'] {
    font-size: var(--type__body__standard--fontsize);
    font-family: var(--type__body__standard--fontfamily);
    font-weight: var(--type__body__standard--fontweight);
    line-height: var(--type__body__standard--lineheight);
  }

  // https://sourcegraph.pp.dropbox.com/maestro_lib/-/blob/packages/dig-components/src/text_fields/index.base.css
  textarea.dig-TextArea--large,
  input.dig-TextInputContainer--large {
    &[type='email'],
    &[type='text'],
    &[type='password'],
    &[type='date'],
    &[type='time'],
    &[type='datetime'],
    &[type='datetime-local'],
    &[type='week'],
    &[type='tel'],
    &[type='number'],
    &[type='url'] {
      font-size: var(--type__body__large--fontsize);
      font-family: var(--type__body__large--fontfamily);
      font-weight: var(--type__body__large--fontweight);
      line-height: var(--type__body__large--lineheight);
    }
  }

  textarea.dig-TextArea:focus,
  textarea.dig-TextArea:focus-within {
    box-shadow: var(--dig-utilities__boxshadow__focus-ring);
  }

  .dig-Select button.dig-Select-trigger {
    border: 1px solid #8c8279;
  }

  /* DIG Overlays */

  // DIG overlays are portaled and so they are not
  // descendants of the Backdrop theme. This is a temporary
  // fix to force all DIG overlays (regardless of theme) to
  // use the default DIG font stack.
  .dig-Overlay {
    font-family: var(--__bodyFontStack);

    * {
      font-family: inherit;
    }

    .dig-Title {
      font-family: var(--type__title__standard--fontfamily);
    }
  }

  /* DIG Typeahead */
  .dig-Typeahead-container {
    margin: 0; // uls have built-in margins that we don't want
  }

  /* DIG Date Picker */
  .dig-DatePicker-day,
  .dig-DatePicker-day[disabled] {
    background-color: transparent;
    color: var(--dig-color__text__base);
  }

  .dig-DatePicker-day--selected,
  .dig-DatePicker-day--selected:hover {
    background-color: var(--dig-color__opacity__surface);
    color: var(--dig-color__text__base);
  }

  .dig-DatePicker-day--selectedEdge,
  .dig-DatePicker-day--selectedEdge:hover {
    background-color: var(--dig-color__secondary__base);
    color: var(--color__inverse__standard__text);
  }
}
