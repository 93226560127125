.m-tooltip {
  z-index: 9999999;

  .m-tooltip--text {
    position: relative;
    display: inline;
    color: $cinder-block;
    cursor: pointer;
    font-size: 14px;
    font-weight: 300;
    &:hover {
      color: $light-grey;
    }
  }

  .m-tooltip--bubble {
    z-index: 9999999;
    position: absolute;
    max-width: 250px;
    min-width: 100px;

    &.top {
      margin-top: 14px;
      .m-tooltip--bubble--arrow.bottom,
      .m-tooltip--bubble--arrow.left,
      .m-tooltip--bubble--arrow.right {
        display: none;
      }
    }

    &.bottom {
      margin-bottom: 14px;
      .m-tooltip--bubble--arrow.top,
      .m-tooltip--bubble--arrow.left,
      .m-tooltip--bubble--arrow.right {
        display: none;
      }
    }

    &.left {
      margin-left: 14px;
      .m-tooltip--bubble--arrow.bottom,
      .m-tooltip--bubble--arrow.top,
      .m-tooltip--bubble--arrow.right {
        display: none;
      }
    }

    &.right {
      margin-right: 14px;
      .m-tooltip--bubble--arrow.bottom,
      .m-tooltip--bubble--arrow.left,
      .m-tooltip--bubble--arrow.top {
        display: none;
      }
    }
  }

  .m-tooltip--bubble--content {
    background: $castle-rock;
    float: left;
    padding: 10px 15px;
    color: $warm-chinchilla;
    font-weight: 300;
    font-size: 10px;
    border-radius: 2px;
    line-height: 1.4em;
  }

  .m-tooltip--bubble--arrow {
    &:after,
    &:before {
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &.top {
      &:after {
        left: 50%;
        border-bottom-color: $castle-rock;
        border-width: 7px;
        margin-left: -7px;
        margin-top: -14px;
      }
    }

    &.bottom {
      &:after {
        left: 50%;
        border-top-color: $castle-rock;
        border-width: 7px;
        margin-left: -7px;
        bottom: -14px;
      }
    }

    &.left {
      &:before {
        top: 50%;
        border-right-color: $castle-rock;
        border-width: 7px;
        margin-left: -7px;
        margin-top: -7px;
        left: -7px;
      }
    }

    &.right {
      &:after {
        top: 50%;
        border-left-color: $castle-rock;
        border-width: 7px;
        margin-left: 0;
        margin-top: -7px;
      }
    }
  }

  &.m-tooltip--onboarding {
    .m-tooltip--text {
      color: $plume;
      &:hover {
        color: $plume;
      }
    }

    .m-tooltip--bubble {
      width: 400px;
      max-width: 400px;
    }

    .m-tooltip--bubble--content {
      background: $plume;
      color: $ashen;
      line-height: 1.3em;
      font-size: 11px;
      letter-spacing: 0.07em;
      font-weight: 400;
    }

    .m-tooltip--bubble--arrow {
      &.top {
        &:after {
          border-bottom-color: $plume;
        }
      }

      &.bottom {
        &:after {
          border-top-color: $plume;
        }
      }

      &.left {
        &:before {
          top: 50%;
          border-right-color: $plume;
        }
      }

      &.right {
        &:after {
          top: 50%;
          border-left-color: $plume;
        }
      }
    }
  }
}

.m-tooltip--container {
  &.is-hidden {
    display: none;
  }
}

#noCCForHipaaTip,
#featureLockedTip,
#featureHipaaLockedTip,
#ccDisabledWarningTip {
  width: 250px;
}

.m-tooltip--lockedTemplateTip .m-tooltip--bubble {
  width: 250px;
}
