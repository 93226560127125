.m-pricing-level {
  // need to override the gutter width
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: 6px;
  .block {
    background: $plume;
    padding: 12px 0px;
    @media #{$large-up} {
      margin-bottom: 2px;
    }
  }

  .bgi-cerulean {
    background-color: var(--dig-color__primary__base);
  }

  .bgi-goldenrod {
    background-color: $goldenrod;
  }

  .m-pricing-level--header {
    color: $white;
    @media #{$small-up} {
      height: 125px;
      font-size: 16px;
      cursor: pointer; // Required for iOS click events
      text-align: left;
      font-weight: 800;
      padding-top: 20px;
    }
    @media #{$medium-only} {
      font-size: 28px;
      font-weight: 800;
    }
    @media #{$large-up} {
      height: 60px;
      font-size: 18px;
      text-align: center;
      cursor: auto;
    }
    .most-popular {
      position: absolute;
      top: -46px;
      left: 10px;
    }
    .level-title {
      height: 100%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
    }
    .level-price {
      text-align: right;
      height: 100%;
    }
    .price {
      font-size: 25px;
      @media #{$small-up} {
        color: $white;
      }
      @media #{$medium-up} {
        color: $white;
      }
      @media #{$large-up} {
        position: relative;
        color: $dark-black;
      }
      &.crossed {
        color: $cinder-block;
        font-size: 25px;
        position: relative;
        font-weight: 300;
        &:before {
          position: absolute;
          content: '';
          left: -5px;
          top: 50%;
          right: -10px;
          border-top: 2px solid;
          border-color: red;
          @include transform(rotate(-35deg));
        }
      }
    }

    .billing-period {
      font-size: 14px;
      display: block;
      letter-spacing: normal;
      text-transform: none;

      @media #{$small-up} {
        color: $white;
      }
      @media #{$medium-up} {
        color: $white;
      }
      @media #{$large-up} {
        color: $dark-black;
      }
    }
    .phone-container {
      text-align: right;
      height: 100%;
      display: none;
    }
    .contact-us-phone-number {
      margin-top: 5px;
      font-weight: 500;
      color: $ashen;
      letter-spacing: 0.07em;
      @media #{$small-up} {
        font-size: 14px;
      }
      @media #{$large-up} {
        font-size: 16px;
      }
    }
    .arrow-container {
      height: 100%;
      text-align: right;
    }
    .arrow {
      width: 16px;
      height: 10px;
      background: url('../arrow@2x.png');
      background-size: 16px 10px;
      background-repeat: no-repeat;
      &.expanded {
        -moz-transform: scaleY(-1);
        -o-transform: scaleY(-1);
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
        filter: FlipV;
        -ms-filter: 'FlipV';
      }
    }
  }
  .m-pricing-level--seat-range {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 52px;
    z-index: 1;
    text-align: center;
    font-size: 13px;
    font-weight: normal;
    color: black;
    text-transform: uppercase;

    .max-seats-badge {
      margin: 0 auto; // This allows the badge to be centered in the column
      border: 2px solid $warm-chinchilla;
      border-radius: 11px;
      background-color: $white;
      color: $ashen;
      width: 100px;
      padding: 1px;
      padding-top: 2px;
      font-size: 11px;
      font-weight: bold;
    }
  }
  .m-pricing-level--feature {
    margin-top: 5px;
    margin-bottom: 4px;
    // no gutter width
    padding-left: 0px;
    padding-right: 0px;
    // hidden except on large+ screens and when expanded
    display: none;
    color: $ashen;
    margin-bottom: 2px;
    @media #{$large-up} {
      display: block;
    }
    &.expanded {
      display: block;
    }
  }
  .m-pricing-level--body {
    // no gutter width
    padding-left: 0px;
    padding-right: 0px;
    // hidden except on large+ screens and when expanded
    display: none;
    color: $ashen;
    @media #{$large-up} {
      display: block;
    }
    &.expanded {
      display: block;
    }
    .first-section {
      height: 340px;
      @media #{$small-up} {
        margin-top: -6px;
      }
      @media #{$large-up} {
        margin-top: 0px;
        padding-top: 30px;
      }
    }
    .price {
      display: inline-block;
      font-size: 25px;
      padding-bottom: 12px;
      &.crossed {
        color: $cinder-block;
        font-size: 25px;
        position: relative;
        &:before {
          position: absolute;
          content: '';
          left: -10px;
          top: 50%;
          right: -15px;
          border-top: 2px solid;
          border-color: red;
          @include transform(rotate(-35deg));
        }
      }
    }
    .no-billing-period {
      height: 0px;
      margin-bottom: 16px;
    }
    .current-plan {
      margin-top: 15px;
      margin-bottom: 45px;
    }
    .no-plan-option {
      margin-top: 15px;
      margin-bottom: 48px;
    }
    .contact-us {
      background: url('../contact_us.png');
      &:hover {
        background: url('../contact_us_hover.png');
      }
      width: 130px;
      height: 37px;
      margin: 48px auto 0px;
      cursor: pointer;
    }
    .m-pricing-level--num-trial-days {
      margin-top: 5px; // Do this here vs class because it's less than 10px
      margin-bottom: 10px;
      font-size: 12px;
      font-style: italic;
      color: $grey;
    }
    .m-pricing-level--no-free-trial {
      height: 28px;
      &.current-plan {
        display: none;
      }
    }
    .thirty-day {
      font-size: 12px;
      font-weight: 300;
      font-style: italic;
      margin-top: 8px;
    }
    .looking-for-more {
      font-size: 25px;
      margin-top: 12px;
      height: 22%;
    }
    .phone-icon {
      height: 24px;
      width: 24px;
    }
    .contact-us-phone-number {
      font-weight: 300;
      margin-bottom: 4px;
      @media #{$small-up} {
        font-size: 14px;
      }
      @media #{$large-up} {
        font-size: 16px;
      }
    }
    .no-features-padding {
      margin-bottom: 58px;
    }
  }
  .m-pricing-level--feature {
    &.block {
      padding: 15px 0;
      height: 80px;
    }
    .name {
      font-size: 16px;
      color: $dark-black;
      padding: 0px 10px;

      &.bold {
        font-weight: bold;
      }
    }
    .number {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    .detail {
      font-size: 16px;
      font-weight: 300;
    }
  }
}
