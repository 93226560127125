.m-tabbed-code-example {
  float: left;
  width: 100%;
  margin: 15px 0;

  .m-tabbed-area--row {
    margin: 0px auto !important;

    .m-tabs--tab {
      float: left;
      font-size: 13px;
      font-weight: bold;
      color: $grey;
      background: $plume;
      border: solid 1px $warm-chinchilla;
      padding: 8px 16px;
      margin-right: 5px;
      cursor: pointer;
      text-transform: uppercase;
      border-bottom: transparent;
      position: relative;
      &:hover,
      &.is-active {
        color: $castle-rock;
        background: $white;
      }
      &.is-active {
        z-index: 2;
      }
      & > span {
        @media only screen and (max-width: 500px) {
          font-size: 12px;
        }
        @media only screen and (max-width: 415px) {
          font-size: 10px;
        }
      }
    }
  }

  .m-tabs--pane-area {
    float: left;
    position: relative;
    top: -1px;
    width: 100%;

    .m-code-example--code {
      overflow: auto;
      border: none !important;
    }

    .m-tabs--pane {
      margin: 0px auto;
      float: left;
      display: none;
      width: 100%;
      background: $white;

      &.is-active {
        display: inline;
      }
      .m-code-example {
        margin: 0;
        width: 100%;
      }
    }
  }
}
