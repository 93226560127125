// Run this command in the console to make all data-qa-ref visible.
// document.body.classList.add('show-qa-refs')

// When running in storybook's iframe you need this command
// document.getElementById('storybook-preview-iframe').contentWindow.document.body.classList.add('show-qa-refs')
.show-qa-refs [data-qa-ref] {
  outline: 2px solid green;

  &:hover::after {
    content: attr(data-qa-ref);
    white-space: pre;
    font-size: 12pt;
    cursor: crosshair;
    padding: 0.25em;
    z-index: 9999;
    position: absolute;
    color: white;
    background-color: green;
  }
}
