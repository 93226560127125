.m-settings {
  .error {
    color: $blood;
    font-weight: 400;
  }

  .is-hidden {
    display: none;
  }

  .m-tooltip {
    .m-tooltip--bubble--content {
      font-size: 11px !important;
    }
  }

  .plural {
    display: none;
  }
  .is-plural .plural {
    display: initial;
  }

  .m-button {
    margin: 0;
    &.with-icon {
      padding: 10px 15px 8px;
      .m-button--icon {
        width: 18px;
        margin-right: 8px;
        position: relative;
        top: 2px;
      }
      .l-nowrap > span {
        position: relative;
        top: -3px;
      }
    }
  }

  .m-settings--error {
    color: $blood;
    clear: both;
    font-weight: 400;
    font-size: 12px;
    padding-top: 8px;
  }

  .m-settings-title {
    padding-bottom: 36px;
    margin-top: 0.2rem;
  }

  .m-settings--nav {
    float: left;
    width: 100%;

    .m-settings--nav--tab {
      float: left;
      font-size: 14px;
      letter-spacing: 0.05em;
      cursor: pointer;
      color: $ashen;
      font-weight: 300;
      padding-right: 60px;

      &:hover {
        color: $black;
      }

      &.is-active {
        color: $ashen;
        font-weight: 500;
      }
    }

    .m-settings--nav--tabs {
      text-align: left;
      margin: 0;

      &.is-compact {
        .m-settings--nav--tab {
          padding-right: 60px;
        }
      }
    }
  }

  .m-settings--content {
    .m-settings--content--tab {
      float: left;
      width: 100%;
    }
  }

  .m-settings--section {
    float: left;
    width: 100%;
    margin-top: 30px;
    padding-top: 35px;
    border-top: solid 1px $warm-chinchilla;

    .m-settings--section--header {
      float: left;
      color: $cinder-block;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 25px;
      width: 100%;

      .m-settings--section--header--text {
        float: left;
        margin-right: 20px;
        line-height: 34px;
      }

      .m-settings--section--header--button {
        float: right;
        margin-left: 10px;
      }
    }

    &.no-separator {
      border-top: solid 1px transparent;
    }

    &.no-margin {
      margin-top: 0;
    }

    &.no-padding {
      padding-top: 0;
    }

    &.is-api {
      .m-button {
        min-width: 100px;
      }
    }

    &.dbx-bundle {
      font-weight: 300;

      > h4 {
        font-size: 16px;
        margin-bottom: 4px;
      }

      > div {
        font-size: 14px;
        height: 20px;
        line-height: 20px;
      }

      img {
        height: 16px;
        width: 16px;
        margin: 0 8px;
        vertical-align: text-bottom;
      }

      .dbx-billing-byline {
        margin-bottom: 29px;
      }

      .dbx-billing-link {
        display: inline-block;
        margin: 30px 0 70px;
        font-size: 14px;
        font-weight: 500;
        color: var(--primary-blue);
        border: 1px solid var(--primary-blue);
        border-radius: 2px;
        padding: 3px 16px;
      }

      .plan-info {
        margin-bottom: 9px;
      }
    }
  }

  .m-settings--row {
    float: left;
    width: 100%;
    margin-bottom: 30px;
    min-height: 50px;

    .m-settings--row--title {
      float: left;
      width: 250px;
      font-size: 14px;
      font-weight: 500;
      color: $ashen;
      max-width: 205px;
      margin-right: 45px;
    }

    .m-settings--row--title--btn {
      float: left;
      margin-left: 10px;

      & > button {
        min-width: 50px;
        padding: 2px 5px 1px;
        font-size: 10px;
      }
    }

    .m-settings--row--error {
      display: none;
      font-weight: 400;
      font-size: 12px;
      color: $blood;
    }

    &.ext-serv {
      .m-settings--row--title {
        width: 700px;
        max-width: 700px;
        margin-right: 0;
        color: $ashen;
        font-size: 24px;
        font-weight: 300;
        line-height: 1.3em;
        letter-spacing: 0.05em;

        & > p {
          margin-top: 5px;
          color: $ashen;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 0.05em;
          line-height: 1.4em;
        }
      }

      .m-settings--row--cta {
        float: right;
        width: 200px;
        text-align: right;
        padding-right: 120px;
      }
    }

    .m-settings--row--content {
      float: left;
      max-width: 650px;
      font-size: 14px;
    }

    .m-button {
      min-width: 100px;
    }

    .m-settings--row--text {
      float: left;
      width: 100%;
      color: $grey;
      font-size: 12px;
      line-height: 1.4em;
      clear: both;
    }

    &.email-display {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }

    .change-email-notice {
      margin: 16px 0;
    }

    .delete-account-notice {
      margin-bottom: 16px;
    }

    .m-settings--row--sub-action {
      float: left;
      font-size: 12px;
      margin-top: 5px;
    }

    .m-settings--row--text-field {
      & > input {
        float: left;
        color: $light-grey;
        border-color: $light-cinder-block;
        border-radius: 0px;
        font-size: 14px;
        font-weight: 300;
        height: 38px;
        width: 350px;
        box-shadow: none;
        padding-left: 10px;
        margin-bottom: 5px;
      }

      & > textarea {
        width: 350px;
        color: $light-grey;
        border: solid 1px $light-cinder-block;
        border-radius: 0px;
        box-shadow: none;
        font-size: 14px;
        font-weight: 300;
        padding-left: 10px;
      }

      &.short-field {
        float: left;
        width: 175px;
        margin-right: 10px;
        & > input {
          width: 170px;
        }
      }

      &.password-change-input {
        & > label {
          float: left;
          width: 100%;
          font-size: 14px;
          font-weight: 300;
          color: $grey;
        }

        .is-required {
          color: $blood;
        }

        & > input {
          margin-top: 5px;
          margin-bottom: 10px;
        }
      }

      &.email-change-input {
        & > label {
          float: left;
          width: 100%;
          font-size: 14px;
          font-weight: 300;
          color: $grey;
        }

        .is-required {
          color: $blood;
        }

        & > input {
          margin-top: 5px;
          margin-bottom: 10px;
        }
      }

      &.two-fa {
        #verify2faButton {
          float: left;
          margin-left: 10px;

          & > button {
            padding: 11px 10px 9px 10px;
          }
        }

        & > input {
          float: left;
          width: 300px;
        }

        & > a {
          float: left;
          width: 100%;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      & > label.error {
        color: $blood !important;
      }

      &.resizable {
        & > textarea {
          resize: both;
        }
      }
    }

    .m-settings--row--select-field {
      & > select {
        color: $light-grey;
        border-color: $warm-chinchilla;
        font-size: 14px;
        font-weight: 300;
        border-radius: 0px;
        width: 350px;
        height: 38px;
        margin-bottom: 0;
        outline: none;
        float: left;
      }
    }

    .m-settings--row--checkbox {
      display: flex;
      float: left;
      width: 100%;

      & > input {
        float: left;
        height: 12px;
        margin-top: 2px;
      }

      & > label {
        float: left;
        margin-left: 10px;
        margin-bottom: 1rem;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.05em;
        color: $grey;
      }

      .m-settings--row--sub-action {
        margin-top: 0;
        margin-left: 30px;
        line-height: 18px;
      }
    }

    .m-settings--premium-cta {
      float: right;
      width: 200px;
      text-align: right;

      & > a {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .m-settings--row--lock {
      float: right;

      & > input {
        margin: 0;
        position: relative;
        top: -1px;
      }

      & > label {
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.05em;
        margin-left: 0;
      }
    }

    &.is-locked {
      .m-settings--row--content {
        opacity: 0.6;
        filter: alpha(opacity=60);
      }
      .m-button {
        background-color: $light-grey;
        color: $white;
      }
    }

    &.billing-info,
    &.invoices,
    &.subscription {
      .m-settings--row--title > span {
        float: left;
        line-height: 18px;
      }

      .m-settings--row--text-field,
      .m-settings--row--text-field > div {
        font-size: 14px;
        font-weight: 300;
      }

      .m-settings--row--text-field > div {
        margin-bottom: 5px;
      }

      .m-settings--billing--view-plans-link {
        margin-right: 20px;
      }

      .m-settings--billing--cancel-link {
        margin-right: 20px;
      }
    }

    &.two-fa {
      .active {
        display: none;
      }

      &.is-active {
        .active {
          display: inline;
        }
        .inactive {
          display: none;
        }
      }
    }

    &.invitation {
      .m-settings--team-invite-response {
        float: left;
        margin-right: 20px;
      }
    }
  }

  .m-settings--action {
    text-align: right;
    float: left;
    width: 100%;
    margin-top: 50px;
  }

  .m-settings--action--save-btn {
    & > button {
      min-width: 80px;
    }
  }

  .m-settings--api--dashboard {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .m-settings--api--dashboard--text {
      width: 100%;

      & > h2 {
        font-size: 16px;
        font-weight: 800;
      }

      & > div {
        font-size: 14px;
        font-weight: 400;
        color: $grey;
      }
    }
  }

  .m-settings--api--key--container {
    display: none;
    margin-left: 10px;
  }

  .m-settings--api--key--value {
    font-family: Menlo, 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', Monaco,
      Consolas, monospace;
    font-size: 12px;
    letter-spacing: 0.025em;
    color: $ashen;
    margin-bottom: 40px;

    & > h2,
    & > div {
      font-size: 14px;
      font-weight: 300;
    }

    .m-settings--api--key--renew {
      float: right;
      padding-right: 120px;
    }
  }

  .m-settings--api--account-callback {
    .m-settings--row--title {
      width: 170px;
      margin-right: 0;
    }

    .m-settings--row--text-field {
      & > input {
        float: left;
        margin-right: 20px;
      }

      .m-settings--test-callback-btn {
        float: left;

        & > button {
          padding: 11px 10px 9px 10px;
        }
      }
    }

    .m-settings--row--text {
      float: left;
      width: 50%;
    }
  }

  .m-settings--api--apps {
    .m-settings--api--apps--row {
      float: left;
      width: 100%;
      margin-bottom: 25px;

      & > h2 {
        float: left;
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 5px;
      }
    }

    .m-settings--api--apps--row--properties {
      font-size: 14px;
      font-weight: 300;
      margin-top: 10px;
      margin-left: 10px;
      & > b {
        font-weight: 400;
      }
    }

    .m-button {
      float: right;
      margin-left: 10px;
      position: relative;
      top: -10px;
    }
  }

  .m-settings--upload-btn {
    float: left;
    margin-bottom: 10px;
  }

  #upgradeButton {
    margin-bottom: 10px;
  }

  .m-settings--logo-form--selected-file-name {
    float: left;
    width: 320px;
    height: 32px;
    line-height: 32px;
    padding-left: 20px;
  }

  .m-settings--logo-holder {
    float: left;
  }

  .m-settings--remove-logo {
    float: left;
    margin-left: 20px;
    line-height: 75px;
  }
}

.m-settings--cancel {
  .m-settings--cancel--api-option {
    float: left;
    width: 100%;
    margin: 10px 0;

    & > input {
      float: left;
      height: 12px;
      margin-top: 2px;
    }

    & > label {
      float: left;
      margin-left: 10px;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.05em;
      color: $grey;
    }
  }

  .m-settings--cancel--title {
    font-size: 22px;
    font-weight: 300;
    color: $ashen;
    margin-bottom: 20px;
  }

  .m-settings--cancel--description {
    font-size: 15px;
  }

  .m-settings--cancel--reason {
    & > label {
      margin-bottom: 5px;
      font-weight: 300;
      font-size: 14px;
      margin-top: 10px;
      float: left;
      width: 100%;
    }

    & > textarea {
      float: left;
      width: 500px;
      color: $ashen;
      border: solid 1px $warm-chinchilla;
      border-radius: 0px;
      box-shadow: none !important;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.05em;
    }

    .m-settings--cancel--error {
      float: left;
      width: 100%;
      color: $blood;
      margin-bottom: 10px;

      & > ul {
        margin-left: 0;
      }

      & > ul > li {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  .m-settings--cancel--warning {
    float: left;
    width: 80%;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
  }

  .m-settings--cancel--actions {
    margin-top: 30px;

    & > div {
      float: left;
      margin: 0 20px 0 0;
    }

    .m-button {
      margin-left: 0;
      padding: 7px 10px 6px;
    }
  }

  .m-settings--cancel--reminder {
    float: left;
    width: 100%;
    margin: 15px 0;

    .m-settings--cancel--reminder--title {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    & > ul {
      list-style-type: disc;
      list-style-position: inside;

      & > li {
        font-size: 14px;
      }
    }
  }
}

.m-settings--invite-friends {
  .m-settings--invite-friends--close {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 10px;
    width: 10px;
    cursor: pointer;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }

  .m-settings--invite-friends--p {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
  }

  .m-settings--invite-friends--title {
    font-weight: 400;
  }

  .m-settings--invite-friends--sub-title {
    margin-bottom: 5px;
  }

  .m-settings--invite-friends--intro {
    font-size: 14px;
    margin: 10px 0 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px $warm-chinchilla;
  }

  .m-settings--invite-friends--text {
    box-shadow: none;
    border: solid 1px $warm-chinchilla;
    padding: 10px;
    min-height: 75px;
  }

  .m-settings--invite-friends--actions {
    float: left;
    width: 100%;
    height: 60px;
    margin-top: 25px;
    background: $plume;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: right;

    .m-button {
      padding: 10px 10px 8px;
      min-width: 100px;
      margin-top: 13px;
      margin-right: 20px;
    }
  }
}

.m-settings--contact-import {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 10%;

  &.first-step {
    margin-top: 0;
    text-align: left;
  }

  .m-settings--contact-import--title {
    float: left;
    width: 100%;
    margin: 25px 0 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .m-settings--contact-import--btn-close {
    margin-top: 35px;
    display: inline-block;
  }

  .m-settings--contact-import--sub-title {
    font-size: 14px;
    letter-spacing: 0.05em;
    font-weight: 300;
    margin-bottom: 20px;
    padding: 0 75px;
  }

  .m-settings--contact-import--close {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 10px;
    width: 10px;
    cursor: pointer;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }

  .m-settings--contact-import--source-list {
    padding: 0 75px;
  }

  .m-settings--contact-import--source {
    float: left;
    width: 200px;

    & > input {
      float: left;
      margin: 20px 20px 0 0;
    }

    & > img {
      float: left;
      width: 150px;
      cursor: pointer;
    }
  }

  .m-settings--contact-import--actions {
    float: left;
    width: 100%;
    height: 50px;
    margin-top: 25px;
    background: $plume;
    position: absolute;
    top: 236px;
    left: 0px;
    text-align: right;

    .m-button {
      padding: 8px 5px 6px 5px;
      width: 100px;
      min-width: 100px;
      margin-top: 9px;
      margin-right: 20px;
    }
  }

  .m-settings--contact-import--error {
    float: left;
    width: 100%;
    padding: 0 75px;
    margin-top: 10px;
    color: red;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.05em;
  }

  .m-settings--contact-import--text {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.05em;
    float: left;
    width: 100%;
    text-align: center;
  }

  .m-settings--contact-import--step.completed {
    .m-settings--contact-import--title {
      margin-bottom: 10px;
    }

    .m-settings--contact-import--text {
      margin: 10px 0px 20px;
      padding: 0 20px;
    }

    .m-settings--contact-import--contacts-form {
      float: left;
      width: 100%;
      padding: 0 20px;
    }

    .m-settings--contact-import--contacts-form--list {
      max-height: 200px;
      float: left;
      width: 510px;
      overflow-y: scroll;
      border: solid 1px $warm-chinchilla;
      padding: 10px;
      margin-bottom: 5px;
      letter-spacing: 0.05em;

      .contact > label {
        font-size: 14px;
        font-weight: 300;
      }
    }

    .m-settings--contact-import--contacts-form--message {
      float: left;
      margin-top: 20px;

      .m-settings--contact-import--contacts-form--message--title {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
      }

      .m-settings--contact-import--contacts-form--message--text {
        width: 510px;
        height: 103px;
        font-size: 14px;
        font-weight: 300;
        padding: 15px;
        border: solid 1px $warm-chinchilla;
        border-radius: 0;
        letter-spacing: 0.05em;
        margin-top: 0;
        margin-bottom: 10px;
      }
    }

    .m-settings--contact-import--contacts-form--controls {
      float: left;
      font-size: 13px;

      & > a {
        font-size: 13px;
        letter-spacing: 0.05em;
        font-weight: 300;
        color: $ashen;
      }

      & > a:hover {
        text-decoration: none;
        color: $black;
      }
    }

    .m-settings--contact-import--contacts-form--actions {
      float: left;
      width: 100%;
      background-color: #f7f8f9;
      padding: 10px 20px;
      margin-top: 25px;

      .m-settings--contact-import--contacts-form--invite-btn {
        float: right;
      }
    }
  }
}

#deleteAccountButtonSection {
  top: auto;
}

#deleteAccountPopup {
  height: 450px !important;
}

.m-settings--delete-account {
  width: 100%;
  height: 350px;
  padding: unset !important;

  .m-settings--delete-account--title {
    float: left;
    width: 100%;
    margin: 40px 0;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.05em;
  }

  .m-settings--delete-account--close {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 10px;
    width: 10px;
    cursor: pointer;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }

  .m-settings--delete-account--text {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.05em;
    padding: 0 50px;
  }

  .m-settings--delete-account--list {
    margin: 0 0 0 15px;
    padding: 0 50px;
    font-size: 12px;
    list-style-type: disc;
    list-style-position: outside;
  }

  .m-settings--delete-account--input {
    padding: 0 50px;

    .m-settings--delete-account--text {
      padding: 0;
    }

    & > input {
      color: $light-grey;
      border-color: $warm-chinchilla;
      border-radius: 0px;
      font-size: 14px;
      font-weight: 300;
      height: 38px;
      width: 350px;
      &:focus {
        box-shadow: 0 0 5px $cinder-block;
      }
    }
  }

  .m-settings--delete-account--actions {
    float: left;
    width: 100%;
    height: 60px;
    margin-top: 25px;
    background: $plume;
    position: absolute;
    top: 156px;
    left: 0px;
    text-align: right;

    &.delete-account {
      top: 326px;
    }

    .m-button {
      padding: 8px 5px 6px 5px;
      width: 270px;
      min-width: 100px;
      margin-top: 14px;
      margin-right: 20px;
    }
  }

  .m-settings--delete-account--error {
    float: left;
    width: 100%;
    padding: 0 50px;
    margin-top: 10px;
    color: red;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0.05em;
  }
}

.m-settings--team--edit-name {
  width: 400px;

  & > form {
    padding: 0 30px;
  }

  .m-settings--team--edit-name--title {
    float: left;
    width: 100%;
    text-align: center;
    margin: 20px 0;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.05em;
  }

  .m-settings--team--edit-name--input {
    color: $light-grey;
    border-color: $warm-chinchilla;
    border-radius: 0px;
    font-size: 14px;
    font-weight: 300;
    height: 38px;
    width: 350px;
    &:focus {
      box-shadow: 0 0 5px $cinder-block;
    }
  }

  .m-settings--team--edit-name--error {
    font-size: 12px;
    margin-top: 10px;
    font-weight: 300;
    letter-spacing: 0.05em;
    color: $blood;
    visibility: hidden;

    &.is-visible {
      visibility: visible;
    }
  }

  .m-settings--team--edit-name--actions {
    background: $plume;
    text-align: right;
    position: relative;
    top: 11px;
    left: -30px;
    float: left;
    width: 401px;
    height: 50px;

    .m-button {
      padding: 5px 0;
      width: 100px;
      min-width: 100px;
      margin-right: 25px;
      margin-top: 12px;
    }
  }

  .m-settings--team--edit-name--close {
    position: absolute;
    top: 0;
    left: 370px;
    margin: 10px;
    width: 10px;
    cursor: pointer;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }
}

.m-settings--team--opt-state {
  width: 520px;
  padding: 0 30px;

  .m-settings--team--opt-state--title {
    float: left;
    width: 100%;
    margin: 20px 0;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.05em;
  }

  .m-settings--team--opt-state--actions {
    background: $plume;
    text-align: right;
    position: relative;
    top: 11px;
    left: -30px;
    float: left;
    width: 520px;
    height: 50px;

    .m-button {
      padding: 5px 0;
      min-width: 100px;
      margin-right: 25px;
      margin-top: 12px;
    }

    .m-button-wide {
      width: 160px;
    }
  }

  .m-settings--team--opt-state--close {
    position: absolute;
    top: 0;
    left: 490px;
    margin: 10px;
    width: 10px;
    cursor: pointer;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }
}

.m-settings--2fa--verify {
  .m-settings--2fa--verify--title {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .m-settings--2fa--verify--input-container {
    margin-bottom: 20px;

    & > input {
      float: left;
      width: 300px;
      margin-right: 20px;
      color: $light-grey;
      border-color: $warm-chinchilla;
      border-radius: 0px;
      font-size: 14px;
      font-weight: 300;
      height: 38px;
      box-shadow: none;
      &:focus {
        border-color: $light-grey;
      }
    }

    .m-button {
      padding: 11px 20px 9px 20px;
    }
  }

  .m-settings--2fa--verify--error {
    font-size: 12px;
    color: $blood;
  }
}

.m-settings--2fa--recovery {
  .m-settings--2fa--recovery--title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .m-settings--2fa--recovery--instructions {
    font-size: 14px;
    letter-spacing: 0.05em;
  }

  .m-settings--2fa--recovery--code {
    font-weight: 500;
    font-size: 18px;
    margin: 10px 0;
  }

  .m-settings--2fa--recovery--actions {
    margin-top: 20px;
    text-align: right;
  }
}

.m-settings--callback-report {
  float: left;
  width: 100%;
  margin-top: 20px;

  .m-settings--callback-report--title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .m-settings--callback-report--message {
    font-family: Menlo, 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', Monaco,
      Consolas, monospace;
    font-size: 12px;
    letter-spacing: 0.025em;
    line-height: 18px;
    color: $ashen;
    border: solid 1px $warm-chinchilla;
    padding: 20px;
    overflow: auto;
  }

  .m-settings--callback-report--spinner {
    padding-left: 0;
    font-size: 14px;
    margin-top: 10px;
  }

  .m-settings--callback-report--response {
    font-size: 14px;
    margin-top: 10px;
    letter-spacing: 0.05em;

    .error {
      color: $blood;
    }

    & > p {
      font-size: 12px;
      letter-spacing: 0.05em;
    }
  }

  .m-settings--callback-report--success {
    color: $grasshopper;
  }

  .m-settings--callback-report--failed {
    color: $blood;
  }
}

.m-settings--api-app-form {
  .m-settings--api-app-form--title {
    font-size: 22px;
    font-weight: 400;
    color: $ashen;
    margin-bottom: 0;
  }

  .m-settings--row--checkbox {
    & > input {
      margin: 2px 0 0 0;
    }

    & > label {
      font-weight: 300;
      font-size: 14px;
      color: $grey;
      letter-spacing: 0.05em;
    }
  }

  .m-settings--api-app-form--evt-callback {
    float: left;
    width: 400px !important;
  }

  .m-settings--api-app-form--test-btn {
    float: left;
    margin-left: 20px;

    .m-button {
      min-width: 80px;
    }
  }

  .m-settings--api-app-form--oauth {
    display: none;

    .m-settings--row--title {
      float: none;
    }
    .m-settings--row--content {
      float: none;
    }
  }

  .m-settings--api-app-form--upgrade-link {
    line-height: 36px;
    margin-left: 20px;
  }

  .m-settings--row.custom-logo {
    .m-settings--logo-form--selected-file-name {
      display: none;
      line-height: 36px;
      height: 36px;
    }

    .m-settings--row--text {
      width: 80%;
    }

    .m-settings--upload-btn {
      & > button {
        padding: 10px 10px 8px 10px;
      }

      &.is-locked > button {
        opacity: 0.6;
        filter: alpha(opacity=60);
        background-color: $light-grey;
        color: $white;
      }
    }
  }

  .m-settings--row.custom-logo.has-logo {
    .m-settings--logo-form--selected-file-name {
      display: inline;
    }

    .m-settings--api-app-form--upgrade-link {
      display: none;
    }
  }

  .m-settings--api-app-form--error,
  .m-settings--api-app-form--warning,
  .refresh-token-warning-message {
    font-weight: 400 !important;
    font-size: 12px !important;
    color: $blood;
  }

  .m-settings--api-app-form--warning {
    display: none;
  }

  .m-settings--api-app-form--actions {
    float: left;
    width: 100%;
    margin-top: 20px;

    & > div {
      float: left;
      margin-right: 20px;
    }
  }

  .m-settings--api-app-form--actions--warning {
    margin-bottom: 20px;
    color: $cinder-block;
    font-size: 14px;
    font-weight: 300;
    clear: both;
  }

  .m-settings--api-app-form--oauth--callback > input {
    color: $light-grey;
    border-color: $warm-chinchilla;
    border-radius: 0px;
    font-size: 14px;
    font-weight: 300;
    height: 38px;
    width: 350px;
    &:focus {
      box-shadow: 0 0 5px $cinder-block;
    }
  }

  .m-settings--api-app-form--oauth--billing {
    label {
      font-size: 12px;
      font-weight: 300;
      color: $light-grey;
    }
  }

  .m-settings--api-app-form--oauth--scopes {
    & > p {
      font-size: 14px;
      font-weight: 300;
      color: $light-grey;
      margin-bottom: 5px;
    }

    & > p > a {
      font-size: 12px;
    }
  }

  .m-settings--api-app-form--oauth--scopes--list {
    margin-bottom: 10px;

    & > div > label {
      font-size: 12px;
      font-weight: 300;
      color: $light-grey;

      &.disabled {
        color: $warm-chinchilla;
        cursor: default;
      }
    }

    & > div > input {
      margin-bottom: 5px;
    }
  }

  .m-settings--api-app-form--oauth--display-inline {
    display: flex;
  }

  .m-settings--api-app-form--name {
    & > input {
      float: left;
      margin-bottom: 10px;
    }

    & > span {
      float: left;
      margin-left: 20px;
      line-height: 38px;
    }

    &.ok > span {
      color: $grasshopper;
    }

    &.err > span {
      color: $blood;
    }
  }
}

.m-settings--api-app-details {
  padding: 20px;
  width: 570px;

  .m-settings--api-app-details--title {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
  }

  .m-settings--api-app-details--section {
    float: left;
    width: 100%;
    margin-bottom: 20px;

    & > ul {
      margin-left: 0;
      font-size: 14px;
    }
  }

  .m-settings--api-app-details--section--title {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .m-settings--api-app-details--section--item {
    float: left;
    width: 100%;
    font-size: 14px;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
    line-height: 21px;
    word-break: break-word;

    .title {
      color: $ashen;
      font-weight: 500;
    }

    .value {
      color: $ashen;
      font-family: Menlo, 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', Monaco,
        Consolas, monospace;
      font-size: 12px;
      padding: 4px;
    }

    .no-break {
      word-break: break-all;
    }

    .pending {
      color: $blood;
      font-weight: 400;
    }

    .approved {
      color: $grasshopper;
      font-weight: 400;
      color: $grey;
    }

    & > a.no-margin {
      margin-bottom: 0;
    }

    &.auth_url {
      margin-bottom: 20px;
    }
  }

  .m-settings--api-app-details--warning {
    margin-top: 5px;
  }

  .m-settings--api-app-details--close {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 10px;
    width: 10px;
    cursor: pointer;
    opacity: 0.8;
    filter: alpha(opacity=80);
    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }

  .m-settings--callback-report {
    width: 500px;
  }

  .m-settings--test-callback-btn {
    float: left;
    & > button {
      margin: 10px 0 0 0;
      padding: 10px 20px 7px 20px;
      min-width: 100px;
    }
  }
}

.m-settings--notice {
  border: solid 1px $warm-chinchilla;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 12px;
  color: $light-grey;
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: 15px;
  background-color: rgb(255, 248, 219);
  margin-bottom: 20px;
  text-align: center;
}

.m-settings--popup-footer {
  text-align: right;
  background-color: $plume;
  padding: 12px;
}

.m-settings--popup-label {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4;
}

.m-settings--popup-label-error {
  color: $blood;
  font-size: 14px;
  font-weight: 300;
}
