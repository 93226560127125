/* -----  ONBOARDING LAYOUT (slight changes from IN-APP)  ---------------- */

.l-app--onboarding {
  .l-app--container .l-app--content {
    float: left;
    max-width: inherit;
    width: 100%;
  }

  .l-app--onboarding--navlink {
    color: #bfbfbf;
    font-size: 12px;
    padding-left: 15px;
  }

  .l-app--onboarding--testimonial {
    padding-left: 45px;
    padding-top: 10px;
    border-left: solid 1px #e6e6e6;
  }

  .l-app--onboarding--testimonial li {
    margin-bottom: 25px;
  }
}

.m-onboarding-page {
  .m-onboarding-page--text {
    font-size: 14px;
    font-weight: 400;
    margin-right: 60px;
    margin-bottom: 30px;
  }

  .m-onboarding-page--larger-text {
    font-size: 24px;
  }

  .m-onboarding-page--navlinks a:first-child {
    margin-left: 0px;
  }
}
