/**
 * When creating a reveal modal in JavaScript, you'll want to use the
 * HfReactHelper.getRevealAttachPoint() to construct the necessary elements
 * on the page. This method allows you to pass in an element ID and an optional
 * reveal size (e.g., "small", "tiny"). If you do NOT pass in the size, the
 * modal will be constrained to 475px. Otherwise the modal will be dynamic.
 * 
 * If you need to construct the modal manually outside of JS, refer to the
 * styles used in the getRevealAttachPoint() method to make this work.
 */

@mixin m-modal-base() {
  color: $ashen;
  font-size: 14px;
  height: auto;

  @media #{$medium-up} {
    top: 100px;
  }

  &.is-stand-alone {
    max-width: 500px;
    margin: 20px auto;
    &.is-large {
      max-width: 520px;
    }
  }

  .m-modal--close {
    color: #727a83;
    position: absolute;
    top: 7px;
    right: 14px;
    font-size: 26px !important;
    font-weight: 300;
    text-decoration: none;
  }

  .close-modal-left-side {
    left: 14px;
  }

  /**
     * May want to set this on a non-modal class instead...
     */
  .m-modal--error {
    p {
      color: red;
      font-size: 12px;
    }
  }

  /**
     * This style is only here for the "Forgot Password" modal,
     * which could probably be refactored into its own module.
     */
  .m-modal--text-input {
    display: inline-block;
    max-width: 300px;
    &.m-modal--login-password {
      margin-bottom: 4px;
    }
  }
}

.m-modal {
  @include m-modal-base();

  $levels: 10;
  @for $i from -10 through $levels {
    &.l-margin-t-#{$i*10} {
      margin-top: #{$i * 10}px;
    }
    &.l-margin-b-#{$i*10} {
      margin-bottom: #{$i * 10}px;
    }
  }
}

.m-modal-restricted-size {
  @include reveal-modal-base($base-style: true);

  width: 475px !important;
  margin-left: -237.5px !important;

  @media #{$small-only} {
    height: 100vh !important;
    min-height: 100vh !important;
    width: 100vw !important;
    min-width: 100vw !important;
    margin: 0 !important;
    top: 0 !important;
  }

  @include m-modal-base();
}
