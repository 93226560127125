.m-create-account {
  font-size: 14px;

  .m-create-account--form {
    margin-bottom: 0;
  }

  .m-create-account--modal-google-login {
    padding-bottom: 50px;
  }

  .m-create-account--inline-google-login {
    padding-bottom: 50px;
    @media #{$large-up} {
      border-right: 0 solid $warm-chinchilla;
      border-bottom: 0;
      height: 200px;
      padding-right: 10px;
    }
  }

  .m-create-account__inline-google-login--extra-top-padding {
    padding-top: 80px;
  }

  .m-create-account__inline-google-login--minimal-top-padding {
    padding-top: 50px;
  }

  .m-create-account__inline-google-login--small-top-padding {
    padding-top: 20px;
  }

  .m-create-account--countries-dropdown {
    text-align: right;
    right: -20px;
    position: relative;
    top: -20px;
    bottom: 0px;
  }

  .header-text--size {
    font-size: 25px;
  }

  .m-create-account--label {
    color: #000;
    cursor: default;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05em;
    margin-bottom: 2px;
    text-transform: uppercase;
  }

  .m-create-account--margin-left {
    margin-left: 3px;
  }

  .m-create-account--text-input {
    margin-bottom: 17px;
  }

  .m-create-account--login-side-text {
    @media #{$large-up} {
      text-align: left;
      padding-left: 50px;
      padding-right: 20px;
    }

    text-align: center;
    padding-left: 0;
    padding-right: 0;
    padding-top: 50px;
  }

  @mixin m-create-account--base-google-login-or-image {
    background-image: url('../or_white@2x.png');
    background-repeat: no-repeat;
    height: 29px;
    width: 29px;
    background-size: 29px 29px;
    position: absolute;
    bottom: -15px;
    right: 40%;
  }
  .m-create-account--modal-google-login-or-image {
    @include m-create-account--base-google-login-or-image;
    right: 45%;
  }

  .m-create-account--inline-google-login-or-image {
    @include m-create-account--base-google-login-or-image;
    @media #{$large-up} {
      position: absolute;
      right: -15px;
      bottom: 85px;
    }
  }

  @mixin m-create-account--base-google-login-border {
    @media #{$small-only} {
      border-bottom: 1px solid $warm-chinchilla;
      position: absolute;
      bottom: 0;
      right: 30%;
      left: 30%;
    }
  }

  .m-create-account--modal-google-login-border {
    @include m-create-account--base-google-login-border;
    @media #{$small-up} {
      border-bottom: 1px solid $warm-chinchilla;
      position: absolute;
      bottom: 0;
      right: 20%;
      left: 20%;
    }
  }

  .m-create-account--inline-google-login-border {
    @include m-create-account--base-google-login-border;
    @media #{$medium-only} {
      border-bottom: 1px solid $warm-chinchilla;
      position: absolute;
      bottom: 0;
      right: 30%;
      left: 30%;
    }
    @media #{$large-up} {
      border-right: 1px solid $warm-chinchilla;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }

  @mixin m-create-account--base-google-login-button {
    width: auto;
  }

  .m-create-account--inline-create-account-button {
    width: auto;
    font-size: 12px;
    margin: 0;
  }

  .m-create-account--modal-create-account-button {
    width: 100%;
    margin: 0px;
  }

  .m-create-account--inline-google-login-button {
    width: auto;
    @media #{$small-up} {
      margin-left: 0;
    }
    @media #{$large-up} {
      margin-left: -60px;
    }
  }

  .m-create-account--modal-google-login-button {
    @include m-create-account--base-google-login-button;
  }

  .m-create-account--sign-up {
    @media #{$large-up} {
      height: 150px;
    }
  }

  .m-create-account--inline-container {
    border-left: 1px solid $warm-chinchilla;
    border-top: 1px solid $warm-chinchilla;
    border-right: 1px solid $warm-chinchilla;
  }

  .m-create-account--inline-container-vertical {
    border: 1px solid $warm-chinchilla;
  }

  input {
    display: inline-block !important;

    &.m-create-account--login-password {
      margin-bottom: 4px;
    }
  }

  .m-create-account--opt-in-container {
    display: block;
    text-align: left;
    font-size: 11px;
  }

  .m-create-account--sign-in-link {
    margin-bottom: 30px;
  }

  .m-create-account--h-divider {
    height: 1px;
    margin-bottom: 16px;
    background: $warm-chinchilla;
    z-index: 0;
    margin-left: 11%;
    width: 78%;
  }

  .m-create-account--sign-in-link p {
    text-align: center;
    font-size: 14px;
  }

  @mixin m-create-account--opt-in-align {
    text-align: left;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    span {
      color: $light-grey;
    }
  }

  .m-create-account--opt-in-align {
    @include m-create-account--opt-in-align;
    max-width: 290px;
    width: 500px;
  }

  .m-create-account--opt-in-align-vertical {
    @include m-create-account--opt-in-align;
  }

  .m-create-account--login-prompt {
    * {
      font-style: italic;
      font-size: 12px;
    }
    span {
      color: #a0a0a0;
    }
  }
}
